<script>
    import AuctioneerUI from '@/components/pc28/AuctioneerUI' //特码投注
    import { getList } from "@/api/touzhu";

    export default {
    name: 'TradingFloor',
    components:{
        AuctioneerUI,
    },
    data() {
        return {
            open:false,
            opend:false,
            auctionText:null,
            titleMenuPayload:null,
            clearTradingFloorTimer:null,
            roomTypes:[
                'lhc',
                'hljssc',
                'tjssc',
                'sscone',
                'sscthree',
                'sscfive',
                'scone',
                'scthree',
                'scfive',
                'fastthreeone',
                'fastthreethree',
                'fastthreefive',
                'fastthreeten',
                'lhcaomen',
                'lhcone',
                'lhcthree',
                'lhcfive',
                'lhcten',
                'pc28one',
                'tencent',
                'shfastthree',
                'jsfastthree',
                'hbfastthree',
                'gdfiveselect',
                'sdfiveselect',
                'jsfiveselect',
                'jxfiveselect',
                'shhfiveselect',
                'anhfiveselect',
                'shxfiveselect',
                'fc3d',
                'gxklsf',
                'gdklten',
                'jndxy',
                'azxy5',
                'azxy10',
                'cqklten',
                'wanneng',
                'damacai',
                'duoduo',
                'yuedawan',
                'shaba88',
                'saimahui',
                'xinjapowz',
                'tiantiancai',
            ],
            
            /*template-2 begin*/
            roomTypesAdded:['cqssc','bjsc','xyft'],
            /*template-2 end*/

            currentHeadlineClosed:false,
            currentHeadlineContent:null,

            /*template-3 begin*/
            textUiSwitcherData:[
                {
                    id:'1',
                    title: getWord('text_betting'),
                    active:false,
                },
                {
                    id:'2',
                    title: getWord('auctioneer_by_ui3'),
                    active:true,
                },
            ],
            /*template-3 end*/
        }
    },
    props: [
        'titleMenu',
        'headerMenuDockerOpen',
    ],
    inject: [
        // 'tradingFloorMessages',
        'auctioneerUIActive',
        'game',
        'user',
        'addMessage',
        'orderAuction',
        'updateBalance',
        'scrollEnd',
        'initTitleMenuClass',
        'toggleHeaderMenuDocker',
        'toggleChatRoom',
    ],
    provide:function(){
        return {
            game: this.game,
        }
    },
    methods: {
        toggleAuctioneerUI(lock){
            this.open=!this.open;
            this.auctioneerUIActive();
            if (lock) {
                this.opend=!this.opend;
            }
        },
        enter(){
            this.$refs.auctioneerUI.$el.style.position='fixed'
        },
        afterEnter(){
            this.opend=true;
            this.$refs.auctioneerUI.$el.style.position='relative'
        },
        leave(){
            this.$refs.auctioneerUI.$el.style.position='fixed'
        },
        orderAuctionByText(){
            var that=this;
            this.orderAuction(this.auctionText,function(){
                that.scrollToFloorEnd()
            });
            this.auctionText='';            
        },
        scrollToFloorEnd(){
            this.scrollEnd(this.$refs.hgroup);
        },
        checkRoomType(add){
            var result=0;
            var types=[];
            if(add) {
                types=add;
            }else {
                types=this.roomTypes;
            }
            types.forEach(item=>{
                this.game.info.game_type!==item
                    ?result+=0
                    :result+=1;
            })
            return result
        },
        fetchMsg(item) {
            if (item.msg.split(' ').length===2 && item.msg.split(' ')[0].indexOf(getWord('period'))>-1){
                return item.msg.split(' ').length===2?item.msg.split(' ')[1]:item.msg    
            }else {
                return item.msg
            }
        },
        formatMsg(msg,item) {
            var _number=[]
            var _totalPrice=0;
            var _size=msg.split('、').length
            var key=this.currencySymbol()
            var _item=item;
            if (_item&&_item.type&&_item.type==='bet-self') {
                if (_size>1) {
                    msg.split('、').forEach(item=>{
                        _number.push(item.split(key)[0])
                        _totalPrice+=Number(item.split(key)[1])
                    })
                }else {
                    _number.push(msg.split(key)[0])
                    _totalPrice+=Number(msg.split(key)[1])
                }

                return {
                    number: ''+_number.join(','),
                    unitPrice: msg.split('、')[0].split(key)[1],
                    totalPrice: _totalPrice,
                    size: _size,
                }
            }else {
                if (_size>1) {
                    msg.split('、').forEach(item=>{
                        _number.push(item.split(key)[0])
                        _totalPrice+=Number(item.split(key)[1])
                    })
                }else {
                    _number.push(msg.split(key)[0])
                    _totalPrice+=Number(msg.split(key)[1])
                }

                return {
                    number: ''+_number.join(','),
                    unitPrice: msg.split('、')[0].split(key)[1],
                    totalPrice: _totalPrice,
                    size: _size,
                }
            }
            
        },
        TEMPLATE2toggleAuctioneerUI(){
            this.toggleAuctioneerUI()
            this.TEMPLATE2_toggleAuctioneerUI(true)
        },
        changeType(menu,item,entries) {
            this.titleMenuSwitcher({
                type:this.$route.query.type,
                class:item.title,
                id:item.id,
                alias:item.alias,
                classTypeId:item.parentId,
            })
            if (entries&&entries.childNav) {
                entries.type=item.title;
                entries.childNav=false;
            }
            this.$forceUpdate();
        },
        toggleActive(menu,item) {
            menu.forEach(item=>{
                item.active=false;
                item.childNav=false;
            })
            item.active=true;
            if (item.list.length>1) {
                item.childNav=true;                
            }
            this.$forceUpdate();
        },
        restore(){
            this.toggleHeaderMenuDocker(true)
        },
        close_headline(){
            var current=JSON.parse(self.sessionStorage.getItem('roomHeadline'));
            current[JSON.parse(window.localStorage.getItem('game')).type]['room'+JSON.parse(window.localStorage.getItem('game')).room].closed=true;
            self.sessionStorage.setItem('roomHeadline',JSON.stringify(current));
            this.currentHeadlineClosed=true;
        },
        textUiSwitcher(item){
            if (item.id==='1') {
                if (this.opend===true) {
                    return false;
                }
                this.textUiSwitcherData.forEach(item=>{
                    item.active=false;
                })
                item.active=true;
                this.toggleAuctioneerUI(true);
            }
            if (item.id==='2') {
                if (this.opend===true) {
                    this.textUiSwitcherData.forEach(item=>{
                        item.active=false;
                    })
                    item.active=true;
                    this.toggleAuctioneerUI(true);
                }else {
                    return false;
                }
            }
    },     
    },     
    provide:function(){
        return {
            toggleAuctioneerUI: this.toggleAuctioneerUI,
            addMessage: this.addMessage,
            updateBalance: this.updateBalance,
            scrollToFloorEnd: this.scrollToFloorEnd,
            textUiSwitcher: this.textUiSwitcher,
            textUiSwitcherData: this.textUiSwitcherData,
        }
    },
    mounted() {
        var that=this;
        
        localStorage.setItem('game', JSON.stringify({
            type:this.game.info.game_type,
            room:this.game.info.game_room,
        }));
        
        if (this.$refs.hgroup) this.CustomScroll(this.$refs.hgroup.id);
        this.initTitleMenuClassFlag=true;
        this.$root.$on('titleMenuSwitcher',(payload)=>{            
            if (that.open){
                
            }else {
                that.titleMenuPayload=Object.assign({},payload)
                if (that._TEMPLATE==='template-3') {
                    that.textUiSwitcher(that.textUiSwitcherData[0])
                }else {                
                that.toggleAuctioneerUI(true);                
            }            
            }            
        })
        var openDirectly=false;
        this.roomTypes.forEach(item=>{
            if (that.$route.query.type===item || that.game.info.game_type===item || (JSON.parse(localStorage.getItem('game'))&&JSON.parse(localStorage.getItem('game')).type===item)){
                openDirectly=true;
            }    
        })
        if (openDirectly) {
            this.toggleAuctioneerUI()
        }
        this.clearTradingFloorTimer=setInterval(function(){
            if (that.$refs.hgroup && that.$refs.hgroup.childElementCount>400){
                Array.from(that.$refs.hgroup.children).slice(0,that.$refs.hgroup.childElementCount-400).forEach(item=>{
                    that.$refs.hgroup.removeChild(item)
                })
                console.log('Trading Floor hgroup clear schedule done.')
            }else {
                console.log('attemping excute clear shedule failed: not enough total lines')
            }
        },1000*60*5)

        this.$root.$on('TEMPLATE2_toggleAuctioneerUI',(data)=>{

        })

        var _type=JSON.parse(window.localStorage.getItem('game')).type;
        var _room=JSON.parse(window.localStorage.getItem('game')).room;

        if (['bj28','jnd28','twbg28','mgelg28','jndx28','snfk28','bjsc','xyft','cqssc'].filter(item=>{return item===_type}).length>0) {
            
            getList(_type, _room).then(result=>{
                if (result.data.code==='SUCCESS') {
                    var _result=[];
                    if (result.data.result.list.length>0) {
                        result.data.result.list.forEach(item=>{
                            _result.push(item)
                        })
                        var current=JSON.parse(self.sessionStorage.getItem('roomHeadline'));
                        current[_type]['room'+_room].content=_result;
                        self.sessionStorage.setItem('roomHeadline',JSON.stringify(current));
                        this.currentHeadlineContent=Object.assign({},_result);
                        this.currentHeadlineClosed=current[_type]['room'+_room].closed;
                    }
                }
            })
        }
    },
    beforeDestroy(){
        if (this.clearTradingFloorTimer) {
            window.clearInterval(this.clearTradingFloorTimer)
        }
        this.$root.$off('titleMenuSwitcher');
    },
    computed:{
        tradingFloorMessages(){
            return this.$store.state.tradingFloorMessages;
        },
        chatroomSettings(){
            return this.$store.state.chatroomSettings;
        },
        language(){
            return window.language
        }
    },
    watch:{
        
    }
};
</script>
<template>
    <div id="trading-floor" :class="[_TEMPLATE,{coverd:headerMenuDockerOpen,pc28:game.info.game_type.indexOf('28')>-1}]" @click="restore">
        <div class="headline" v-if="currentHeadlineContent!==null && !currentHeadlineClosed &&  (game.info.game_type.indexOf('28')>-1||game.info.game_type==='cqssc'||game.info.game_type==='bjsc'||game.info.game_type==='xyft')">
            <h4>{{ getWord('important_notice') }}<i class="iconfont icon-23" @click="close_headline()"></i></h4>
            <p>
                <span v-for="item in currentHeadlineContent">{{item.content}}</span>
            </p>
        </div>

        <template v-if="_TEMPLATE==='template-1'">            
            <hgroup id='hgroup' ref="hgroup" v-show="!opend" v-if="!checkRoomType()">
                <template v-for="(item,index) in tradingFloorMessages">
                    <p v-if="item.type==='boardcast'" :key="index" :title="item.title" :class="{result:item.title==='开奖结果',start:item.title==='开始下注'}">
                        {{item.msg}}
                    </p>
                    <h4 v-if="item.type==='bet-all' && item.drawingIndex" :key="index">
                        <img class="avatar" :src="item.avatar" />
                        <div>
                            <b>{{item.nickname}} {{item.time}}</b>
                            <span>{{item.msg}}</span>
                        </div>
                    </h4>
                    <h5 v-if="item.type==='bet-self'" :key="index">
                        <img class="avatar" :src="item.avatar" />
                        <div>
                            <b>{{item.nickname}} {{item.time}}</b>
                            <span>{{item.msg}}</span>
                        </div>
                    </h5>
                </template>
            </hgroup>
            <div class="auctioneer" v-if="!opend" :class="{ hidden:checkRoomType() }">
                <a class="ripple" @click="toggleAuctioneerUI()" style="width: 90%;text-align: center;">{{ getWord('betting') }}</a>
                <!-- <input type="text" @blur="_blured($event)" @keydown.enter="orderAuctionByText()" v-model="auctionText" :placeholder="getWord(['lane', 'number2', 'amount_of_betting'])" disabled />
                <button class="ripple" @click="orderAuctionByText()">{{ getWord('send') }}</button> -->
            </div>
            <transition name="slide-fade"
                @enter="enter"
                @after-enter="afterEnter"
                @leave="leave"
                >
                <AuctioneerUI v-if="open" ref="auctioneerUI"
                    :gameType="game.info.game_type" 
                    :gameRoom='game.info.game_room'
                    :currentDrawing="game.currentDrawing.issue"
                    :avatar="user.avatar"
                    :titleMenuPayload="this.titleMenuPayload"
                     :toggleHeaderMenuDocker="this.toggleHeaderMenuDocker"
                ></AuctioneerUI>
            </transition>
        </template>

        <template v-if="_TEMPLATE==='template-2'">
            <hgroup id='hgroup' ref="hgroup" v-show="!opend" v-if="!checkRoomType()" :class="{'has-room':checkRoomType(roomTypesAdded)||game.info.game_type.indexOf('28')>-1}">
                <template v-for="(item,index) in tradingFloorMessages">
                    <p v-if="item.type==='boardcast'" :key="index" :date-locale="getWord('administrator')+' '+ item.date">
                        <span class="inner" :title="item.title" :class="{result:item.title==='开奖结果',start:item.title==='开始下注'}">
                            <span class="drawing" v-if="item.drawing">【{{item.drawing}}{{ getWord('period') }}】</span>{{fetchMsg(item)}}
                        </span>
                    </p>
                    <h4 v-else-if="item.type==='bet-all' && item.drawingIndex" :key="index">
                        <img class="avatar" :src="item.avatar" />
                        <div>
                            <b>{{item.nickname}}</b>
                            <div class="item">
                                <h6>{{item.drawingIndex}} {{ getWord('period') }}</h6>
                                <div class="inner">
                                    <span class="number">{{formatMsg(item.msg).number}}</span><span class="unit-price">{{ currencySymbol() }}{{formatMsg(item.msg).unitPrice}}</span>
                                    <p>{{ getWord('order') }}<i>{{formatMsg(item.msg).size}}</i>{{ getWord('item') }}, {{ getWord('total3') }}：<i>{{formatMsg(item.msg).totalPrice}}</i>{{ currencySymbol() }}</p>
                                </div>
                            </div>
                        </div>
                    </h4>
                    <h5 v-if="item.type==='bet-self'" :key="index">
                        <img class="avatar" :src="item.avatar" />
                        <div>
                            <b>{{item.nickname}}</b>
                            <div class="item">
                                <h6>{{game.currentDrawing.issue}} {{ getWord('period') }}</h6>
                                <div class="inner">
                                    <span class="number">{{formatMsg(item.msg).number}}</span>
                                    <span class="unit-price">
                                        {{ currencySymbol() }}
                                        {{ formatMsg(item.msg,item).unitPrice }}
                                    </span>
                                    <p>{{ getWord('order') }}<i>{{formatMsg(item.msg).size}}</i>{{ getWord('item') }}, {{ getWord('total3') }}：<i>{{formatMsg(item.msg,item).totalPrice}}</i>{{ currencySymbol() }}</p>
                                </div>
                            </div>
                        </div>
                    </h5>
                </template>
            </hgroup>
            <a id="open-chatroom" @click="toggleChatRoom()" v-if="(chatroomSettings&&chatroomSettings.inlet_display==='1')">
                <img :src="require('@@/assets/images/tradingfloor/icons/chatroom.png')" />
            </a>
            <div class="auctioneer" v-if="!opend" :class="{ hidden:checkRoomType() }">
                <a class="ripple" @click="TEMPLATE2toggleAuctioneerUI()" style="width: 100%;text-align: center;">{{ getWord('betting') }}</a>
                <!-- <input type="text" @blur="_blured($event)" @keydown.enter="orderAuctionByText()" v-model="auctionText" :placeholder="getWord(['lane', 'number2', 'amount_of_betting'])" disabled />
                <button class="ripple" @click="orderAuctionByText()">{{ getWord('send') }}</button> -->
            </div>
            <transition name="slide-fade">
                <nav class="title-menu" v-if="titleMenu.length>0&&game.info.game_type.indexOf('28')===-1&&opend" :class="game.info.game_type">
                    <a v-if="checkRoomType(roomTypesAdded)" @click="toggleAuctioneerUI(true)">X</a>
                    <template v-for="entries in titleMenu">
                        <h5 :class="{active:entries.list.length>1?entries.active:entries.list[0].active}" @click="toggleActive(titleMenu,entries)">
                            <template v-if="entries.list.length===1">
                                <a @click="changeType(titleMenu,entries.list[0],entries)">
                                    {{entries.type}}
                                </a>
                            </template>
                            <template v-else>
                                <a>
                                    {{entries.type}}
                                </a>
                            </template>
                            <template v-if="entries.list.length>1">
                                <i class="iconfont icon-down"></i>
                                <ul class="clearfix" v-show="entries.childNav">
                                    <li v-for="item in entries.list" :class="{ highlight:item.active }">
                                        <a @click.stop="changeType(titleMenu,item,entries)">
                                            {{item.title}}
                                        </a>
                                    </li>
                                </ul>
                            </template>
                        </h5>                        
                    </template>                    
                </nav>
            </transition>
            <transition name="slide-fade"
                @enter="enter"
                @after-enter="afterEnter"
                @leave="leave"
                >
                <AuctioneerUI v-if="open" ref="auctioneerUI"
                    :gameType="game.info.game_type" 
                    :gameRoom='game.info.game_room'
                    :currentDrawing="game.currentDrawing.issue"
                    :avatar="user.avatar"
                    :titleMenuPayload="this.titleMenuPayload"
                    :headerMenuDockerOpen="this.headerMenuDockerOpen"
                    :toggleHeaderMenuDocker="this.toggleHeaderMenuDocker"
                ></AuctioneerUI>
            </transition>
        </template>

        <template v-if="_TEMPLATE==='template-3'">
            <div id="text-ui-switcher" v-if="(game.info.game_type.indexOf('28')>-1&&game.info.game_type!=='pc28one')||game.info.game_type==='cqssc'||game.info.game_type==='bjsc'||game.info.game_type==='xyft'">
                <a v-for="item in textUiSwitcherData" @click="textUiSwitcher(item)" :class="{highlight:item.active}">{{item.title}}</a>                
            </div>
            <hgroup id='hgroup' ref="hgroup" v-show="!opend" v-if="!checkRoomType()">
                <template v-for="(item,index) in tradingFloorMessages">                    
                    <p v-if="item.type==='boardcast'" :key="index" :date-locale="getWord('administrator')+' '+ item.date">
                        <span class="inner" :title="item.title?item.title:getWord('system_message')" :class="{result:item.title===getWord('drawing_result'),start:item.title===getWord('betting_tips5')}">
                            <span class="drawing" v-if="item.drawing">【{{item.drawing}}{{ getWord('period') }}】</span>{{fetchMsg(item)}}
                        </span>
                    </p>
                    <h4 v-else-if="item.type==='bet-all' && item.drawingIndex" :key="index">
                        <img class="avatar" :src="item.avatar" />
                        <div>
                            <b>{{item.nickname}}</b>
                            <div class="item">
                                <h6>{{item.drawingIndex}} {{ getWord('period') }}</h6>
                                <div class="inner">
                                    <span class="number">{{formatMsg(item.msg).number}}</span>
                                    <span class="unit-price">{{ currencySymbol() }}{{formatMsg(item.msg,item).unitPrice}}</span>
                                    <p>{{ getWord('order') }}<i>{{formatMsg(item.msg).size}}</i>{{ getWord('item') }}, {{ getWord('total3') }}：<i>{{formatMsg(item.msg,item).totalPrice}}</i>{{ currencySymbol() }}</p>
                                </div>
                            </div>
                        </div>
                    </h4>
                    <h5 v-if="item.type==='bet-self'" :key="index">
                        <img class="avatar" :src="item.avatar" />
                        <div>
                            <b>{{item.nickname}}</b>
                            <div class="item">
                                <h6>{{item.drawingIndex}} {{ getWord('period') }}</h6>
                                <div class="inner">
                                    <span class="number">{{formatMsg(item.msg).number}}</span>
                                    <span class="unit-price">{{ currencySymbol() }}{{formatMsg(item.msg,item).unitPrice}}</span>
                                    <p>{{ getWord('order') }}<i>{{formatMsg(item.msg).size}}</i>{{ getWord('item') }}, {{ getWord('total3') }}：<i>{{formatMsg(item.msg,item).totalPrice}}</i>{{ currencySymbol() }}</p>
                                </div>
                            </div>
                        </div>
                    </h5>
                </template>
            </hgroup>
            <!-- <div class="auctioneer" v-if="!opend" :class="{ hidden:checkRoomType() }">                
                <input type="text" @blur="_blured($event)" @keydown.enter="orderAuctionByText()" v-model="auctionText" :placeholder="getWord(['lane', 'number2', 'amount_of_betting'])" disabled />
                <button class="ripple" @click="orderAuctionByText()">{{ getWord('betting') }}</button>
            </div> -->
            <transition name="slide-fade"
                @enter="enter"
                @after-enter="afterEnter"
                @leave="leave"
                >
                <AuctioneerUI v-if="open" ref="auctioneerUI"
                    :gameType="game.info.game_type" 
                    :gameRoom='game.info.game_room'
                    :currentDrawing="game.currentDrawing.issue"
                    :avatar="user.avatar"
                     :toggleHeaderMenuDocker="this.toggleHeaderMenuDocker"
                    :titleMenuPayload="this.titleMenuPayload"
                ></AuctioneerUI>
            </transition>
        </template>
    </div>
</template>
<style lang='scss' scoped>
@import "@@/assets/style/_variables";
.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-leave-active {
    transition: all .3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateY(100%);
    opacity: 0;
}

#trading-floor {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: #ffffff;
    flex: 1 1 0;

    &.template-3 {
        position: relative;
        background-color: #EEEEEE;
        overflow: auto;

        .headline {
            top: .64rem;
            box-shadow: none;
            background-color: #ffffff;
        }

        #text-ui-switcher {
            justify-content: space-around;

            font-size: .28rem;                        
            background-color: #EEEEEE;
            border-bottom: 1px solid #B0B0B0;

            position: relative;
            z-index: 1;

            a {
                width: 50%;
                color: #5F646E;
                display: inline-block;
                text-align: center;
                padding: .125rem 0;

                &.highlight {
                    color: #ffffff;
                    background-color: #FF9726;
                }
            }
        }

        hgroup {
            background-color: #F5F5F9;
            padding: 0 .1rem;
            padding-top: 0.6rem;

            &.has-room {
                padding-top: 0rem;
            }

            p {
                background-color: transparent;
                width: auto;

                &:before {
                    content: attr(date-locale);
                    font-size: .2rem;
                    background-color: #E2E2E5;
                    display: inline-block;
                    padding: .075rem .2rem;
                }

                .inner {
                    display: inline-block;
                    background-color: #E2E2E5;
                    padding: .25rem .1rem;
                    text-align: center;
                    box-sizing: border-box;
                    font-size: .28rem;
                    width: 5.5rem;
                    border-radius: 3px;

                    &:before {
                        content: attr(title);
                        font-size:.36rem;
                        text-align:center;                        
                        display: block;
                        margin-bottom: .125rem;
                    }

                    &.result {

                        &:before {
                            color: $TEMPLATE2-theme-color;
                        }
                    }

                    &.start {

                        &:before {
                            color: #00A447;   
                        }
                    }

                    .drawing {
                        color: #FF0F0F;
                    }
                }
            }

            h4,
            h5 {

                div {
                    margin-left: 0;
                    margin-right: 0;
                }

                > div {
                    margin-right: .15rem;
                }

                b {
                    text-align: right;
                    font-size: .3rem;
                    color: $TEMPLATE2-theme-color;
                    margin-bottom: 0;
                }

                .item {
                    width: 4.5rem;

                    h6 {
                        color: #ffffff;
                        font-weight: normal;
                        padding: .1rem .25rem;
                        font-size: .32rem;
                        background: rgb(0, 144, 255);
                        background: linear-gradient(90deg, rgba(107, 191, 255, 1) 0%, rgba(0, 144, 255, 1) 100%);
                        border-radius: 3px 3px 0 0;
                    }

                    .inner {
                        background-color: #ffffff;
                        border-radius: 0 0 3px 3px;

                        span {
                            background-color: transparent;
                            display: inline-block;

                            &.unit-price {
                                float: right;
                                color: $TEMPLATE2-theme-color;
                            }
                        }

                        p {
                            font-size: .3rem;
                            margin:0;
                            text-align: right;
                            padding-left: .175rem;
                            padding-right: .175rem;
                            padding-top: 0;
                            clear: both;

                            &:before {
                                content:none;
                            }

                            i {
                                font-style: normal;
                                color: #FF0F0F;
                            }
                        }
                    }
                }
            }

            h4 {

                > div {
                    margin-left: .15rem;
                    margin-right: 0;
                }

                b {
                    text-align: left;
                }

                .item {

                    h6 {
                        background: rgb(255,205,34);
                        background: linear-gradient(90deg, rgba(255,205,34,1) 0%, rgba(255,146,0,1) 100%);
                    }
                }
            }
        }

        .auctioneer {
            background-color: #e8e8e8;
            padding:.125rem;
            height: .675rem;

            input {
                border:0;
                background-color: #F5F5F9;
                margin:0;
                width: 82%;
                padding: .175rem;
            }

            button {
                font-size: .28rem;
                color: #333333;
                white-space: nowrap;
                background-color: #FFB400;
                float: right;
                width: 16.5%;
                overflow: hidden;
            }
        }
    }

    &.template-2 {
        height: 0;

        &.pc28 {

            #auctioneer-ui {            
                padding-top: 0rem;
            }
        }

        &.coverd {

            &:before {
                content:'';
                background-color: rgba(0, 0, 0, 0.5);
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;            
            }
        }

        hgroup {
            background-color: #F5F5F9;
            padding: 0 .1rem;
            padding-top: 0.6rem;

            &.has-room {
                padding-top: 0rem;
            }

            p {
                background-color: transparent;
                width: auto;

                &:before {
                    content: attr(date-locale);
                    font-size: .2rem;
                    background-color: #E2E2E5;
                    display: inline-block;
                    padding: .075rem .2rem;
                }

                .inner {
                    display: inline-block;
                    background-color: #E2E2E5;
                    padding: .25rem .1rem;
                    text-align: center;
                    box-sizing: border-box;
                    font-size: .28rem;
                    width: 5.5rem;
                    border-radius: 3px;

                    &:before {
                        content: attr(title);
                        font-size:.36rem;
                        text-align:center;                        
                        display: block;
                        margin-bottom: .125rem;
                    }

                    &.result {

                        &:before {
                            color: $TEMPLATE2-theme-color;
                        }
                    }

                    &.start {

                        &:before {
                            color: #00A447;   
                        }
                    }

                    .drawing {
                        color: #FF0F0F;
                    }
                }
            }

            h4,
            h5 {

                div {
                    margin-left: 0;
                    margin-right: 0;
                }

                > div {
                    margin-right: .15rem;
                }

                b {
                    text-align: right;
                    font-size: .3rem;
                    color: $TEMPLATE2-theme-color;
                    margin-bottom: 0;
                }

                .item {
                    width: 4.5rem;

                    h6 {
                        color: #ffffff;
                        font-weight: normal;
                        padding: .1rem .25rem;
                        font-size: .32rem;
                        background: rgb(0, 144, 255);
                        background: linear-gradient(90deg, rgba(107, 191, 255, 1) 0%, rgba(0, 144, 255, 1) 100%);
                        border-radius: 3px 3px 0 0;
                    }

                    .inner {
                        background-color: #ffffff;
                        border-radius: 0 0 3px 3px;
                        padding: .175rem;

                        span {
                            background-color: transparent;
                            display: inline-block;
                            padding: 0;

                            &.unit-price {
                                float: right;
                                color: $TEMPLATE2-theme-color;
                            }
                        }

                        p {
                            font-size: .3rem;
                            margin:0;
                            text-align: right;
                            padding: 0;
                            padding-top: .175rem;
                            clear: both;
                            display: flex;
						    flex-wrap: wrap;
						    justify-content: flex-end;

                            &:before {
                                content:none;
                            }

                            i {
                                font-style: normal;
                                color: #FF0F0F;
                            }
                        }
                    }
                }
            }

            h4 {

                > div {
                    margin-left: .15rem;
                    margin-right: 0;
                }

                b {
                    text-align: left;
                }

                .item {

                    h6 {
                        background: rgb(255,205,34);
                        background: linear-gradient(90deg, rgba(255,205,34,1) 0%, rgba(255,146,0,1) 100%);
                    }
                }
            }
        }

        #open-chatroom {
            position: fixed;
            right: .25rem;
            bottom: 2rem;
            z-index: 1;

            img {
                width: 1rem;
            }
        }

        .title-menu {
            display: flex;
            justify-content: space-around;
            border-bottom: 2px solid $TEMPLATE2-theme-color;
            margin-bottom: .3rem;
            position: absolute;
            z-index: 1;
            width: 100%;
            background-color: #ffffff;
            border-top: 1px solid #f5f5f9;
            padding-top: .2rem;
            margin-top: -.3rem;
            z-index: 2;

            &.xinjapowz {

                h5 {

                    a {
                        padding-left: .125rem;
                        padding-right: .125rem;
                    }
                }
            }

            &.wanneng,
            &.damacai,
            &.duoduo,
            &.yuedawan,
            &.saimahui,
            &.shaba88,
            &.xinjapowz {
            	margin-top: 0;
            	padding-top: .3rem;

                + #auctioneer-ui {
                    padding-top: .85rem;
                }                
            }

            + #auctioneer-ui {
                padding-top: 1rem;
            }

            h5 {
                position: relative;
                display: flex;
                font-size: .3rem;
                color: #6E6E6E;
                word-break: break-all;

                a {
                    display: inline-block;
                    padding: 0 .05rem .2rem .05rem;
                }

                > a {
                    line-height: 1;
                }

                &:first-child {
                    ul {
                        left: .1rem;
                        right: auto!important;
                    }
                }

                &:last-child {
                    ul {
                        right: .1rem;
                    }
                }

                &.active {

                    > a {
                        color: $TEMPLATE2-theme-color;
                    }

                    > i {
                        color: $TEMPLATE2-theme-color;
                    }

                    &:before {
                        content:'';
                        width: 0; 
                        height: 0; 
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        border-bottom: 6px solid $TEMPLATE2-theme-color;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        margin-left: -3px;
                    }
                }

                > ul {
                    list-style: none;
                    position: absolute;
                    top: 0.6rem;
                    margin-top: 2px;
                    max-height: 39vh;
                    overflow: auto;
                    z-index: 1;

                    li {

                        a {
                            border: 1px solid #cccccc;
                            display: block;
                            padding: 0.25rem .5rem;
                            white-space: nowrap;
                            font-weight: normal;
                            background-color: #ffffff;
                            // min-width: 1.5rem;
                            text-align: center;
                            border-bottom: 0;
                        }

                        &:nth-child(2n+2) {

                            a {              
                                border-top:1px solid #cccccc;            
                                background-color: #FFFBE0;
                            }
                        }

                        &:last-child {
                            a {              
                                border-bottom:1px solid #cccccc;            
                            }
                        }
                    }
                }
            }
            
            > a {
                font-size: 0.3rem;
                font-weight: 300;
                display: inline-block;
                background-color: #cccccc;
                width: .56rem;
                height: .56rem;
                line-height: .56rem;
                text-align: center;
                color: #ffffff;
                border-radius: 100%;
                margin-top: -.075rem;
                position: relative;
                left: -.1rem;
                order: 10;
                flex: 0 0 .52rem;
                top: -0.025rem;
            }
        }

        #auctioneer-ui {        
            box-sizing: border-box;
        }

        .auctioneer {
            position: relative;
            padding: 0;
            display: flex;
            flex: 0 0 1rem;

            a {
                border-radius:0;
                background-color: #FF7B00;
                // position: absolute;
                left: 0;
                top: 0;
                height: 0.7rem;
                line-height: 0.7rem;
                font-size: .34rem;
                padding: .15rem;
                word-break: break-all;
                line-height: 1;
                width: 4rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            input {
                // height: .75rem;
                width: 4.15rem;
                border:0;
                background-color: #F5F5F9;
                margin: 0;
            }

            button {
                background-color: $TEMPLATE2-theme-color;
                // position: absolute;
                right: 0;
                top: 0;
                height: 0.7rem;
                box-sizing: content-box;
                border-radius: 0;
                font-size: .34rem;
                width: 3rem;
                word-break: break-all;
                line-height: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .headline {
        z-index: 1;
        position: absolute;
        width: 100%;
        background: #f5f5f9;
        box-sizing: border-box;
        padding: .25rem;
        box-shadow: 0 0 5px 1px rgba(0,0,0,.2);
        z-index: 1;

        h4 {
            font-size: .3rem;
            font-weight: normal;
            color: #EC2829;
            text-align: center;

            > .iconfont {
                float: right;
                color: $TEMPLATE2-theme-color;
                margin-top: .025rem;
            }
        }

        p {
            clear: both;
            margin-top: .125rem;
            color: #5F646E;
            max-height: 0.7rem;
            overflow: auto;

            span {
                display: block;
                font-size: .26rem;
            }
        }
    }

    hgroup {
        padding: 0 .25rem;
        flex-grow: 1;
        overflow: auto;

        .avatar {
            width: .74rem;
            height: .74rem;
            display: block;
        }

        p {
            text-align: center;
            color: #333333;
            font-size: .24rem;
            font-weight: 500;
            background-color: #88C75C;
            width: 70%;
            margin: .25rem auto;
            padding: .125rem 0;
            border-radius: 4px;

            &:before {
                content: attr(title);
                display: block;
                font-size: .28rem;
                margin-bottom: .125rem;
            }
        }

        h4 {

            div {
                margin-left: .25rem;
            }
        }

        h4,
        h5 {
            margin: .25rem auto;
            display: flex;
            font-weight: 500;

            b {
                display: block;
                font-weight: 500;
                color: #999999;
                margin-bottom: .1rem;
            }

            span {
                font-size: .3rem;
                background-color: #F3F3F4;
                position: relative;
                padding: .175rem;
                display: block;
                word-break: break-all;

                &:before {
                    content: "";
                    border: solid;
                    border-color: transparent #f3f3f4;
                    border-width: 6px 6px 6px 0px;
                    left: -6px;
                    top: 50%;
                    position: absolute;
                    margin-top: -6px;
                }
            }
        }

        h5 {
            flex-flow: row-reverse;

            span {

                &:before {
                    border-width: 6px 0px 6px 6px;
                    left: auto;
                    right: -6px;
                }
            }

            div {
                margin-right: .25rem;
            }
        }
    }

    .auctioneer {
        padding: .125rem .25rem;
        border-top: 1px solid #f3f3f4;

        a,button {
            color: #ffffff;
            font-size: .34rem;
            line-height: .34rem;
            border-radius: 4px;
            padding: .15rem .3rem;
            display: inline-block;
            vertical-align: middle;
            border: 1px solid transparent;
        }

        a {
            background-color: #ED7428;            
        }

        input {
            width: 4.05rem;
            line-height: .34rem;
            border: 1px solid #E6E6EA;
            padding: .15rem;
            font-size: .28rem;
            box-sizing: border-box;
            margin: 0 .125rem;
            vertical-align: middle;

            &::placeholder {
                color: #cccccc;
            }
        }

        button {            
            background-color: #3985E1;
        }

        &.hidden {
            visibility: hidden;
        }
    }
}
</style>