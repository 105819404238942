<script>
import {
  cloudImageBaseUrl,
  getClientTime,
  pc28TypeTitle,
  pc28TypeRule,
  pc28ColorRule,
  TEMPLATE3_pc28TypeRule,
  pk10TypeRule,
  pk10TypeTitle,
  pk10ClassRule,
  pk10ColorRule,
  xyncTypeRule,
  TEMPLATE3_xyncTypeRule,
  xyncTypeTitle,
  TEMPLATE3_xyncTypeTitle,
  xyncClassRule,
  TEMPLATE3_xyncClassRule,
  xyncColorRule,
  TEMPLATE3_defaultColorRule,
  dtClassicTypeTitle,
  dtClassicTypeRule,
  dtClassicClassRule,
  lhcClassRule,
  TEMPLATE3_lhcClassRule,
  lhcTypeTitle,
  TEMPLATE3_lhcTypeTitle,
  lhcTypeRule,
  TEMPLATE3_lhcTypeRule,
  lhcWeiLianRule,
  lhcNumberList,
  lhcColorRule,
  lhcZodiacSeries,
  kuai3TypeRule,
  kuai3TypeTitle,
  tencentTypeTitle,
  tencentTypeRule,
  tencentClassRule,
  gdfiveselectColorRule,
  TEMPLATE3_gdfiveselectColorRule,
  gdfiveselectTypeTitle,
  TEMPLATE3_gdfiveselectTypeTitle,
  gdfiveselectTypeRule,
  TEMPLATE3_gdfiveselectTypeRule,
  gdfiveselectClassRule,
  TEMPLATE3_gdfiveselectClassRule,
  gdfiveselectNumberList,
  gdfiveselectZhixuanNumberList,
  fc3dClassRule,
  TEMPLATE3_fc3dClassRule,
  fc3dTypeTitle,
  TEMPLATE3_fc3dTypeTitle,
  fc3dTypeRule,
  TEMPLATE3_fc3dTypeRule,
  fc3dErzidingweiNumberList,
  fc3dNumberList,
  gxklsfTypeRule,
  gxklsfTypeTitle,
  gxklsfClassRule,
  gxklsfColorRule,
  TEMPLATE3_gxklsfColorRule,
  gdkltenTypeTitle,
  TEMPLATE3_gdkltenTypeTitle,
  gdkltenTypeRule,
  TEMPLATE3_gdkltenTypeRule,
  gdkltenColorRule,
  gdkltenClassRule,
  TEMPLATE3_gdkltenClassRule,
  gdkltenNumberList,
  jndxyTypeTitle,
  jndxyTypeRule,
  jndxyClassRule,
  ttcTypeRule,
  TEMPLATE3_ttcTypeRule,
  ttcNumberList,
  tiantiancaiClassRule,
  TEMPLATE3_tiantiancaiClassRule,
  tiantiancaiColorRule,
  TEMPLATE3_tiantiancaiColorRule,
  createType,
} from "@/CONF.js";
import $sock from "@/components/WebSocket"; //聊天的js文件
import { touzhu, beilv, getAnimals } from "@/api/touzhu.js";
import { confirmOdds } from "@/api/beijing28.js";
import { explainList } from "@/api/home.js"; //玩法规则

export default {
  name: "AuctioneerUI",
  data() {
    const that = this;
    return {
      api: {
        explainList: {
          func: explainList,
          result: null,
        },
      },

      cloudImageBaseUrl_coins: cloudImageBaseUrl,
      disabled: false,
      totalAmount: 0, //投注总金额
      amount: null, //单个下注的金额
      countOfCombinationBet: 0, //组合注数

      aside: {
        coinSelector: false,
        orderComfirm: false,
        asideGameRule: false,
      },

      type: [],
      pc28TypeTitle: [
        getWord("mixture"),
        getWord("bose") +
          "/" +
          getWord("baozi") +
          "/" +
          getWord("shunzi") +
          "/" +
          getWord("duizi"),
        getWord("item_tema"),
      ],
      pc28TypeRule,
      pc28ColorRule,
      TEMPLATE3_pc28TypeRule,
      pk10TypeRule,
      pk10TypeTitle: [
        getWord("guanyazuhe"),
        getWord("guanyahe"),
        getWord("first4"),
        getWord("second4"),
        getWord("third4"),
        getWord("fourth_place"),
        getWord("fifth_place"),
        getWord("sixth_place"),
        getWord("seventh_place"),
        getWord("eight_place"),
        getWord("ninth_place"),
        getWord("tenth_place"),
      ],
      pk10ClassRule,
      pk10ColorRule,

      xyncTypeRule,
      TEMPLATE3_xyncTypeRule,
      xyncTypeTitle: [
        getWord(["zong", "draw"]),
        getWord("first_ball"),
        getWord("second_ball"),
        getWord("third_ball"),
        getWord("fourth_ball"),
        getWord("fifth_ball"),
        getWord("sixth_ball"),
        getWord("seventh_ball"),
        getWord("eighth_ball"),
        getWord("first_ball"),
        getWord("second_ball"),
        getWord("third_ball"),
        getWord("fourth_ball"),
        getWord("fifth_ball"),
        getWord("sixth_ball"),
        getWord("seventh_ball"),
        getWord("eighth_ball"),
        getWord("zhengma"),
        getWord("lianma"),
        getWord("first_ball"),
        getWord("second_ball"),
        getWord("third_ball"),
        getWord("fourth_ball"),
        getWord("fifth_ball"),
        getWord("sixth_ball"),
        getWord("seventh_ball"),
        getWord("eighth_ball"),
      ],
      TEMPLATE3_xyncTypeTitle: [
        getWord(["zong", "draw"]),
        getWord("first_ball"),
        getWord("second_ball"),
        getWord("third_ball"),
        getWord("fourth_ball"),
        getWord("fifth_ball"),
        getWord("sixth_ball"),
        getWord("seventh_ball"),
        getWord("eighth_ball"),
        getWord("first_ball"),
        getWord("second_ball"),
        getWord("third_ball"),
        getWord("fourth_ball"),
        getWord("fifth_ball"),
        getWord("sixth_ball"),
        getWord("seventh_ball"),
        getWord("eighth_ball"),
        getWord("zhengma"),
        getWord(["renxuan", "er"]),
        getWord("xuanerlianzu"),
        getWord(["renxuan", "san"]),
        getWord("xuansanqianzu"),
        getWord(["renxuan", "si"]),
        getWord(["renxuan", "wu"]),
        getWord("first_ball"),
        getWord("second_ball"),
        getWord("third_ball"),
        getWord("fourth_ball"),
        getWord("fifth_ball"),
        getWord("sixth_ball"),
        getWord("seventh_ball"),
        getWord("eighth_ball"),
      ],
      xyncClassRule,
      TEMPLATE3_xyncClassRule,
      xyncColorRule,

      dtClassicTypeTitle: [
        getWord(["number", "yi", "number_of_item2"]),
        getWord(["number", "er", "number_of_item2"]),
        getWord(["number", "san", "number_of_item2"]),
        getWord(["number", "si", "number_of_item2"]),
        getWord(["number", "wu", "number_of_item2"]),
        getWord(["zong", "draw"]) + "/" + getWord(["long", "hu"]),
        getWord("qiansan"),
        getWord("zhongsan"),
        getWord("housan"),
      ],
      dtClassicTypeRule,
      dtClassicClassRule,
      lhcClassRule,
      TEMPLATE3_lhcClassRule,
      lhcTypeTitle: [
        getWord("item_tema"),
        getWord("zhengma"),
        getWord(["zhengte", "yi"]),
        getWord(["zhengte", "er"]),
        getWord(["zhengte", "san"]),
        getWord(["zhengte", "si"]),
        getWord(["zhengte", "wu"]),
        getWord(["zhengte", "liu"]),
        getWord("zhengtewei"),
        getWord("texiao"),
        getWord(["yi", "xiao"]),
        getWord("hexiao"),
        getWord("xiaolian"),
        getWord("banbo"),
        getWord("lianma"),
        getWord("weishulian"),
        getWord("quanbuzhong"),
      ],
      TEMPLATE3_lhcTypeTitle: [
        getWord("item_tema"),
        getWord("zhengma"),
        getWord(["zhengte", "yi"]),
        getWord(["zhengte", "er"]),
        getWord(["zhengte", "san"]),
        getWord(["zhengte", "si"]),
        getWord(["zhengte", "wu"]),
        getWord(["zhengte", "liu"]),
        getWord("zhengtewei"),
        getWord("texiao"),
        getWord(["yi", "xiao"]),
        getWord("hexiao"),
        getWord(["er", "xiao", "lian"]),
        getWord(["san", "xiao", "lian"]),
        getWord(["si", "xiao", "lian"]),
        getWord(["wu", "xiao", "lian"]),
        getWord("banbo"),
        getWord(["si", "quan", "zhong"]),
        getWord(["san", "quan", "zhong"]),
        getWord(["san", "zhong", "er"]),
        getWord(["er", "quan", "zhong"]),
        getWord(["er", "zhong", "te"]),
        getWord("techuan"),
        getWord(["er", "wei", "lian"]),
        getWord(["san", "wei", "lian"]),
        getWord(["si", "wei", "lian"]),
        getWord(["wu", "buzhong"]),
        getWord(["liu", "buzhong"]),
        getWord(["qi", "buzhong"]),
        getWord(["ba", "buzhong"]),
        getWord(["jiu", "buzhong"]),
        getWord(["shi", "buzhong"]),
        getWord(["eleven", "buzhong"]),
        getWord(["twelve", "buzhong"]),
      ],
      lhcTypeRule,
      TEMPLATE3_lhcTypeRule,
      lhcWeiLianRule: lhcWeiLianRule(),
      lhcNumberList,
      lhcColorRule,
      lhcZodiacSeries,
      lhcAnimalRule: [],
      kuai3TypeRule,
      kuai3TypeTitle: [
        getWord("sanjun"),
        getWord(["da", "small"]),
        getWord("dianshu"),
        getWord("weitou") + "," + getWord("quantou"),
        getWord("changpai"),
        getWord("duanpai"),
      ],
      tencentTypeTitle: [
        getWord("first_ball"),
        getWord("second_ball"),
        getWord("third_ball"),
        getWord("fourth_ball"),
        getWord("fifth_ball"),
        getWord(["zong", "draw"]) + "/" + getWord(["long", "hu"]),
        getWord("qiansan"),
        getWord("zhongsan"),
        getWord("housan"),
        getWord("first_ball") + "VS" + getWord("second_ball"),
        getWord("first_ball") + "VS" + getWord("third_ball"),
        getWord("first_ball") + "VS" + getWord("fourth_ball"),
        getWord("first_ball") + "VS" + getWord("fifth_ball"),
        getWord("second_ball") + "VS" + getWord("third_ball"),
        getWord("second_ball") + "VS" + getWord("fourth_ball"),
        getWord("second_ball") + "VS" + getWord("fifth_ball"),
        getWord("third_ball") + "VS" + getWord("fourth_ball"),
        getWord("third_ball") + "VS" + getWord("fifth_ball"),
        getWord("fourth_ball") + "VS" + getWord("fifth_ball"),
      ],
      tencentTypeRule,
      tencentClassRule,
      gdfiveselectTypeTitle: [
        getWord("first_ball"),
        getWord("second_ball"),
        getWord("third_ball"),
        getWord("fourth_ball"),
        getWord("fifth_ball"),
        getWord("first_ball"),
        getWord("second_ball"),
        getWord("third_ball"),
        getWord("fourth_ball"),
        getWord("fifth_ball"),
        getWord(["zong", "draw"]),
        getWord(["zong", "wei"]) + "/" + getWord(["long", "hu"]),
        getWord("lianma"),
        getWord("zhixuan"),
      ],
      TEMPLATE3_gdfiveselectTypeTitle: [
        getWord("first_ball"),
        getWord("second_ball"),
        getWord("third_ball"),
        getWord("fourth_ball"),
        getWord("fifth_ball"),
        getWord("first_ball"),
        getWord("second_ball"),
        getWord("third_ball"),
        getWord("fourth_ball"),
        getWord("fifth_ball"),
        getWord(["zong", "draw"]),
        getWord(["zong", "wei"]) + "/" + getWord(["long", "hu"]),
        getWord(["lianma", "yi", "zhong", "yi"]),
        getWord(["lianma", "er", "zhong", "er"]),
        getWord(["lianma", "san", "zhong", "san"]),
        getWord(["lianma", "si", "zhong", "si"]),
        getWord(["lianma", "wu", "zhong", "wu"]),
        getWord(["lianma", "liu", "zhong", "wu"]),
        getWord(["lianma", "qi", "zhong", "wu"]),
        getWord(["lianma", "ba", "zhong", "wu"]),
        getWord(["qianer", "zuxuan"]),
        getWord(["qiansan", "zuxuan"]),
        getWord(["qianer", "zhixuan"]),
        getWord(["qiansan", "zhixuan"]),
      ],
      gdfiveselectTypeRule,
      TEMPLATE3_gdfiveselectTypeRule,
      gdfiveselectClassRule,
      TEMPLATE3_gdfiveselectClassRule,
      gdfiveselectNumberList,
      gdfiveselectZhixuanNumberList,
      fc3dClassRule,
      TEMPLATE3_fc3dClassRule,
      fc3dTypeTitle: fc3dTypeTitle(),
      TEMPLATE3_fc3dTypeTitle: [
        getWord(["bai2", "place"]),
        getWord(["shi2", "place"]),
        getWord(["ge", "place"]),
        getWord(["bai2", "shi2"]),
        getWord(["bai2", "ge"]),
        getWord(["shi2", "ge"]),
        getWord(["bai2", "shi2", "draw", "weishu"]),
        getWord(["bai2", "ge", "draw", "weishu"]),
        getWord(["shi2", "ge", "draw", "weishu"]),
        getWord(["bai2", "shi2", "ge", "heshu"]),
        getWord(["bai2", "shi2", "ge", "draw", "weishu"]),
        getWord(["yi", "zi", "combine"]),
        getWord(["er", "zi", "combine"]),
        getWord(["bai2", "place"]),
        getWord(["shi2", "place"]),
        getWord(["ge", "place"]),
        getWord(["er", "zi", "positioning", "bai2", "shi2", "place"]),
        getWord(["er", "zi", "positioning", "bai2", "ge", "place"]),
        getWord(["er", "zi", "positioning", "shi2", "ge", "place"]),
        getWord(["bai2", "shi2", "heshu"]),
        getWord(["bai2, shi2, draw, weishu"]),
        getWord(["bai2, ge, heshu"]),
        getWord(["bai2", "ge", "draw", "weishu"]),
        getWord(["shi2", "ge", "heshu"]),
        getWord(["shi2", "ge", "draw", "weishu"]),
        getWord(["zuxuan", "san"]),
        getWord(["zuxuan", "liu"]),
        getWord("kuadu"),
      ],
      fc3dTypeRule,
      TEMPLATE3_fc3dTypeRule,
      fc3dErzidingweiNumberList,
      fc3dNumberList,
      gxklsfTypeRule,
      gxklsfTypeTitle: [
        {
          title: getWord("first_ball"),
          class: ["class1", "class3"],
        },
        {
          title: getWord("second_ball"),
          class: ["class1", "class4"],
        },
        {
          title: getWord("third_ball"),
          class: ["class1", "class5"],
        },
        {
          title: getWord("fourth_ball"),
          class: ["class1", "class6"],
        },
        {
          title: getWord("fifth_ball"),
          class: ["class1", "class7"],
        },
        {
          title: getWord("first_ball"),
          class: ["class2", "class3"],
        },
        {
          title: getWord("second_ball"),
          class: ["class2", "class4"],
        },
        {
          title: getWord("third_ball"),
          class: ["class2", "class5"],
        },
        {
          title: getWord("fourth_ball"),
          class: ["class2", "class6"],
        },
        {
          title: getWord("fifth_ball"),
          class: ["class2", "class7"],
        },
        {
          title: getWord(["zong", "draw"]) + "/" + getWord(["long", "hu"]),
          class: ["class2"],
        },
        {
          title: getWord("first_ball"),
          class: ["class3"],
        },
        {
          title: getWord("second_ball"),
          class: ["class4"],
        },
        {
          title: getWord("third_ball"),
          class: ["class5"],
        },
        {
          title: getWord("fourth_ball"),
          class: ["class6"],
        },
        {
          title: getWord("fifth_ball"),
          class: ["class7"],
        },
      ],
      gxklsfClassRule,
      gxklsfColorRule,
      gdkltenTypeTitle: [
        {
          title: getWord("first_ball"),
          class: ["class2", "class3"],
        },
        {
          title: getWord("second_ball"),
          class: ["class2", "class4"],
        },
        {
          title: getWord("third_ball"),
          class: ["class2", "class5"],
        },
        {
          title: getWord("fourth_ball"),
          class: ["class2", "class6"],
        },
        {
          title: getWord("fifth_ball"),
          class: ["class2", "class7"],
        },
        {
          title: getWord("sixth_ball"),
          class: ["class2", "class8"],
        },
        {
          title: getWord("seventh_ball"),
          class: ["class2", "class9"],
        },
        {
          title: getWord("eighth_ball"),
          class: ["class2", "class10"],
        },

        {
          title: getWord(["zong", "draw"]),
          class: ["class1", "class11"],
        },
        {
          title: getWord("first_ball"),
          class: ["class1", "class3"],
        },
        {
          title: getWord("second_ball"),
          class: ["class1", "class4"],
        },
        {
          title: getWord("third_ball"),
          class: ["class1", "class5"],
        },
        {
          title: getWord("fourth_ball"),
          class: ["class1", "class6"],
        },
        {
          title: getWord("fifth_ball"),
          class: ["class1", "class7"],
        },
        {
          title: getWord("sixth_ball"),
          class: ["class1", "class8"],
        },
        {
          title: getWord("seventh_ball"),
          class: ["class1", "class9"],
        },
        {
          title: getWord("eighth_ball"),
          class: ["class1", "class10"],
        },

        {
          title: getWord("first_ball"),
          class: ["class3"],
        },
        {
          title: getWord("second_ball"),
          class: ["class4"],
        },
        {
          title: getWord("third_ball"),
          class: ["class5"],
        },
        {
          title: getWord("fourth_ball"),
          class: ["class6"],
        },
        {
          title: getWord("fifth_ball"),
          class: ["class7"],
        },
        {
          title: getWord("sixth_ball"),
          class: ["class8"],
        },
        {
          title: getWord("seventh_ball"),
          class: ["class9"],
        },
        {
          title: getWord("eighth_ball"),
          class: ["class10"],
        },
        {
          title: getWord("zhengma"),
          class: ["class11"],
        },
        {
          title: getWord("lianma"),
          class: ["class12"],
        },
      ],
      TEMPLATE3_gdkltenTypeTitle: [
        {
          title: getWord("first_ball"),
          class: ["class2", "class3"],
        },
        {
          title: getWord("second_ball"),
          class: ["class2", "class4"],
        },
        {
          title: getWord("third_ball"),
          class: ["class2", "class5"],
        },
        {
          title: getWord("fourth_ball"),
          class: ["class2", "class6"],
        },
        {
          title: getWord("fifth_ball"),
          class: ["class2", "class7"],
        },
        {
          title: getWord("sixth_ball"),
          class: ["class2", "class8"],
        },
        {
          title: getWord("seventh_ball"),
          class: ["class2", "class9"],
        },
        {
          title: getWord("eighth_ball"),
          class: ["class2", "class10"],
        },

        {
          title: getWord(["zong", "draw"]),
          class: ["class1", "class11"],
        },
        {
          title: getWord("first_ball"),
          class: ["class1", "class3"],
        },
        {
          title: getWord("second_ball"),
          class: ["class1", "class4"],
        },
        {
          title: getWord("third_ball"),
          class: ["class1", "class5"],
        },
        {
          title: getWord("fourth_ball"),
          class: ["class1", "class6"],
        },
        {
          title: getWord("fifth_ball"),
          class: ["class1", "class7"],
        },
        {
          title: getWord("sixth_ball"),
          class: ["class1", "class8"],
        },
        {
          title: getWord("seventh_ball"),
          class: ["class1", "class9"],
        },
        {
          title: getWord("eighth_ball"),
          class: ["class1", "class10"],
        },

        {
          title: getWord("first_ball"),
          class: ["class3"],
        },
        {
          title: getWord("second_ball"),
          class: ["class4"],
        },
        {
          title: getWord("third_ball"),
          class: ["class5"],
        },
        {
          title: getWord("fourth_ball"),
          class: ["class6"],
        },
        {
          title: getWord("fifth_ball"),
          class: ["class7"],
        },
        {
          title: getWord("sixth_ball"),
          class: ["class8"],
        },
        {
          title: getWord("seventh_ball"),
          class: ["class9"],
        },
        {
          title: getWord("eighth_ball"),
          class: ["class10"],
        },
        {
          title: getWord("zhengma"),
          class: ["class11"],
        },

        {
          title: getWord(["renxuan", "er"]),
          class: ["class12"],
        },
        {
          title: getWord("xuanerlianzu"),
          class: ["class13"],
        },
        {
          title: getWord(["renxuan", "san"]),
          class: ["class14"],
        },
        {
          title: getWord("xuansanqianzu"),
          class: ["class15"],
        },
        {
          title: getWord(["renxuan", "si"]),
          class: ["class16"],
        },
        {
          title: getWord(["renxuan", "wu"]),
          class: ["class17"],
        },
      ],
      TEMPLATE3_gdkltenTypeRule,
      gdkltenTypeRule,
      gdkltenClassRule,
      TEMPLATE3_gdkltenClassRule,
      gdkltenNumberList,
      jndxyTypeTitle,
      jndxyTypeRule,
      jndxyClassRule,

      ttcTypeRule,
      TEMPLATE3_ttcTypeRule,
      ttcTypeTitle: [
        {
          title: getWord(["item_tema", "yi"]),
          class: ["class1"],
        },
        {
          title: getWord(["item_tema", "er"]),
          class: ["class1"],
        },
        {
          title: getWord("lianma"),
          class: ["class2"],
        },
      ],
      TEMPLATE3_ttcTypeTitle: [
        {
          title: getWord(["item_tema", "yi"]),
          class: ["class1"],
        },
        {
          title: getWord(["item_tema", "er"]),
          class: ["class2"],
        },
        {
          title: getWord("lianma_wuzhong_special"),
          class: ["class3"],
        },
        {
          title: getWord("lianmi_liuzhong_special"),
          class: ["class4"],
        },
        {
          title: getWord("lianmi_qizhong_special"),
          class: ["class5"],
        },
        {
          title: getWord("lianma_bazhong_special"),
          class: ["class6"],
        },
      ],
      ttcNumberList,
      tiantiancaiClassRule: tiantiancaiClassRule(),
      TEMPLATE3_tiantiancaiClassRule,
      TEMPLATE3_tiantiancaiColorRule,

      gdfiveselectZhixuanTitle: {
        2: [getWord("first_ball"), getWord("second_ball")],
        3: [
          getWord("first_ball"),
          getWord("second_ball"),
          getWord("third_ball"),
        ],
      },

      fc3dErzidingweiTitle: {
        100: [getWord(["bai2", "place"]), getWord(["shi2", "place"])],
        10: [getWord(["bai2", "place"]), getWord(["ge", "place"])],
        1: [getWord(["shi2", "place"]), getWord(["ge", "place"])],
      },

      fc3dNumber: [
        "ONEMERGER",
        "YIZIBAIWEI",
        "YIZISHIWEI",
        "YIZIGEWEI",
        "TWOMERGER_01-09",
        "TWOMERGER_12-19",
        "TWOMERGER_23-29",
        "TWOMERGER_34-39",
        "TWOMERGER_45-49",
        "TWOMERGER_56-59",
        "TWOMERGER_67-69",
        "TWOMERGER_78-79",
      ],

      ttcNumber: [
        "LIANMA_5_5",
        "LIANMA_5_TE",
        "LIANMA_6_6",
        "LIANMA_6_TE",
        "LIANMA_7_7",
        "LIANMA_7_TE",
        "LIANMA_8_8",
        "LIANMA_8_TE",
      ],

      dtClassicNumber: ["ONE", "TWO", "THREE", "FOUR", "FIVE"],

      gdfiveselectNumber: ["ONE", "TWO", "THREE", "FOUR", "FIVE"],

      pk10Number: [
        "ONE",
        "TWO",
        "THREE",
        "FOUR",
        "FIVE",
        "SIX",
        "SEVEN",
        "EIGHT",
        "NINE",
        "TEN",
      ],

      coins: [],
      coinsType: ["1", "5", "10", "50", "100", "500", "1000", "10000"],
      coinsInit: ["1", "5", "10"],
      originalUserCoinsInit: null,

      createType,

      gdfiveselectData: {
        combinationOdds: "",
        class3: {
          current: "",
          currentId: null,
          actions: [
            {
              name: getWord(["lianma", "yi", "zhong", "yi"]),
              id: "LIANMAONE",
              method: function () {
                return that.class3Switch("LIANMAONE", 1);
              },
            },
            {
              name: getWord(["lianma", "er", "zhong", "er"]),
              id: "LIANMATWO",
              method: function () {
                return that.class3Switch("LIANMATWO", 2);
              },
            },
            {
              name: getWord(["lianma", "san", "zhong", "san"]),
              id: "LIANMATHREE",
              method: function () {
                return that.class3Switch("LIANMATHREE", 3);
              },
            },
            {
              name: getWord(["lianma", "si", "zhong", "si"]),
              id: "LIANMAFOUR",
              method: function () {
                return that.class3Switch("LIANMAFOUR", 4);
              },
            },
            {
              name: getWord(["lianma", "wu", "zhong", "wu"]),
              id: "LIANMAFIVE",
              method: function () {
                return that.class3Switch("LIANMAFIVE", 5);
              },
            },
            {
              name: getWord(["lianma", "liu", "zhong", "wu"]),
              id: "LIANMASIX",
              method: function () {
                return that.class3Switch("LIANMASIX", 5);
              },
            },
            {
              name: getWord(["lianma", "qi", "zhong", "wu"]),
              id: "LIANMASEVEN",
              method: function () {
                return that.class3Switch("LIANMASEVEN", 5);
              },
            },
            {
              name: getWord(["lianma", "ba", "zhong", "wu"]),
              id: "LIANMAEIGHT",
              method: function () {
                return that.class3Switch("LIANMAEIGHT", 5);
              },
            },
            {
              name: getWord(["qianer", "zuxuan"]),
              id: "ZUER",
              method: function () {
                return that.class3Switch("ZUER", 2);
              },
            },
            {
              name: getWord(["qiansan", "zuxuan"]),
              id: "ZUSAN",
              method: function () {
                return that.class3Switch("ZUSAN", 3);
              },
            },
          ],
        },
        class4: {
          current: "",
          actions: [
            {
              name: getWord(["qianer", "zhixuan"]),
              id: "QIANER",
              method: function () {
                return that.class4Switch("QIANER", 2);
              },
            },
            {
              name: getWord(["qiansan", "zhixuan"]),
              id: "QIANSAN",
              method: function () {
                return that.class4Switch("QIANSAN", 3);
              },
            },
          ],
          currentTypeTab: "",
        },
        currentTypeTab: "",
        class3Visible: false,
        class4Visible: false,
      },

      fc3dData: {
        combinationOdds: "",
        class5: {
          current: "",
          actions: [
            {
              name: getWord([
                "er",
                "zi",
                "positioning",
                "bai2",
                "shi2",
                "place",
              ]),
              id: "ERZIBAISHIWEI",
              method: function () {
                return that.fc3dClass5Switch("ERZIBAISHIWEI", 100);
              },
            },
            {
              name: getWord(["er", "zi", "positioning", "bai2", "ge", "place"]),
              id: "ERZIBAIGEWEI",
              method: function () {
                return that.fc3dClass5Switch("ERZIBAIGEWEI", 10);
              },
            },
            {
              name: getWord(["er", "zi", "positioning", "shi2", "ge", "place"]),
              id: "ERZISHIGEWEI",
              method: function () {
                return that.fc3dClass5Switch("ERZISHIGEWEI", 1);
              },
            },
          ],
          currentTypeTab: "",
        },
        class7: {
          current: "",
          actions: [
            {
              name: "组选三-五连中",
              id: "ZUXUANSAN_5",
              method: function () {
                return that.fc3dClass7Switch("ZUXUANSAN_5", 5);
              },
            },
            {
              name: "组选三-六连中",
              id: "ZUXUANSAN_6",
              method: function () {
                return that.fc3dClass7Switch("ZUXUANSAN_6", 6);
              },
            },
            {
              name: "组选三-七连中",
              id: "ZUXUANSAN_7",
              method: function () {
                return that.fc3dClass7Switch("ZUXUANSAN_7", 7);
              },
            },
            {
              name: "组选三-八连中",
              id: "ZUXUANSAN_8",
              method: function () {
                return that.fc3dClass7Switch("ZUXUANSAN_8", 8);
              },
            },
            {
              name: "组选三-九连中",
              id: "ZUXUANSAN_9",
              method: function () {
                return that.fc3dClass7Switch("ZUXUANSAN_9", 9);
              },
            },
            {
              name: "组选三-十连中",
              id: "ZUXUANSAN_10",
              method: function () {
                return that.fc3dClass7Switch("ZUXUANSAN_10", 10);
              },
            },
          ],
          defaultHint: getWord("select_numbers", {
            $1: "5",
            $2: "10",
          }),
        },
        class8: {
          current: "",
          actions: [
            {
              name: "组选六-四连中",
              id: "ZUXUANLIU_4",
              method: function () {
                return that.fc3dClass8Switch("ZUXUANLIU_4", 4);
              },
            },
            {
              name: "组选六-五连中",
              id: "ZUXUANLIU_5",
              method: function () {
                return that.fc3dClass8Switch("ZUXUANLIU_5", 5);
              },
            },
            {
              name: "组选六-六连中",
              id: "ZUXUANLIU_6",
              method: function () {
                return that.fc3dClass8Switch("ZUXUANLIU_6", 6);
              },
            },
            {
              name: "组选六-七连中",
              id: "ZUXUANLIU_7",
              method: function () {
                return that.fc3dClass8Switch("ZUXUANLIU_7", 7);
              },
            },
            {
              name: "组选六-八连中",
              id: "ZUXUANLIU_8",
              method: function () {
                return that.fc3dClass8Switch("ZUXUANLIU_8", 8);
              },
            },
          ],
          defaultHint: getWord("select_numbers", {
            $1: "4",
            $2: "8",
          }),
        },
        currentTypeTab: "",
        class5Visible: false,
        class7Visible: false,
        class8Visible: false,
      },

      lhcData: {
        specialItemSelected: false,
        specialItemOdds: "",
        combinationOdds: "",
        actions: [
          {
            id: "1",
            name: getWord("zhong"),
            method: function () {
              return that.actionsSwitch(getWord("zhong"));
            },
          },
          {
            id: "2",
            name: getWord("buzhong"),
            method: function () {
              return that.actionsSwitch(getWord("buzhong"));
            },
          },
        ],
        class17: {
          current: "",
          actions: [
            {
              name: getWord(["wu", "buzhong"]),
              method: function () {
                return that.class17Switch(getWord(["wu", "buzhong"]), 5);
              },
            },
            {
              name: getWord(["liu", "buzhong"]),
              method: function () {
                return that.class17Switch(getWord(["liu", "buzhong"]), 6);
              },
            },
            {
              name: getWord(["qi", "buzhong"]),
              method: function () {
                return that.class17Switch(getWord(["qi", "buzhong"]), 7);
              },
            },
            {
              name: getWord(["ba", "buzhong"]),
              method: function () {
                return that.class17Switch(getWord(["ba", "buzhong"]), 8);
              },
            },
            {
              name: getWord(["jiu", "buzhong"]),
              method: function () {
                return that.class17Switch(getWord(["jiu", "buzhong"]), 9);
              },
            },
            {
              name: getWord(["shi", "buzhong"]),
              method: function () {
                return that.class17Switch(getWord(["shi", "buzhong"]), 10);
              },
            },
            {
              name: getWord(["eleven", "buzhong"]),
              method: function () {
                return that.class17Switch(getWord(["eleven", "buzhong"]), 11);
              },
            },
            {
              name: getWord(["twelve", "buzhong"]),
              method: function () {
                return that.class17Switch(getWord(["twelve", "buzhong"]), 12);
              },
            },
          ],
        },
        zhong: "中",
        maxLimit: false,
        actionsVisible: false,
        class17Visible: false,
        currentTypeTab: "",
        typeTab: {
          class13: [
            {
              title: getWord(["er", "xiao", "lian"]),
              id: "2",
              active: false,
            },
            {
              title: getWord(["san", "xiao", "lian"]),
              id: "3",
              active: false,
            },
            {
              title: getWord(["si", "xiao", "lian"]),
              id: "4",
              active: false,
            },
            {
              title: getWord(["wu", "xiao", "lian"]),
              id: "5",
              active: false,
            },
          ],
          class15: [
            {
              title: getWord(["er", "wei", "lian"]),
              id: "2",
              active: false,
            },
            {
              title: getWord(["san", "wei", "lian"]),
              id: "3",
              active: false,
            },
            {
              title: getWord(["si", "wei", "lian"]),
              id: "4",
              active: false,
            },
          ],
        },
      },

      ttcData: {
        combinationOdds: "",
        class1: {
          current: "",
          currentId: "",
          actions: [
            {
              name: getWord(["item_tema", "yi"]),
              id: "0",
              method: function () {
                return that.ttcClass1Switch("0");
              },
            },
            {
              name: getWord(["item_tema", "er"]),
              id: "1",
              method: function () {
                return that.ttcClass1Switch("1");
              },
            },
          ],
        },
        class2: {
          current: "",
          actions: [
            {
              name: getWord(["wu", "zhong", "te"]),
              id: "LIANMA_5_TE",
              method: function () {
                return that.ttcClass2Switch("LIANMA_5_TE", 5);
              },
            },
            {
              name: getWord(["liu", "zhong", "te"]),
              id: "LIANMA_6_TE",
              method: function () {
                return that.ttcClass2Switch("LIANMA_6_TE", 5);
              },
            },
            {
              name: getWord(["qi", "zhong", "te"]),
              id: "LIANMA_7_TE",
              method: function () {
                return that.ttcClass2Switch("LIANMA_7_TE", 5);
              },
            },
            {
              name: getWord(["ba", "zhong", "te"]),
              id: "LIANMA_8_TE",
              method: function () {
                return that.ttcClass2Switch("LIANMA_8_TE", 5);
              },
            },
          ],
          currentTypeTab: "",
        },
      },

      gdkltenData: {
        combinationOdds: "",
        class12: {
          current: "",
          actions: [
            {
              name: getWord(["renxuan", "er"]),
              id: "LIAN_REN_2",
              method: function () {
                return that.gdkltenClass12Switch("LIAN_REN_2", 2);
              },
            },
            {
              name: getWord("xuanerlianzu"),
              id: "LIAN_REN_ZU_2",
              method: function () {
                return that.gdkltenClass12Switch("LIAN_REN_ZU_2", 2);
              },
            },
            {
              name: getWord(["renxuan", "san"]),
              id: "LIAN_REN_3",
              method: function () {
                return that.gdkltenClass12Switch("LIAN_REN_3", 3);
              },
            },
            {
              name: getWord("xuansanqianzu"),
              id: "LIAN_REN_ZU_3",
              method: function () {
                return that.gdkltenClass12Switch("LIAN_REN_ZU_3", 3);
              },
            },
            {
              name: getWord(["renxuan", "si"]),
              id: "LIAN_REN_4",
              method: function () {
                return that.gdkltenClass12Switch("LIAN_REN_4", 4);
              },
            },
            {
              name: getWord(["renxuan", "wu"]),
              id: "LIAN_REN_5",
              method: function () {
                return that.gdkltenClass12Switch("LIAN_REN_5", 5);
              },
            },
          ],
          currentTypeTab: "",
        },
        currentTypeTab: "",
        class12Visible: false,
      },

      cqkltenData: {
        combinationOdds: "",
        class4: {
          current: "",
          actions: [
            {
              name: getWord(["renxuan", "er"]),
              id: "LIAN_REN_2",
              method: function () {
                return that.cqkltenClass4Switch("LIAN_REN_2", 2);
              },
            },
            {
              name: getWord("xuanerlianzu"),
              id: "LIAN_REN_ZU_2",
              method: function () {
                return that.cqkltenClass4Switch("LIAN_REN_ZU_2", 2);
              },
            },
            {
              name: getWord(["renxuan", "san"]),
              id: "LIAN_REN_3",
              method: function () {
                return that.cqkltenClass4Switch("LIAN_REN_3", 3);
              },
            },
            {
              name: getWord("xuansanqianzu"),
              id: "LIAN_REN_ZU_3",
              method: function () {
                return that.cqkltenClass4Switch("LIAN_REN_ZU_3", 3);
              },
            },
            {
              name: getWord(["renxuan", "si"]),
              id: "LIAN_REN_4",
              method: function () {
                return that.cqkltenClass4Switch("LIAN_REN_4", 4);
              },
            },
            {
              name: getWord(["renxuan", "wu"]),
              id: "LIAN_REN_5",
              method: function () {
                return that.cqkltenClass4Switch("LIAN_REN_5", 5);
              },
            },
          ],
          currentTypeTab: "",
        },
        currentTypeTab: "",
        class4Visible: false,
      },

      roomTypes: [
        "lhc",
        "hljssc",
        "tjssc",
        "sscone",
        "sscthree",
        "sscfive",
        "scone",
        "scthree",
        "scfive",
        "fastthreeone",
        "fastthreethree",
        "fastthreefive",
        "fastthreeten",
        "lhcone",
        "lhcthree",
        "lhcfive",
        "lhcten",
        "lhcaomen",
        "pc28one",
        "tencent",
        "shfastthree",
        "jsfastthree",
        "hbfastthree",
        "gdfiveselect",
        "jsfiveselect",
        "sdfiveselect",
        "jxfiveselect",
        "shhfiveselect",
        "anhfiveselect",
        "shxfiveselect",
        "fc3d",
        "gxklsf",
        "gdklten",
        "jndxy",
        "azxy5",
        "azxy10",
        "cqklten",
        "wanneng",
        "damacai",
        "duoduo",
        "yuedawan",
        "shaba88",
        "saimahui",
        "xinjapowz",
        "tiantiancai",
      ],

      /* template-2 begin */
      TEMPLATE2coinsInit: ["1", "5", "10", "50", "100", "500", "1000", "10000"],
      TEMPLATE1coinsInit: ["50", "500", "1000"],
      roomTypesAdded: ["cqssc", "bjsc", "xyft"],
      /* template-2 end */

      confirmOddsSelectAry: null,
      odds_token: null,

      wannengNum1: null,
      wannengNum2: null,
      wannengNum3: null,
      wannengNum4: null,
      wannengKey1: {
        value: null,
        alias: "BIG",
      },
      wannengKey2: {
        value: null,
        alias: "SMALL",
      },
      wannengKey3: {
        value: null,
        alias: "SA",
      },
      wannengKey4: {
        value: null,
        alias: "3A",
      },
      wannengKey5: {
        value: null,
        alias: "3C",
      },
      currentWannengOdds: null,
    };
  },
  components: {
    "mt-actionsheet": app.Mint.Actionsheet,
  },
  inject: [
    "toggleAuctioneerUI",
    "addMessage",
    "updateBalance",
    "scrollToFloorEnd",
    "game",
    "user",
    "textUiSwitcher",
    "textUiSwitcherData",
  ],
  props: [
    "gameType",
    "gameRoom",
    "currentDrawing",
    "avatar",
    "titleMenuPayload",
    "headerMenuDockerOpen",
    "toggleHeaderMenuDocker",
  ],
  methods: {
    bindTypeTab(item, _class) {
      var _type =
        this.game.info.game_type.indexOf("lhc") === 0
          ? "lhc"
          : this.game.info.game_type;
      var actived = this[_type + "Data"].typeTab[_class].filter((item) => {
        return item.active === true;
      });
      if (actived.length !== 0) {
        actived[0].active = false;
      }
      item.active = !item.active;

      this[_type + "Data"].currentTypeTab = item.id;
      this.choose_num({ select: false });
    },
    bindZodiacSeries(item) {
      this.lhcAnimalRule
        .reduce((acc, value) => acc.concat(value), [])
        .filter((data) => {
          return data.select === true;
        })
        .forEach((item) => {
          item.select = false;
        });

      this.lhcZodiacSeries().forEach((item) => {
        item.select = false;
      });

      item.relatedIndex.forEach((item) => {
        this.lhcAnimalRule[item - 1].select = true;
      });

      item.select = !item.select;
      this.choose_num({ select: false });
    },
    class17Switch(value, permutation) {
      this.lhcData.class17.current = value;
      this.lhcData.currentTypeTab = permutation;
      this.choose_num({ select: false });
    },
    class3Switch(value, permutation) {
      this.reset(false);
      this.gdfiveselectData.class3.current = value;
      this.gdfiveselectData.class3.currentId = value;
      this.gdfiveselectData.currentTypeTab = permutation;
      this.choose_num({ select: false });
    },
    class4Switch(value, permutation) {
      this.reset(false);
      this.gdfiveselectData.class4.current = value;
      this.gdfiveselectData.class4.currentId = value;
      this.gdfiveselectData.class4.currentTypeTab = permutation;
      this.choose_num({ select: false });
    },
    fc3dClass5Switch(value, permutation) {
      if (this._TEMPLATE !== "template-3") {
        this.reset(false);
      }

      this.fc3dData.class5.current = value;
      this.fc3dData.class5.currentId = value;
      this.fc3dData.currentTypeTab = permutation;
      this.fc3dData.class5.currentTypeTab = permutation;
      this.choose_num({ select: false });
    },
    fc3dClass7Switch(value, permutation) {
      // this.reset(false)
      this.fc3dData.class7.current = value;
      this.gdfiveselectData.class3.currentId = value;
      this.fc3dData.currentTypeTab = permutation;
      this.choose_num({ select: false });
    },
    fc3dClass8Switch(value, permutation) {
      // this.reset(false)
      this.fc3dData.class8.current = value;
      this.fc3dData.currentTypeTab = permutation;
      this.choose_num({ select: false });
    },
    gdkltenClass12Switch(value, permutation) {
      this.reset(false);
      this.gdkltenData.class12.current = value;
      this.gdkltenData.class12.currentId = value;
      this.gdkltenData.currentTypeTab = permutation;
      this.choose_num({ select: false });
    },
    ttcClass1Switch(value) {
      this.reset(false);
      this.ttcData.class1.current = value;
      this.ttcData.class1.currentId = value;
    },
    ttcClass2Switch(value, permutation) {
      this.reset(false);
      this.ttcData.class2.current = value;
      this.ttcData.class2.currentId = value;
      this.ttcData.currentTypeTab = permutation;
      this.choose_num({ select: false });
    },
    cqkltenClass4Switch(value, permutation) {
      this.reset(false);
      this.cqkltenData.class4.current = value;
      this.cqkltenData.class4.currentId = value;
      this.cqkltenData.currentTypeTab = permutation;
      this.choose_num({ select: false });
    },
    actionsSwitch(value) {
      this.lhcData.zhong = value;
      this.choose_num({ select: false });
    },
    order() {
      var that = this;
      var length = this.type
        .reduce((acc, value) => acc.concat(value), [])
        .filter((data) => {
          return data.select === true;
        }).length;
      if (this.game.info.game_type === "gdfiveselect") {
        if (this.game.info.currentAuctioneerUIClass.classTypeId === "4") {
          if (this.selectAry[0].id === "QIANER") {
            var first = this.gdfiveselectZhixuanNumberList
              .slice(0, 11)
              .filter((item) => {
                return item.select === true;
              })[0]
              ? true
              : false;
            var second = this.gdfiveselectZhixuanNumberList
              .slice(11, 22)
              .filter((item) => {
                return item.select === true;
              })[0]
              ? true
              : false;
            if (!first) {
              app.Mint.Toast(getWord("qianer_tip1"));
              return false;
            }
            if (!second) {
              app.Mint.Toast(getWord("qianer_tip2"));
              return false;
            }
          }
          if (this.selectAry[0].id === "QIANSAN") {
            var first = this.gdfiveselectZhixuanNumberList
              .slice(22, 33)
              .filter((item) => {
                return item.select === true;
              })[0]
              ? true
              : false;
            var second = this.gdfiveselectZhixuanNumberList
              .slice(33, 44)
              .filter((item) => {
                return item.select === true;
              })[0]
              ? true
              : false;
            var third = this.gdfiveselectZhixuanNumberList
              .slice(44, 55)
              .filter((item) => {
                return item.select === true;
              })[0]
              ? true
              : false;
            if (!first) {
              app.Mint.Toast(getWord("qiansan_tip1"));
              return false;
            }
            if (!second) {
              app.Mint.Toast(getWord("qiansan_tip2"));
              return false;
            }
            if (!third) {
              app.Mint.Toast(getWord("qiansan_tip3"));
              return false;
            }
          }
        }
      }
      if (length == 0) {
        app.Mint.Toast(getWord("betting_tips8"));
        return false;
      } else if (this.amount == 0) {
        app.Mint.Toast(getWord("betting_tips9"));
        return false;
      } else if (this.totalAmount == 0 && length > 0) {
        app.Mint.Toast(getWord("betting_tips8"));
        return false;
      } else if (this.totalAmount > this.$parent.account_balance) {
        app.Mint.Toast(getWord("betting_tips10"));
        return false;
      } else {
        // confirmOdds(
        //     this.gameType,
        //     this.currentDrawing,
        //     this.gameRoom,
        //     this.createBetList(true),
        // ).then(function(result){
        //     if (result.data.code === 'SUCCESS') {
        //         var data=result.data.result.odds
        //         that.odds_token=result.data.result.userOddsToken;
        //         that.confirmOddsSelectAry=that.selectAry.slice(0);
        //         data.forEach(item=>{
        //             that.confirmOddsSelectAry.filter(_item=>{
        //                 return _item.id===item.keyword
        //             })[0].odds=item.odds
        //         })

        //         that.toggleAside('orderComfirm')
        //         that.$nextTick(function() {
        //             if (that.$refs.scrolledBlock) {
        //                 that.CustomScroll(that.$refs.scrolledBlock.id);
        //             }
        //         });
        //     }
        // })
        this.toggleAside("orderComfirm");
        this.$nextTick(function () {
          if (this.$refs.scrolledBlock) {
            this.CustomScroll(this.$refs.scrolledBlock.id);
          }
        });
      }
    },
    order2() {
      var that = this;
      if (!this.calcLength()) {
        return false;
      }
      if (this.gameType === "wanneng") {
        var num1 = !this.wannengNum1 ? 1 : 0;
        var num2 = !this.wannengNum2 ? 1 : 0;
        var num3 = !this.wannengNum3 ? 1 : 0;
        var num4 = !this.wannengNum4 ? 1 : 0;
        var class2Number = num1 + num2 + num3 + num4;
        if (
          this.game.info.currentAuctioneerUIClass.id === "2" &&
          class2Number !== 1
        ) {
          Toast(getWord("wzwn_tip12"));
          return false;
        }
        if (
          this.game.info.currentAuctioneerUIClass.id !== "2" &&
          class2Number > 0
        ) {
          Toast(getWord("wzwn_tips6"));
          return false;
        }
      }
      this.toggleAside("orderComfirm");
      this.$nextTick(function () {
        if (this.$refs.scrolledBlock) {
          this.CustomScroll(this.$refs.scrolledBlock.id);
        }
      });
    },
    reset(toast) {
      if (toast) {
        let instance = app.Mint.Toast(getWord(["clear", "success"]));
        setTimeout(() => {
          instance.close();
        }, 2000);
      }

      this.countOfCombinationBet = 0;

      //reset coins
      this.amount = 0;
      var _activedCoin = this.coins.filter((item) => {
        return item.active === true;
      });
      if (_activedCoin.length > 0) {
        _activedCoin[0].active = false;
      }

      [
        this.type,
        this.lhcWeiLianRule,
        this.lhcAnimalRule,
        this.lhcNumberList,
        this.lhcZodiacSeries(),
        this.gdfiveselectNumberList,
        this.gdfiveselectZhixuanNumberList,
        this.fc3dNumberList,
        this.fc3dErzidingweiNumberList,
        this.gdkltenNumberList,
        this.ttcNumberList,
      ].forEach((item) => {
        item
          .reduce((acc, value) => acc.concat(value), [])
          .filter((data) => {
            if (data.select === true || data.disabled === true) {
              return true;
            }
          })
          .forEach((item) => {
            item.select = false;
            item.disabled = false;
          });
      });

      this.wannengNum1 = null;
      this.wannengNum2 = null;
      this.wannengNum3 = null;
      this.wannengNum4 = null;
      this.wannengKey1.value = null;
      this.wannengKey2.value = null;
      this.wannengKey3.value = null;
      this.wannengKey4.value = null;
      this.wannengKey5.value = null;

      this.fc3dData.combinationOdds = "";
      this.fc3dData.currentTypeTab = "";
      this.fc3dData.class5.current = "";
      this.fc3dData.class7.current = "";
      this.fc3dData.class8.current = this.fc3dData.class8.defaultHint;

      this.gdfiveselectData.combinationOdds = "";
      this.gdfiveselectData.currentTypeTab = "";
      this.gdfiveselectData.class3.current = "";
      this.gdfiveselectData.class4.current = "";

      this.gdkltenData.combinationOdds = "";
      this.gdkltenData.currentTypeTab = "";
      this.gdkltenData.class12.current = "";

      this.ttcData.combinationOdds = "";
      this.ttcData.currentTypeTab = "";
      this.ttcData.class2.current = "";

      this.lhcData.combinationOdds = "";
      this.lhcData.specialItemOdds = "";
      this.lhcData.currentTypeTab = "";
      this.lhcData.class17.current = "";
      this.lhcData.zhong = "1";
      this.lhcData.maxLimit = false;
      this.lhcData.specialItemSelected = false;
      Object.values(this.lhcData.typeTab).forEach((item) => {
        item.forEach((item) => {
          item.active = false;
        });
      });

      if (
        _TEMPLATE === "template-3" &&
        this.game.info.game_type.indexOf("lhc") === 0
      ) {
        this.setDefault(this.game.info.currentAuctioneerUIClass);
      }

      document.querySelectorAll('li[title^="zhixuan-"]').forEach((item) => {
        item.className = item.className.replace("highlight", "");
        item.className = item.className.replace("disabled", "");
      });

      // if (this._TEMPLATE==='template-3') {
      //     this.setDefault(this.game.info.currentAuctioneerUIClass)
      // }
    },
    choosing_chips(item) {
      var choosedLength = this.coins.filter((data) => {
        return data.select === true;
      }).length;

      if (choosedLength === 3 && item && item.select === false) {
        app.Mint.Toast(getWord("bargaining_chip_rules3",{ '$1':'3'}));
      } else if (item) {
        item.select = !item.select;
      }
    },
    close_show() {
      // debugger
      if (this._TEMPLATE === "template-1") {
        this.toggleAuctioneerUI(true);
        this.TEMPLATE1_toggleAuctioneerUI(false);
      }
      if (this._TEMPLATE === "template-2") {
        this.toggleAuctioneerUI(true);
        this.TEMPLATE2_toggleAuctioneerUI(false);
      }
      if (this._TEMPLATE === "template-3") {
        this.textUiSwitcher(this.textUiSwitcherData[1]);
      }
    },
    switch_show(index) {
      app.Mint.Toast(getWord("coming_soon"));
    },
    deleteWannengEntry(index) {
      this["wannengKey" + index].value = null;
    },
    deleteEntry(entry) {
      var that = this;
      this.type
        .reduce((acc, value) => acc.concat(value), [])
        .forEach(function (item) {
          if (item.originalTitle) {
            if (item.originalTitle === entry.originalTitle) {
              item.select = false;
            }
          } else {
            if (item.id === entry.id) {
              item.select = false;
            }
          }
        });

      function clearSelectType(type) {
        type
          .reduce((acc, value) => acc.concat(value), [])
          .filter((data) => {
            return data.select === true;
          })
          .forEach((item) => {
            item.select = false;
          });

        that.fc3dData.combinationOdds = "";
        that.fc3dData.currentTypeTab = "";
        that.fc3dData.class5.current = "";
        that.fc3dData.class7.current = "";
        that.fc3dData.class8.current = that.fc3dData.class8.defaultHint;

        that.gdfiveselectData.combinationOdds = "";
        that.gdfiveselectData.class3.current = "";
        that.gdfiveselectData.currentTypeTab = "";

        that.gdkltenData.combinationOdds = "";
        that.gdkltenData.class12.current = "";
        that.gdkltenData.currentTypeTab = "";

        that.lhcData.combinationOdds = "";
        that.lhcData.specialItemOdds = "";
        that.lhcData.specialItemSelected = false;
        that.lhcData.class17.current = "";
        that.lhcData.currentTypeTab = "";

        Object.values(that.lhcData.typeTab)
          .reduce((acc, value) => acc.concat(value), [])
          .forEach((item) => {
            item.active = false;
          });
      }
      function clearOthers() {}
      var beChecked = null;
      // if (this._TEMPLATE==='template-1'||this._TEMPLATE==='template-2') {
      //     beChecked=this.game.info.currentAuctioneerUIClass.classId
      // }
      // if (this._TEMPLATE==='template-3') {
      beChecked = this.game.info.currentAuctioneerUIClass.classTypeId;
      // }

      if (
        this.game.info.game_type === "gdklten" ||
        this.game.info.game_type === "cqklten"
      ) {
        switch (beChecked) {
          case "5":
            clearSelectType(this.gdkltenNumberList);
            break;
          default:
            break;
        }
      }
      if (this.game.info.game_type.indexOf("fiveselect") > -1) {
        switch (beChecked) {
          case "3":
            clearSelectType(this.gdfiveselectNumberList);
            break;
          default:
            break;
        }
      }
      if (this.game.info.game_type === "fc3d") {
        switch (this.game.info.currentAuctioneerUIClass.classTypeId) {
          case "5":
            clearSelectType(this.fc3dErzidingweiNumberList);
            break;
          case "8":
          case "9":
            clearSelectType(this.fc3dErzidingweiNumberList);
            break;
          default:
            break;
        }
      }
      if (this.game.info.game_type.indexOf("lhc") === 0) {
        switch (this.game.info.currentAuctioneerUIClass.id) {
          case "10":
          case "12":
            clearSelectType(this.lhcNumberList);
            break;
          case "7":
          case "8":
            clearSelectType(this.lhcAnimalRule);
            break;
          case "11":
            clearSelectType(this.lhcWeiLianRule);
          default:
            break;
        }
      }

      this.choose_num({ select: false });

      if (this.selectAry.length === 0) {
        this.toggleAside("orderComfirm", function () {
          if (that._TEMPLATE === "template-3") {
            that.setDefault(that.game.info.currentAuctioneerUIClass);
          }
        });
      }
    },
    choose_num(item, event) {
      if (this._TEMPLATE === "template-1" || this._TEMPLATE === "template-2") {
        if (
          event &&
          event.target.parentNode.className &&
          event.target.parentNode.className.indexOf("disabled") > -1
        ) {
          return false;
        } else if (
          event &&
          event.target.parentNode.className &&
          event.target.parentNode.className.indexOf("highlight") > -1
        ) {
          var highlightItem = true;
        } else {
          var highlightItem = false;
        }
      }
      if (this._TEMPLATE === "template-3") {
        if (
          event &&
          event.currentTarget &&
          event.currentTarget.className.indexOf("disabled") > -1
        ) {
          return false;
        }
      }
      var that = this;
      var current =
        that.game.info.currentAuctioneerUIClass &&
        that.game.info.currentAuctioneerUIClass.class;
      var currentId =
        that.game.info.currentAuctioneerUIClass &&
        that.game.info.currentAuctioneerUIClass.id;
      var currentClassType =
        that.game.info.currentAuctioneerUIClass &&
        that.game.info.currentAuctioneerUIClass.classType;
      var currentClassTypeId =
        that.game.info.currentAuctioneerUIClass &&
        that.game.info.currentAuctioneerUIClass.classTypeId;
      if (
        (this.game.info.game_type.indexOf("lhc") === 0 ||
          this.game.info.game_type.indexOf("fiveselect") > -1 ||
          this.game.info.game_type === "fc3d" ||
          this.game.info.game_type.indexOf("klten") > -1) &&
        (currentClassTypeId === "10" || currentClassTypeId === "5") &&
        item.permutation
      ) {
        this.type
          .reduce((acc, value) => acc.concat(value), [])
          .filter((data) => {
            return data.select === true;
          })
          .forEach((item) => {
            item.select = false;
          });
        item.select = !item.select;
        this.lhcData.currentTypeTab = item.permutation;
        this.gdfiveselectData.currentTypeTab = item.permutation;
        this.fc3dData.currentTypeTab = item.permutation;
        this.gdkltenData.currentTypeTab = item.permutation;
        this.cqkltenData.currentTypeTab = item.permutation;
      } else {
        if (this.lhcData.maxLimit === false) {
          item.select = !item.select;
        } else if (item.select === true) {
          item.select = !item.select;
        }
      }

      this.$nextTick(function () {
        if (
          this.game.info.game_type.indexOf("fiveselect") > -1 &&
          currentClassTypeId === "4" &&
          event !== undefined
        ) {
          if (
            this._TEMPLATE === "template-1" ||
            this._TEMPLATE === "template-2"
          ) {
            var related = document.querySelectorAll(
              'li[title="' +
                event.target.parentNode.title +
                '"]:not(.highlight)'
            );

            related.forEach((item) => {
              if (
                item.className.indexOf("disabled") > -1 ||
                highlightItem === true
              ) {
                item.className = item.className.replace(" disabled", "");
              } else if (item.className.indexOf("number") > -1) {
                item.className += " disabled";
              } else if (item.className === "") {
                item.className += "disabled";
              }
            });
          }
          if (this._TEMPLATE === "template-3") {
            var arr1 = gdfiveselectZhixuanNumberList.slice(0, 22);
            var arr2 = gdfiveselectZhixuanNumberList.slice(22, 55);
            if (currentId === "class13") {
              disabled(arr1);
            }
            if (currentId === "class14") {
              disabled(arr2);
            }
            function disabled(data) {
              data.forEach((item_1) => {
                if (item_1.select) {
                  // console.log('selected:',item_1)
                  data
                    .filter((item_2) => {
                      return item_2.title === item_1.title;
                    })
                    .forEach((item_3) => {
                      // console.log('unselected:',item_3)
                      if (item_3.index !== item_1.index) {
                        item_3.disabled = true;
                      }
                    });
                }
                if (item_1.title === item.title && item.select === false) {
                  item_1.disabled = false;
                }
              });
            }
            that.gdfiveselectZhixuanNumberList = [];
            that.gdfiveselectZhixuanNumberList = arr1.concat(arr2);
          }
        }
      });

      //计价
      var countOfType = this.type
        .reduce((acc, value) => acc.concat(value), [])
        .filter((data) => {
          return data.select === true;
        }).length;

      if (this.game.info.game_type.indexOf("tiantiancai") === 0) {
        var combinationRule =
          currentClassTypeId === "2" ? that.ttcNumberList : [];

        var countOfSpecialCode = combinationRule
          .reduce((acc, value) => acc.concat(value), [])
          .filter((data) => {
            return data.select === true;
          }).length;

        if (countOfSpecialCode >= 10 && currentClassTypeId === "2") {
          this.lhcData.maxLimit = true;
          if (Object.values(item).length > 1 && !item.permutation) {
            app.Mint.Toast(
              getWord("select_rule", {
                $1: "10",
              })
            );
          }
        } else {
          this.lhcData.maxLimit = false;
        }

        if (currentClassTypeId === "2") {
          this.combinationTypeRender(
            currentId,
            countOfSpecialCode,
            currentClassTypeId
          );

          var countOfType = this.type
            .reduce((acc, value) => acc.concat(value), [])
            .filter((data) => {
              return data.select === true;
            }).length;
        }
      }

      if (this.game.info.game_type.indexOf("lhc") === 0) {
        var combinationRule = [];
        if (currentClassTypeId === "8" || currentId === "class12") {
          combinationRule = that.lhcAnimalRule;
        }
        if (currentClassTypeId === "11") {
          combinationRule = that.lhcWeiLianRule;
        }
        if (currentClassTypeId === "10" || currentClassTypeId === "12") {
          combinationRule = that.lhcNumberList;
        }

        var countOfLhcSpecialCode = combinationRule
          .reduce((acc, value) => acc.concat(value), [])
          .filter((data) => {
            if (
              data.special === true &&
              data.select === true &&
              currentId !== "class12"
            ) {
              this.lhcData.specialItemSelected = true;
            } else {
              this.lhcData.specialItemSelected = false;
            }
            return data.select === true;
          }).length;

        if (
          countOfLhcSpecialCode >= 10 &&
          (currentClassTypeId === "10" || currentId === "class12")
        ) {
          this.lhcData.maxLimit = true;
          if (Object.values(item).length > 1 && !item.permutation) {
            app.Mint.Toast(
              getWord("select_rule", {
                $1: "10",
              })
            );
          }
        } else {
          this.lhcData.maxLimit = false;
        }

        if (
          countOfLhcSpecialCode >= 7 &&
          (currentClassTypeId === "11" || currentClassTypeId === "8")
        ) {
          this.lhcData.maxLimit = true;
          if (Object.values(item).length > 1 && !item.permutation) {
            app.Mint.Toast(
              getWord("select_rule", {
                $1: "7",
              })
            );
          }
        }

        if (
          countOfLhcSpecialCode >= that.lhcData.currentTypeTab + 2 &&
          currentClassTypeId === "12"
        ) {
          this.lhcData.maxLimit = true;
          if (Object.values(item).length > 1 && !item.permutation) {
            app.Mint.Toast(
              getWord("select_rule", {
                $1: that.lhcData.currentTypeTab + 2,
              })
            );
          }
        }

        if (
          currentId === "class12" ||
          currentClassTypeId === "8" ||
          currentClassTypeId === "11" ||
          currentClassTypeId === "12"
        ) {
          this.combinationTypeRender(
            currentId,
            countOfLhcSpecialCode,
            currentClassTypeId
          );

          var countOfType = this.type
            .reduce((acc, value) => acc.concat(value), [])
            .filter((data) => {
              return data.select === true;
            }).length;
        }
      }

      if (
        this.game.info.game_type === "cqklten" &&
        currentClassTypeId === "5"
      ) {
        var combinationRule =
          currentClassTypeId === "5" ? that.gdkltenNumberList : [];

        var countOfSpecialCode = combinationRule
          .reduce((acc, value) => acc.concat(value), [])
          .filter((data) => {
            return data.select === true;
          }).length;

        if (
          countOfSpecialCode >= 7 &&
          currentClassTypeId === "5" &&
          (that.cqkltenData.class4.currentId === "LIAN_REN_2" ||
            that.cqkltenData.class4.currentId === "LIAN_REN_3" ||
            that.cqkltenData.class4.currentId === "LIAN_REN_ZU_2" ||
            that.cqkltenData.class4.currentId === "LIAN_REN_ZU_3")
        ) {
          this.lhcData.maxLimit = true;
          if (Object.values(item).length > 1 && !item.permutation) {
            app.Mint.Toast(
              getWord("select_rule", {
                $1: "7",
              })
            );
          }
        } else {
          this.lhcData.maxLimit = false;
        }

        if (
          countOfSpecialCode >= 5 &&
          currentClassTypeId === "5" &&
          (that.cqkltenData.class4.currentId === "LIAN_REN_4" ||
            that.cqkltenData.class4.currentId === "LIAN_REN_5")
        ) {
          this.lhcData.maxLimit = true;
          if (Object.values(item).length > 1 && !item.permutation) {
            app.Mint.Toast(
              getWord("select_rule", {
                $1: "5",
              })
            );
          }
        }

        if (currentClassTypeId === "5") {
          this.combinationTypeRender(
            currentId,
            countOfSpecialCode,
            currentClassTypeId
          );

          var countOfType = this.type
            .reduce((acc, value) => acc.concat(value), [])
            .filter((data) => {
              return data.select === true;
            }).length;
        }
      }

      if (
        this.game.info.game_type === "gdklten" &&
        currentClassTypeId === "5"
      ) {
        var combinationRule =
          currentClassTypeId === "5" ? that.gdkltenNumberList : [];

        var countOfSpecialCode = combinationRule
          .reduce((acc, value) => acc.concat(value), [])
          .filter((data) => {
            return data.select === true;
          }).length;

        if (
          countOfSpecialCode >= 7 &&
          currentClassTypeId === "5" &&
          (that.gdkltenData.class12.currentId === "LIAN_REN_2" ||
            that.gdkltenData.class12.currentId === "LIAN_REN_3" ||
            that.gdkltenData.class12.currentId === "LIAN_REN_ZU_2" ||
            that.gdkltenData.class12.current === "LIAN_REN_ZU_3")
        ) {
          this.lhcData.maxLimit = true;
          if (Object.values(item).length > 1 && !item.permutation) {
            app.Mint.Toast(
              getWord("select_rule", {
                $1: "7",
              })
            );
          }
        } else {
          this.lhcData.maxLimit = false;
        }

        if (
          countOfSpecialCode >= 5 &&
          currentClassTypeId === "5" &&
          (that.gdkltenData.class12.currentId === "LIAN_REN_4" ||
            that.gdkltenData.class12.currentId === "LIAN_REN_5")
        ) {
          this.lhcData.maxLimit = true;
          if (Object.values(item).length > 1 && !item.permutation) {
            app.Mint.Toast(
              getWord("select_rule", {
                $1: "5",
              })
            );
          }
        }

        if (currentClassTypeId === "5") {
          this.combinationTypeRender(
            currentId,
            countOfSpecialCode,
            currentClassTypeId
          );

          var countOfType = this.type
            .reduce((acc, value) => acc.concat(value), [])
            .filter((data) => {
              return data.select === true;
            }).length;
        }
      }

      if (
        this.game.info.game_type.indexOf("fiveselect") > -1 &&
        (currentClassTypeId === "3" || currentClassTypeId === "4")
      ) {
        var combinationRule =
          currentClassTypeId === "3"
            ? that.gdfiveselectNumberList
            : currentClassTypeId === "4"
            ? that.gdfiveselectZhixuanNumberList
            : [];

        var countOfGdfiveselectSpecialCode = combinationRule
          .reduce((acc, value) => acc.concat(value), [])
          .filter((data) => {
            return data.select === true;
          }).length;

        if (
          countOfGdfiveselectSpecialCode >= 9 &&
          currentClassTypeId === "3" &&
          (that.gdfiveselectData.class3.currentId === "LIANMATWO" ||
            that.gdfiveselectData.class3.currentId === "LIANMATHREE" ||
            that.gdfiveselectData.class3.currentId === "LIANMASIX" ||
            that.gdfiveselectData.class3.currentId === "LIANMASEVEN" ||
            that.gdfiveselectData.class3.currentId === "LIANMAEIGHT")
        ) {
          this.lhcData.maxLimit = true;
          if (Object.values(item).length > 1 && !item.permutation) {
            app.Mint.Toast(
              getWord("select_rule", {
                $1: "9",
              })
            );
          }
        } else {
          this.lhcData.maxLimit = false;
        }

        if (
          countOfGdfiveselectSpecialCode >= 7 &&
          currentClassTypeId === "3" &&
          (that.gdfiveselectData.class3.currentId === "LIANMAFOUR" ||
            that.gdfiveselectData.class3.currentId === "LIANMAFIVE")
        ) {
          this.lhcData.maxLimit = true;
          if (Object.values(item).length > 1 && !item.permutation) {
            app.Mint.Toast(
              getWord("select_rule", {
                $1: "7",
              })
            );
          }
        }

        if (
          countOfGdfiveselectSpecialCode >= 5 &&
          currentClassTypeId === "3" &&
          (that.gdfiveselectData.class3.currentId === "ZUER" ||
            that.gdfiveselectData.class3.currentId === "ZUSAN")
        ) {
          this.lhcData.maxLimit = true;
          if (Object.values(item).length > 1 && !item.permutation) {
            app.Mint.Toast(
              getWord("select_rule", {
                $1: "5",
              })
            );
          }
        }

        if (currentClassTypeId === "3" || currentClassTypeId === "4") {
          this.combinationTypeRender(
            currentId,
            countOfGdfiveselectSpecialCode,
            currentClassTypeId
          );

          var countOfType = this.type
            .reduce((acc, value) => acc.concat(value), [])
            .filter((data) => {
              return data.select === true;
            }).length;
        }
      }

      if (
        this.game.info.game_type === "fc3d" &&
        (currentClassTypeId === "5" ||
          currentClassTypeId === "7" ||
          currentClassTypeId === "8")
      ) {
        var combinationRule =
          currentClassTypeId === "5"
            ? that.fc3dErzidingweiNumberList
            : (combinationRule =
                currentClassTypeId === "7" || currentClassTypeId === "8"
                  ? that.fc3dNumberList
                  : []);

        var countOfSpecialCode = combinationRule
          .reduce((acc, value) => acc.concat(value), [])
          .filter((data) => {
            return data.select === true;
          }).length;

        if (
          currentClassTypeId === "5" ||
          currentClassTypeId === "7" ||
          currentClassTypeId === "8"
        ) {
          this.combinationTypeRender(
            currentId,
            countOfSpecialCode,
            currentClassTypeId
          );

          var countOfType = this.type
            .reduce((acc, value) => acc.concat(value), [])
            .filter((data) => {
              return data.select === true;
            }).length;
        }
      }

      if (
        this.game.info.currentAuctioneerUIClass &&
        this.game.info.game_type.indexOf("lhc") === 0 &&
        currentClassTypeId !== "8" &&
        currentClassTypeId !== "11" &&
        currentClassTypeId !== "10" &&
        currentClassTypeId !== "12" &&
        this.game.info.game_type.indexOf("fiveselect") > -1 &&
        currentClassTypeId !== "4" &&
        this.game.info.game_type === "fc3d" &&
        currentClassTypeId !== "5" &&
        currentClassTypeId !== "7" &&
        currentClassTypeId !== "8"
      ) {
        this.totalAmount = countOfType * this.amount;
      } else {
        if (this.game.info.game_type.indexOf("fiveselect") > -1) {
          if (currentClassTypeId === "4") {
            var _arr = [];
            var datas = that.gdfiveselectZhixuanNumberList
              .reduce((acc, value) => acc.concat(value), [])
              .filter((data) => {
                return data.select === true;
              });
            datas.forEach((item) => {
              _arr.push({
                title: item.id ? item.id : item.title.toString().match(/\d+/g),
                index: item.index,
              });
            });

            var result = "";
            var _index = 1;
            _arr.forEach((item, index) => {
              item.index === _index
                ? index === 0
                  ? (result += "")
                  : (result += ",")
                : ((result += "|"), (_index = item.index));
              result += item.title;
            });

            var zhixuanLength = 1;

            result.split("|").forEach((item) => {
              zhixuanLength = zhixuanLength * item.split(",").length;
            });

            this.countOfCombinationBet = countOfType * zhixuanLength;
          } else {
            this.countOfCombinationBet =
              countOfType *
              this.permutation(
                parseInt(this.gdfiveselectData.currentTypeTab),
                countOfGdfiveselectSpecialCode
              );
          }
          this.totalAmount = this.countOfCombinationBet * this.amount;
        } else if (this.game.info.game_type === "fc3d") {
          if (currentClassTypeId === "5") {
            var _arr = [];
            var datas = fc3dErzidingweiNumberList
              .reduce((acc, value) => acc.concat(value), [])
              .filter((data) => {
                return data.select === true;
              });
            datas.forEach((item) => {
              _arr.push({
                title: item.id ? item.id : item.title.toString().match(/\d+/g),
                index: item.index,
              });
            });

            var result = "";
            var _index = 1;
            _arr.forEach((item, index) => {
              item.index === _index
                ? index === 0
                  ? (result += "")
                  : (result += ",")
                : ((result += "|"), (_index = item.index));
              result += item.title;
            });

            var zhixuanLength = 1;

            result.split("|").forEach((item) => {
              zhixuanLength = zhixuanLength * item.split(",").length;
            });

            this.countOfCombinationBet = countOfType * zhixuanLength;
          } else if (currentClassTypeId === "7" || currentClassTypeId === "8") {
            var _arr = [];
            var datas = fc3dNumberList
              .reduce((acc, value) => acc.concat(value), [])
              .filter((data) => {
                return data.select === true;
              });
            datas.forEach((item) => {
              _arr.push({
                title: item.id ? item.id : item.title.toString().match(/\d+/g),
                index: item.index,
              });
            });

            var fc3dMax = this.fc3dData.currentTypeTab;

            if (_arr.length < fc3dMax) {
              this.countOfCombinationBet = 0;
            } else {
              this.countOfCombinationBet = countOfType;
            }
          } else {
            this.countOfCombinationBet =
              countOfType *
              this.permutation(
                parseInt(this.fc3dData.currentTypeTab),
                countOfSpecialCode
              );
          }
          this.totalAmount = this.countOfCombinationBet * this.amount;
        } else if (this.game.info.game_type === "gdklten") {
          this.countOfCombinationBet =
            countOfType *
            this.permutation(
              parseInt(this.gdkltenData.currentTypeTab),
              countOfSpecialCode
            );
          this.totalAmount = this.countOfCombinationBet * this.amount;
        } else if (this.game.info.game_type === "cqklten") {
          this.countOfCombinationBet =
            countOfType *
            this.permutation(
              parseInt(this.cqkltenData.currentTypeTab),
              countOfSpecialCode
            );
          this.totalAmount = this.countOfCombinationBet * this.amount;
        } else {
          this.countOfCombinationBet =
            countOfType *
            this.permutation(
              parseInt(this.lhcData.currentTypeTab),
              countOfLhcSpecialCode
            );
          this.totalAmount = this.countOfCombinationBet * this.amount;
        }
      }
    },
    permutation(m, n) {
      let upRow = 1;
      let downRow = 1;
      for (let i = 0; i < m; i++) {
        upRow = upRow * (n - i);
        downRow = downRow * (m - i);
      }
      return upRow / downRow;
    },
    permutationList(numbers) {
      var permArr = [];
      var usedChars = [];

      function permute(input) {
        var i, ch;
        for (i = 0; i < input.length; i++) {
          ch = input.splice(i, 1)[0];
          usedChars.push(ch);
          if (input.length == 0) {
            permArr.push(usedChars.slice());
          }
          permute(input);
          input.splice(i, 0, ch);
          usedChars.pop();
        }
        return permArr;
      }

      return permute(numbers);
    },
    combinationTypeRender(type, count, typeClass) {
      var that = this;
      var classType =
        that.game.info.currentAuctioneerUIClass &&
        that.game.info.currentAuctioneerUIClass.classType;
      var classTypeId =
        that.game.info.currentAuctioneerUIClass &&
        that.game.info.currentAuctioneerUIClass.classTypeId;

      if (this.game.info.game_type === "tiantiancai") {
        if (typeClass === "2") {
          var currentTypeTitle = this.ttcData.class2.currentId;
        }
        var select = this.type
          .reduce((acc, value) => acc.concat(value), [])
          .filter((data) => {
            return data.select === true;
          });
        if (select.length !== 0) {
          select[0].select = false;
        }
        if (typeClass === "2") {
          var relatedItem = this.type
            .reduce((acc, value) => acc.concat(value), [])
            .filter((data) => {
              return data.id === currentTypeTitle;
            });
          if (relatedItem && relatedItem.length !== 0) {
            relatedItem[0].select = true;
            this.ttcData.combinationOdds = relatedItem[0].odds;
          }
        }
      }
      if (this.game.info.game_type.indexOf("fiveselect") > -1) {
        if (typeClass === "3") {
          var currentTypeTitle = this.gdfiveselectData.class3.currentId;
        } else {
          var currentTypeTitle = this.gdfiveselectData.class4.currentId;
        }
        var select = this.type
          .reduce((acc, value) => acc.concat(value), [])
          .filter((data) => {
            return data.select === true;
          });
        if (select.length !== 0) {
          select[0].select = false;
        }
        if (typeClass === "3" || typeClass === "4") {
          var relatedItem = this.type
            .reduce((acc, value) => acc.concat(value), [])
            .filter((data) => {
              return data.id === currentTypeTitle;
            });
          if (relatedItem && relatedItem.length !== 0) {
            relatedItem[0].select = true;
            this.gdfiveselectData.combinationOdds = relatedItem[0].odds;
          }
        }
      }
      if (this.game.info.game_type === "cqklten") {
        if (typeClass === "5") {
          var currentTypeTitle = this.cqkltenData.class4.currentId;
        }
        var select = this.type
          .reduce((acc, value) => acc.concat(value), [])
          .filter((data) => {
            return data.select === true;
          });
        if (select.length !== 0) {
          select[0].select = false;
        }
        if (typeClass === "5") {
          var relatedItem = this.type
            .reduce((acc, value) => acc.concat(value), [])
            .filter((data) => {
              return data.id === currentTypeTitle;
            });
          if (relatedItem && relatedItem.length !== 0) {
            relatedItem[0].select = true;
            this.cqkltenData.combinationOdds = relatedItem[0].odds;
          }
        }
      }
      if (this.game.info.game_type === "gdklten") {
        if (typeClass === "5") {
          var currentTypeTitle = this.gdkltenData.class12.currentId;
        }
        var select = this.type
          .reduce((acc, value) => acc.concat(value), [])
          .filter((data) => {
            return data.select === true;
          });
        if (select.length !== 0) {
          select[0].select = false;
        }
        if (typeClass === "5") {
          var relatedItem = this.type
            .reduce((acc, value) => acc.concat(value), [])
            .filter((data) => {
              return data.id === currentTypeTitle;
            });
          if (relatedItem && relatedItem.length !== 0) {
            relatedItem[0].select = true;
            this.gdkltenData.combinationOdds = relatedItem[0].odds;
          }
        }
      }
      if (this.game.info.game_type === "fc3d") {
        if (typeClass === "5") {
          var currentTypeTitle = this.fc3dData.class5.currentId;
        } else if (typeClass === "7") {
          var currentTypeTitle = this.fc3dData.class7.currentId;
        } else {
          var currentTypeTitle = this.fc3dData.class8.currentId;
        }
        var select = this.type
          .reduce((acc, value) => acc.concat(value), [])
          .filter((data) => {
            return data.select === true;
          });
        if (select.length !== 0) {
          select[0].select = false;
        }
        if (typeClass === "5") {
          var relatedItem = this.type
            .reduce((acc, value) => acc.concat(value), [])
            .filter((data) => {
              return data.id === currentTypeTitle;
            });
          if (relatedItem && relatedItem.length !== 0) {
            relatedItem[0].select = true;
            this.fc3dData.combinationOdds = relatedItem[0].odds;
          }
        }
        if (typeClass === "8" || typeClass === "7") {
          var relatedItem = this.type
            .reduce((acc, value) => acc.concat(value), [])
            .filter((data) => {
              if (typeClass === "8") {
                return data.id === "ZUXUANLIU_" + count;
              } else if (typeClass === "7") {
                return data.id === "ZUXUANSAN_" + count;
              }
            });
          if (relatedItem && relatedItem.length !== 0) {
            relatedItem[0].select = true;
            if (typeClass === "8") {
              this.fc3dData.class8.current = relatedItem[0].originalTitle;
            } else if (typeClass === "7") {
              this.fc3dData.class7.current = relatedItem[0].originalTitle;
            }
            this.fc3dData.combinationOdds = relatedItem[0].odds;
          } else {
            if (typeClass === "8") {
              this.fc3dData.class8.current = this.fc3dData.class8.defaultHint;
            } else if (typeClass === "7") {
              this.fc3dData.class7.current = this.fc3dData.class7.defaultHint;
            }
            this.fc3dData.combinationOdds = "";
          }
        }
      }
      if (this.game.info.game_type.indexOf("lhc") === 0) {
        var suffix = this.lhcData.zhong === "1" ? "IN" : "NOT";
        var select = this.type
          .reduce((acc, value) => acc.concat(value), [])
          .filter((data) => {
            return data.select === true;
          });
        if (select.length !== 0) {
          select[0].select = false;
        }
        var relatedItem = this.type
          .reduce((acc, value) => acc.concat(value), [])
          .filter((data) => {
            if (type === "class12") {
              return data.id === "TEMA_HE_ANIMAL_" + count + "_" + suffix;
            } else if (
              typeClass === "8" &&
              this.lhcData.currentTypeTab !== ""
            ) {
              return (
                data.id ===
                "ANIMAL_LIAN_" + this.lhcData.currentTypeTab + "_" + suffix
              );
            } else if (
              typeClass === "11" &&
              this.lhcData.currentTypeTab !== ""
            ) {
              return (
                data.id ===
                "WEI_LIAN_" + this.lhcData.currentTypeTab + "_" + suffix
              );
            } else if (
              typeClass === "12" &&
              this.lhcData.currentTypeTab !== ""
            ) {
              return data.id === "ALLNOTIN_" + this.lhcData.currentTypeTab;
            }
          });
        if (relatedItem && relatedItem.length !== 0) {
          relatedItem[0].select = true;
          this.lhcData.combinationOdds = relatedItem[0].odds;
        }

        if (relatedItem.length !== 0 && this.lhcData.specialItemSelected) {
          var specialItem = this.type
            .reduce((acc, value) => acc.concat(value), [])
            .filter((data) => {
              if (typeClass === "8" && this.lhcData.currentTypeTab !== "") {
                return (
                  data.id ===
                  "ANIMAL_LIAN_" +
                    this.lhcData.currentTypeTab +
                    "_" +
                    suffix +
                    "_BEN"
                );
              } else if (
                typeClass === "11" &&
                this.lhcData.currentTypeTab !== ""
              ) {
                return (
                  data.id ===
                  "WEI_LIAN_" +
                    this.lhcData.currentTypeTab +
                    "_" +
                    suffix +
                    "_0"
                );
              } else {
                return [];
              }
            });
          if (specialItem.length !== 0) {
            this.lhcData.specialItemOdds = specialItem[0].odds;
          }
        }
      }

      this.$forceUpdate();
    },
    choose_money(item, event) {
      this.amount = this.amount ? this.amount : 0;
      var _activedCoin = this.coins.filter((item) => {
        return item.active === true;
      });
      if (_activedCoin.length > 0) {
        _activedCoin[0].active = false;
      }
      item.active = true;
      //总价
      var increase = parseInt(item.value);
      this.amount = parseInt(this.amount) + increase;

      this.choose_num({ select: false });
    },
    orderAuctionByUI() {
      var that = this;
      this.toggleAside("orderComfirm");
      this.subInfo(function () {
        that.scrollToFloorEnd();
      });
 if (this._TEMPLATE === "template-1") {
        this.TEMPLATE1_toggleAuctioneerUI(false);
      }
      if (this._TEMPLATE === "template-2") {
        this.TEMPLATE2_toggleAuctioneerUI(false);
      }
    },
    createBetList(forConfirmOdds) {
      var that = this;
      var betting = [];
      var current =
        that.game.info.currentAuctioneerUIClass &&
        that.game.info.currentAuctioneerUIClass.class;
      var currentId =
        that.game.info.currentAuctioneerUIClass &&
        that.game.info.currentAuctioneerUIClass.id;
      var currentClassType =
        that.game.info.currentAuctioneerUIClass &&
        that.game.info.currentAuctioneerUIClass.classType;
      var currentClassTypeId =
        that.game.info.currentAuctioneerUIClass &&
        that.game.info.currentAuctioneerUIClass.classTypeId;
      var _countOfBet = this.type
        .reduce((acc, value) => acc.concat(value), [])
        .filter((data) => {
          return data.select === true;
        });

      if (forConfirmOdds) {
        _countOfBet.forEach((item) => {
          betting[item.id] = this.amount;
        });
        return betting;
      }

      if (
        this.$route.query.type.indexOf("lhc") === 0 ||
        this.$route.query.type.indexOf("fiveselect") > -1 ||
        this.$route.query.type === "fc3d" ||
        this.$route.query.type === "gdklten" ||
        this.$route.query.type === "cqklten"
      ) {
        function getBet(_item) {
          if (
            that.$route.query.type.indexOf("fiveselect") > -1 &&
            currentClassTypeId === "1"
          ) {
            return _item.title;
          }

          if (
            that.$route.query.type === "fc3d" &&
            (currentClassTypeId === "2" ||
              currentClassTypeId === "3" ||
              currentClassTypeId === "4" ||
              currentClassTypeId === "9")
          ) {
            if (isNaN(formatTitle(_item).split("-")[1])) {
              return "";
            } else {
              return formatTitle(_item).split("-")[1];
            }
          }
          if (that.$route.query.type === "fc3d" && currentClassTypeId === "6") {
            if (isNaN(formatTitle(_item).split("_")[1])) {
              return "";
            } else {
              return formatTitle(_item).split("_")[1];
            }
          }

          var _arr = [];
          if (that.$route.query.type.indexOf("fiveselect") > -1) {
            var combinationRule =
              currentClassTypeId === "3"
                ? that.gdfiveselectNumberList
                : currentClassTypeId === "4"
                ? that.gdfiveselectZhixuanNumberList
                : [];
          }
          if (
            that.$route.query.type === "gdklten" ||
            that.$route.query.type === "cqklten"
          ) {
            var combinationRule =
              currentClassTypeId === "5" ? that.gdkltenNumberList : [];
          }
          if (that.$route.query.type === "fc3d") {
            var combinationRule =
              currentClassTypeId === "7" || currentClassTypeId === "8"
                ? that.fc3dNumberList
                : currentClassTypeId === "5"
                ? that.fc3dErzidingweiNumberList
                : [];
          }
          if (that.$route.query.type.indexOf("lhc") === 0) {
            var combinationRule =
              currentClassTypeId === "8" || currentId === "class12"
                ? that.lhcAnimalRule
                : currentClassTypeId === "11"
                ? that.lhcWeiLianRule
                : currentClassTypeId === "10" || currentClassTypeId === "12"
                ? that.lhcNumberList
                : [];
          }

          var datas = combinationRule
            .reduce((acc, value) => acc.concat(value), [])
            .filter((data) => {
              return data.select === true;
            });
          datas.forEach((item) => {
            if (current === "直选" || currentClassType === "直选") {
              _arr.push({
                title: item.id ? item.id : item.title.toString().match(/\d+/g),
                index: item.index,
              });
            } else if (
              this.$route.query.type === "fc3d" &&
              currentClassTypeId === "5"
            ) {
              _arr.push({
                title: item.id ? item.id : item.title.toString().match(/\d+/g),
                index: item.index,
              });
            } else {
              _arr.push(
                item.id ? item.id : item.title.toString().match(/\d+/g)
              );
            }
          });

          if (current === "直选" || currentClassType === "直选") {
            var result = "";
            var _index = 1;
            _arr.forEach((item, index) => {
              item.index === _index
                ? index === 0
                  ? (result += "")
                  : (result += ",")
                : ((result += "|"), (_index = item.index));
              result += item.title;
            });
            if (result.indexOf("|") === 0) {
              result = result.substr(1);
            }
            return result;
          } else if (
            this.$route.query.type === "fc3d" &&
            currentClassTypeId === "5"
          ) {
            var result = "";
            var _index = 1;
            _arr.forEach((item, index) => {
              item.index === _index
                ? index === 0
                  ? (result += "")
                  : (result += ",")
                : ((result += "|"), (_index = item.index));
              result += item.title;
            });
            return result;
          } else {
            return _arr.join(",");
          }
        }

        function formatTitle(item) {
          if (that.$route.query.type.indexOf("lhc") === 0) {
            switch (currentId) {
              case "class12":
                return current + "-" + item.title.substring(2);
                break;
              default:
                if (that._TEMPLATE === "template-3") {
                  return (
                    (currentClassTypeId === "3" ? current : currentClassType) +
                    "-" +
                    item.title
                  );
                } else {
                  return current + "-" + item.title;
                }
                break;
            }
          } else if (that.$route.query.type.indexOf("fiveselect") > -1) {
            switch (current) {
              case "连码":
              case "连码一中一":
              case "连码二中二":
              case "连码三中三":
              case "连码四中四":
              case "连码五中五":
              case "连码六中五":
              case "连码七中五":
              case "连码八中五":
              case "前二组选":
              case "前三组选":
              case "直选":
              case "前二直选":
              case "前三直选":
                return item.title;
                break;
              case "数字盘":
              case "两面盘":
                return item.originalTitle;
                break;
              default:
                return current + "-" + item.title;
                break;
            }
          } else if (that.$route.query.type === "fc3d") {
            switch (currentClassTypeId) {
              case "4":
              case "6":
              case "1":
                return item.originalTitle;
                break;
              case "5":
              case "7":
              case "8":
                return item.title;
                break;
              default:
                return current + "-" + item.title;
                break;
            }
          } else {
            return item.originalTitle;
          }
        }

        _countOfBet.forEach((item) => {
          betting.push({
            key: item.id,
            money: this.amount,
            name: formatTitle(item),
            bet: getBet(item),
          });
        });
      } else if (this.$route.query.type.indexOf("28") === -1) {
        _countOfBet.forEach((item) => {
          betting.push({
            nickname: JSON.parse(localStorage.getItem("user")).nickname,
            key: item.id,
            money: this.amount,
            name: item.originalTitle,
            bet: isNaN(item.title) ? "" : item.title,
            avatar: this.avatar,
          });
        });
      } else {
        _countOfBet.forEach((item) => {
          betting.push({
            nickname: JSON.parse(localStorage.getItem("user")).nickname,
            key: item.id,
            money: this.amount,
            name: item.title,
            avatar: this.avatar,
          });
        });
      }
      return betting;
    },
    //点击确定投注时
    subInfo(callback) {
      var that = this;
      var current =
        that.game.info.currentAuctioneerUIClass &&
        that.game.info.currentAuctioneerUIClass.class;
      var currentId =
        that.game.info.currentAuctioneerUIClass &&
        that.game.info.currentAuctioneerUIClass.id;
      var currentClassType =
        that.game.info.currentAuctioneerUIClass &&
        that.game.info.currentAuctioneerUIClass.classType;
      var currentClassTypeId =
        that.game.info.currentAuctioneerUIClass &&
        that.game.info.currentAuctioneerUIClass.classTypeId;
      if (
        this.$route.query.type === "wanneng" ||
        this.$route.query.type === "damacai" ||
        this.$route.query.type === "duoduo" ||
        this.$route.query.type === "yuedawan" ||
        this.$route.query.type === "shaba88" ||
        this.$route.query.type === "saimahui" ||
        this.$route.query.type === "xinjapowz"
      ) {
        var betting = [];
        var keys = [];
        var key1 = this.wannengKey1.value ? keys.push(1) : false;
        var key2 = this.wannengKey2.value ? keys.push(2) : false;
        var key3 = this.wannengKey3.value ? keys.push(3) : false;
        var key4 = this.wannengKey4.value ? keys.push(4) : false;
        var key5 = this.wannengKey5.value ? keys.push(5) : false;
        if (this.game.info.currentAuctioneerUIClass.id === "2") {
          keys.forEach((item) => {
            this.generatedSequenceList.forEach((_item) => {
              betting.push({
                nickname: JSON.parse(localStorage.getItem("user")).nickname,
                name:
                  this.wannengConvertTitle(
                    that.game.info.currentAuctioneerUIClass.alias
                  ) +
                  "_" +
                  this.wannengConvertTitle(this["wannengKey" + item].alias),
                key:
                  that.game.info.currentAuctioneerUIClass.alias +
                  "_" +
                  this["wannengKey" + item].alias,
                money: this["wannengKey" + item].value,
                avatar: this.avatar,
                bet: _item,
              });
            });
          });
        } else if (this.game.info.currentAuctioneerUIClass.id === "3") {
          keys.forEach((item) => {
            this.calculatedPermutationList.forEach((_item) => {
              betting.push({
                nickname: JSON.parse(localStorage.getItem("user")).nickname,
                name:
                  this.wannengConvertTitle(
                    that.game.info.currentAuctioneerUIClass.alias
                  ) +
                  "_" +
                  this.wannengConvertTitle(this["wannengKey" + item].alias),
                key:
                  that.game.info.currentAuctioneerUIClass.alias +
                  "_" +
                  this["wannengKey" + item].alias,
                money: this["wannengKey" + item].value,
                avatar: this.avatar,
                bet: _item,
              });
            });
          });
        } else {
          keys.forEach((item) => {
            betting.push({
              nickname: JSON.parse(localStorage.getItem("user")).nickname,
              name:
                this.wannengConvertTitle(
                  that.game.info.currentAuctioneerUIClass.alias
                ) +
                "_" +
                this.wannengConvertTitle(this["wannengKey" + item].alias) +
                (that.game.info.currentAuctioneerUIClass.id === "4"
                  ? "_M" + Array.from(this.calculatedPermutationList).length
                  : ""),
              key:
                that.game.info.currentAuctioneerUIClass.alias +
                "_" +
                this["wannengKey" + item].alias +
                (that.game.info.currentAuctioneerUIClass.id === "4"
                  ? "_M" + Array.from(this.calculatedPermutationList).length
                  : ""),
              money: this["wannengKey" + item].value,
              avatar: this.avatar,
              bet:
                this.wannengNum1 +
                "" +
                this.wannengNum2 +
                "" +
                this.wannengNum3 +
                "" +
                this.wannengNum4,
            });
          });
        }
        touzhu(this.currentDrawing, this.gameRoom, this.gameType, betting).then(
          (result) => {
            if (result.data.code === "SUCCESS") {
              if (!this.checkRoomType()) {
                this.toggleAuctioneerUI(true);
              }

              this.updateBalance();
              if (this.checkRoomType()) {
                this.reset(false);
              }

              if (!this.checkRoomType()) {
                this.$nextTick(function () {
                  typeof callback === "function" && callback();
                });
              }

              app.Mint.Toast(result.data.msg);
            } else {
              app.Mint.Toast(result.data.msg);
            }
          }
        );
      } else if (
        this.$route.query.type.indexOf("lhc") === 0 ||
        this.$route.query.type.indexOf("fiveselect") > -1 ||
        this.$route.query.type === "fc3d" ||
        this.$route.query.type === "gdklten" ||
        this.$route.query.type === "cqklten" ||
        this.$route.query.type === "tiantiancai"
      ) {
        var betting = [];
        var _countOfBet = this.type
          .reduce((acc, value) => acc.concat(value), [])
          .filter((data) => {
            return data.select === true;
          });

        function getBet(_item) {
          if (
            that.$route.query.type.indexOf("fiveselect") > -1 &&
            currentId === "class1"
          ) {
            return _item.title;
          }

          if (
            that.$route.query.type === "fc3d" &&
            (currentId === "class2" ||
              currentId === "class3" ||
              currentClassTypeId === "9")
          ) {
            if (isNaN(formatTitle(_item).split("-")[1])) {
              return "";
            } else {
              return formatTitle(_item).split("-")[1];
            }
          }
          if (that.$route.query.type === "fc3d" && currentClassTypeId === "6") {
            if (isNaN(formatTitle(_item).split("_")[1])) {
              return "";
            } else {
              return formatTitle(_item).split("_")[1];
            }
          }
          if (that.$route.query.type === "cqklten" && current === "正码") {
            return _item.id.split("_")[1];
          }

          var _arr = [];
          if (that.$route.query.type.indexOf("fiveselect") > -1) {
            var combinationRule =
              currentClassTypeId === "3"
                ? that.gdfiveselectNumberList
                : currentClassTypeId === "4"
                ? that.gdfiveselectZhixuanNumberList
                : [];
          }
          if (that.$route.query.type === "tiantiancai") {
            var combinationRule =
              currentClassTypeId === "2" ? that.ttcNumberList : [];
          }
          if (
            that.$route.query.type === "gdklten" ||
            that.$route.query.type === "cqklten"
          ) {
            var combinationRule =
              currentClassTypeId === "5" ? that.gdkltenNumberList : [];
          }
          if (that.$route.query.type === "fc3d") {
            var combinationRule =
              currentClassTypeId === "7" || currentClassTypeId === "8"
                ? that.fc3dNumberList
                : currentClassTypeId === "5"
                ? that.fc3dErzidingweiNumberList
                : [];
          }
          if (that.$route.query.type.indexOf("lhc") === 0) {
            var combinationRule =
              currentClassTypeId === "8" || currentId === "class12"
                ? that.lhcAnimalRule
                : currentClassTypeId === "11"
                ? that.lhcWeiLianRule
                : currentClassTypeId === "10" || currentClassTypeId === "12"
                ? that.lhcNumberList
                : [];
          }

          var datas = combinationRule
            .reduce((acc, value) => acc.concat(value), [])
            .filter((data) => {
              return data.select === true;
            });
          datas.forEach((item) => {
            if (
              that.$route.query.type.indexOf("fiveselect") > -1 &&
              currentClassTypeId === "4"
            ) {
              _arr.push({
                title: item.id ? item.id : item.title.toString().match(/\d+/g),
                index: item.index,
              });
            } else if (
              that.$route.query.type === "fc3d" &&
              currentClassTypeId === "5"
            ) {
              _arr.push({
                title: item.id ? item.id : item.title.toString().match(/\d+/g),
                index: item.index,
              });
            } else {
              _arr.push(
                item.id ? item.id : item.title.toString().match(/\d+/g)
              );
            }
          });

          if (
            that.$route.query.type.indexOf("fiveselect") > -1 &&
            currentClassTypeId === "4"
          ) {
            var result = "";
            var _index = 1;
            _arr.forEach((item, index) => {
              item.index === _index
                ? index === 0
                  ? (result += "")
                  : (result += ",")
                : ((result += "|"), (_index = item.index));
              result += item.title;
            });
            if (result.indexOf("|") === 0) {
              result = result.substr(1);
            }
            return result;
          } else if (
            that.$route.query.type === "fc3d" &&
            currentClassTypeId === "5"
          ) {
            var result = "";
            var _index = 1;
            _arr.forEach((item, index) => {
              item.index === _index
                ? index === 0
                  ? (result += "")
                  : (result += ",")
                : ((result += "|"), (_index = item.index));
              result += item.title;
            });
            return result;
          } else {
            return _arr.join(",");
          }
        }

        function formatTitle(item) {
          if (that.$route.query.type.indexOf("lhc") === 0) {
            switch (currentId) {
              case "class12":
                return current + "-" + item.title.substring(2);
                break;
              default:
                if (that._TEMPLATE === "template-3") {
                  return (
                    (currentClassTypeId === "3" ? current : currentClassType) +
                    "-" +
                    item.title
                  );
                } else {
                  return current + "-" + item.title;
                }
                break;
            }
          } else if (that.$route.query.type.indexOf("fiveselect") > -1) {
            switch (currentClassTypeId) {
              case "3":
              case "4":
                return item.title;
                break;
              case "class1":
              case "class2":
                return item.originalTitle;
                break;
              default:
                return current + "-" + item.title;
                break;
            }
          } else if (that.$route.query.type === "fc3d") {
            switch (currentClassTypeId) {
              case "4":
              case "6":
              case "1":
                return item.originalTitle;
                break;
              case "5":
              case "7":
              case "8":
                return item.title;
                break;
              default:
                return current + "-" + item.title;
                break;
            }
          } else {
            return item.originalTitle;
          }
        }
        _countOfBet.forEach((item) => {
          betting.push({
            key: item.id,
            money: this.amount,
            name: formatTitle(item),
            bet: getBet(item),
          });
        });
        touzhu(this.currentDrawing, this.gameRoom, this.gameType, betting).then(
          (result) => {
            if (result.data.code === "SUCCESS") {
              this.updateBalance();
              this.reset(false);
              app.Mint.Toast(result.data.msg);
            } else {
              app.Mint.Toast(result.data.msg);
            }
          }
        );
      } else if (this.$route.query.type.indexOf("28") === -1) {
        var betting = [];
        var _countOfBet = this.type
          .reduce((acc, value) => acc.concat(value), [])
          .filter((data) => {
            return data.select === true;
          });

        _countOfBet.forEach((item) => {
          console.log(item);
          betting.push({
            nickname: JSON.parse(localStorage.getItem("user")).nickname,
            key: item.id,
            money: this.amount,
            name: item.originalTitle,
            bet: isNaN(item.title) ? "" : item.title.toString(),
            avatar: this.avatar,
            dict_keyword: item.lang_key,
            drawing_index: that.game.currentDrawing.issue,
          });
        });

        touzhu(this.currentDrawing, this.gameRoom, this.gameType, betting).then(
          (result) => {
            if (result.data.code === "SUCCESS") {
              //send boardcast request
              $sock.send(
                "Index/sayBet",
                {
                  message: betting,
                },
                function (data) {
                  console.log(data);
                }
              );

              var data_time = getClientTime();
              var lotteries = result.data.result.user.lotteries;
              var lotteries_data = "";
              for (var i in lotteries) {
                if (i == lotteries.length - 1) {
                  lotteries_data +=
                    lotteries[i].name +
                    " " +
                    this.currencySymbol() +
                    lotteries[i].money;
                } else {
                  lotteries_data +=
                    lotteries[i].name +
                    " " +
                    this.currencySymbol() +
                    lotteries[i].money +
                    "、";
                }
              }

              that.$store.commit("UPDATE_tradingFloorMessages", {
                type: "bet-self",
                nickname: result.data.result.user.nickname,
                msg: lotteries_data,
                time: data_time,
                avatar: result.data.result.user.headImage,
                drawingIndex: that.game.currentDrawing.issue,
              });

              // this.showSpecialCodeBet()
              if (!this.checkRoomType()) {
                this.toggleAuctioneerUI(true);
              }

              // this.$parent.function_account_balance();
              this.updateBalance();
              // this.$parent.jump_bottom();
              if (this.checkRoomType()) {
                this.reset(false);
              }

              if (!this.checkRoomType()) {
                this.$nextTick(function () {
                  typeof callback === "function" && callback();
                });
              }

              app.Mint.Toast(result.data.msg);
            } else {
              app.Mint.Toast(result.data.msg);
            }
          }
        );
      } else {
        var betting = [];
        var _countOfBet = this.type
          .reduce((acc, value) => acc.concat(value), [])
          .filter((data) => {
            return data.select === true;
          });

        _countOfBet.forEach((item) => {
          betting.push({
            nickname: JSON.parse(localStorage.getItem("user")).nickname,
            key: item.id,
            money: this.amount,
            name: item.title,
            avatar: this.avatar,
            dict_keyword: item.lang_key,
            drawing_index: that.game.currentDrawing.issue,
          });
        });

        touzhu(this.currentDrawing, this.gameRoom, this.gameType, betting).then(
          (result) => {
            if (result.data.code === "SUCCESS") {
              //send boardcast request
              $sock.send(
                "Index/sayBet",
                {
                  message: betting,
                },
                function (data) {
                  console.log(data);
                }
              );

              var data_time = getClientTime();
              var lotteries = result.data.result.user.lotteries;
              var lotteries_data = "";
              var prefix =
                that.game.info.game_type.indexOf("28") === -1
                  ? ""
                  : getWord("item_tema") + "-";
              for (var i in lotteries) {
                if (i == lotteries.length - 1) {
                  lotteries_data +=
                    prefix +
                    lotteries[i].name +
                    " " +
                    this.currencySymbol() +
                    lotteries[i].money;
                } else {
                  lotteries_data +=
                    prefix +
                    lotteries[i].name +
                    " " +
                    this.currencySymbol() +
                    lotteries[i].money +
                    "、";
                }
              }

              that.$store.commit("UPDATE_tradingFloorMessages", {
                type: "bet-self",
                nickname: result.data.result.user.nickname,
                msg: isNaN(lotteries_data.split(this.currencySymbol())[0])
                  ? lotteries_data
                  : prefix + lotteries_data,
                time: data_time,
                avatar: result.data.result.user.headImage,
                drawingIndex: that.game.currentDrawing.issue,
              });

              // this.showSpecialCodeBet()
              if (this.$route.query.type !== "pc28one") {
                this.toggleAuctioneerUI(true);
              }

              if (this.$route.query.type === "pc28one") {
                this.reset(false);
              }

              this.updateBalance();

              this.$nextTick(function () {
                typeof callback === "function" && callback();
              });

              app.Mint.Toast(result.data.msg);
            } else {
              app.Mint.Toast(result.data.msg);
            }
          }
        );
      }
    },
    chip_select(aside, closeTag) {
      if (!closeTag) {
        if (
          this.coins.filter((data) => {
            return data.select === true;
          }).length != 3
        ) {
          app.Mint.Toast(getWord("bargaining_chip_rules2",{ '$1':'3'}));
          return false;
        } else {
          var userCoinsInit = [];
          this.coins
            .filter((data) => {
              return data.select === true;
            })
            .forEach((item) => {
              userCoinsInit.push(item.value);
            });
          this.originalUserCoinsInit = userCoinsInit;
          window.localStorage.setItem("coinsInit", userCoinsInit);
        }
      } else {
        this.originalUserCoinsInit.forEach((item) => {
          this.coins.forEach((data) => {
            if (data.value === item) {
              data.select = true;
            }
          });
        });
      }
      this.toggleAside(aside);
    },
    toggleAside(data, callback) {
      this.aside[data] = !this.aside[data];
      this.$nextTick(function () {
        typeof callback === "function" && callback();
      });
    },
    openModal(data) {
      var that = this;
      function checkAPI(name) {
        return that.api[name] ? true : false;
      }
      if (checkAPI(data.api.name)) {
        this.api[data.api.name].func(data.type).then((result) => {
          if (result.data.code === "SUCCESS") {
            this.toggleAside("asideGameRule");
            this.api.explainList.result = result.data.result.content;
          } else if (result.data.code === "FAILUE") {
            app.Mint.Toast(result.data.msg);
          }
        });
      } else {
        console.log('openModal ERROR: API "' + data.api.name + '" not exist.');
      }
    },
    checkRoomType() {
      var result = 0;
      var types = [];
      if (this.roomTypesAdded) {
        types = this.roomTypes.concat(this.roomTypesAdded);
      } else {
        types = this.roomTypes;
      }
      types.forEach((item) => {
        this.gameType !== item ? (result += 0) : (result += 1);
      });
      return result;
    },
    convertTitle(item) {
      if (item.length === 3) {
        return item.slice(2);
      } else {
        return item;
      }
    },
    checkClass(classNumber) {
      var relatedClassRule = this[this.gameType + "ClassRule"].filter(
        (item) => {
          return item.id === this.game.info.currentAuctioneerUIClass.id;
        }
      );
      if (relatedClassRule.length > 0) {
        var related = 0;
        classNumber.forEach((item) => {
          if (relatedClassRule[0].id === item) {
            related += 1;
          }
        });
        if (related !== 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    restore() {
      console.log(this.$parent,this.toggleHeaderMenuDocker);
      if (this._TEMPLATE === "template-1"||this._TEMPLATE === "template-2") {
        this.toggleHeaderMenuDocker(true);
      }
    },
    setDefault(data) {
      var that = this;
      if (!data) {
        return false;
      }
      if (data.type.indexOf("lhc") === 0) {
        if (
          that._TEMPLATE === "template-1" ||
          that._TEMPLATE === "template-2"
        ) {
          if (data.id === "class15") {
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIANMA_4_ALLIN";
                })[0]
            );
          }

          if (data.classTypeId === "12") {
            that.class17Switch(getWord(["wu", "buzhong"]), 5);
          }

          if (data.id === "class13") {
            that.bindTypeTab(
              that.lhcData.typeTab.class13
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "2";
                })[0],
              "class13"
            );
          }

          if (data.id === "class16") {
            that.bindTypeTab(
              that.lhcData.typeTab.class15
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "2";
                })[0],
              "class15"
            );
          }
        }

        if (that._TEMPLATE === "template-3") {
          if (data.id === "class13") {
            that.lhcData.currentTypeTab = 2;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "ANIMAL_LIAN_2_IN";
                })[0]
            );
          }
          if (data.id === "class14") {
            that.lhcData.currentTypeTab = 3;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "ANIMAL_LIAN_3_IN";
                })[0]
            );
          }
          if (data.id === "class15") {
            that.lhcData.currentTypeTab = 4;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "ANIMAL_LIAN_4_IN";
                })[0]
            );
          }
          if (data.id === "class16") {
            that.lhcData.currentTypeTab = 5;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "ANIMAL_LIAN_5_IN";
                })[0]
            );
          }
          if (data.id === "class18") {
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIANMA_4_ALLIN";
                })[0]
            );
          }
          if (data.id === "class19") {
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIANMA_3_ALLIN";
                })[0]
            );
          }
          if (data.id === "class20") {
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIANMA_3_IN_2";
                })[0]
            );
          }
          if (data.id === "class21") {
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIANMA_2_ALLIN";
                })[0]
            );
          }
          if (data.id === "class22") {
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIANMA_2_IN_TEMA";
                })[0]
            );
          }
          if (data.id === "class23") {
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIANMA_1_IN_TEMA";
                })[0]
            );
          }
          if (data.id === "class24") {
            that.lhcData.currentTypeTab = 2;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "WEI_LIAN_2_IN";
                })[0]
            );
          }
          if (data.id === "class25") {
            that.lhcData.currentTypeTab = 3;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "WEI_LIAN_3_IN";
                })[0]
            );
          }
          if (data.id === "class26") {
            that.lhcData.currentTypeTab = 4;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "WEI_LIAN_4_IN";
                })[0]
            );
          }
          if (data.id === "class27") {
            that.lhcData.currentTypeTab = 5;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "ALLNOTIN_5";
                })[0]
            );
          }
          if (data.id === "class28") {
            that.lhcData.currentTypeTab = 6;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "ALLNOTIN_6";
                })[0]
            );
          }
          if (data.id === "class29") {
            that.lhcData.currentTypeTab = 7;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "ALLNOTIN_7";
                })[0]
            );
          }
          if (data.id === "class30") {
            that.lhcData.currentTypeTab = 8;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "ALLNOTIN_8";
                })[0]
            );
          }
          if (data.id === "class31") {
            that.lhcData.currentTypeTab = 9;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "ALLNOTIN_9";
                })[0]
            );
          }
          if (data.id === "class32") {
            that.lhcData.currentTypeTab = 10;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "ALLNOTIN_10";
                })[0]
            );
          }
          if (data.id === "class33") {
            that.lhcData.currentTypeTab = 11;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "ALLNOTIN_11";
                })[0]
            );
          }
          if (data.id === "class34") {
            that.lhcData.currentTypeTab = 12;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "ALLNOTIN_12";
                })[0]
            );
          }
        }
      }

      if (data.type.indexOf("fiveselect") > -1) {
        if (
          that._TEMPLATE === "template-1" ||
          that._TEMPLATE === "template-2"
        ) {
          if (data.classTypeId === "3") {
            that.class3Switch("LIANMAONE", 1);
          }

          if (data.classTypeId === "4") {
            that.class4Switch("QIANER", 2);
          }
        }

        if (that._TEMPLATE === "template-3") {
          if (data.id === "class3") {
            that.gdfiveselectData.class3.current = getWord([
              "lianma",
              "yi",
              "zhong",
              "yi",
            ]);
            that.gdfiveselectData.class3.currentId = "LIANMAONE";
            that.gdfiveselectData.currentTypeTab = 1;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIANMAONE";
                })[0]
            );
          }
          if (data.id === "class4") {
            that.gdfiveselectData.class3.current = getWord([
              "lianma",
              "er",
              "zhong",
              "er",
            ]);
            that.gdfiveselectData.class3.currentId = "LIANMATWO";
            that.gdfiveselectData.currentTypeTab = 2;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIANMATWO";
                })[0]
            );
          }
          if (data.id === "class5") {
            that.gdfiveselectData.class3.current = getWord([
              "lianma",
              "san",
              "zhong",
              "san",
            ]);
            that.gdfiveselectData.class3.currentId = "LIANMATHREE";
            that.gdfiveselectData.currentTypeTab = 3;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIANMATHREE";
                })[0]
            );
          }
          if (data.id === "class6") {
            that.gdfiveselectData.class3.current = getWord([
              "lianma",
              "si",
              "zhong",
              "si",
            ]);
            that.gdfiveselectData.class3.currentId = "LIANMAFOUR";
            that.gdfiveselectData.currentTypeTab = 4;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIANMAFOUR";
                })[0]
            );
          }
          if (data.id === "class7") {
            that.gdfiveselectData.class3.current = getWord([
              "lianma",
              "wu",
              "zhong",
              "wu",
            ]);
            that.gdfiveselectData.class3.currentId = "LIANMAFIVE";
            that.gdfiveselectData.currentTypeTab = 5;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIANMAFIVE";
                })[0]
            );
          }
          if (data.id === "class8") {
            that.gdfiveselectData.class3.current = getWord([
              "lianma",
              "liu",
              "zhong",
              "wu",
            ]);
            that.gdfiveselectData.class3.currentId = "LIANMASIX";
            that.gdfiveselectData.currentTypeTab = 5;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIANMASIX";
                })[0]
            );
          }
          if (data.id === "class9") {
            that.gdfiveselectData.class3.current = getWord([
              "lianma",
              "qi",
              "zhong",
              "wu",
            ]);
            that.gdfiveselectData.class3.currentId = "LIANMASEVEN";
            that.gdfiveselectData.currentTypeTab = 5;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIANMASEVEN";
                })[0]
            );
          }
          if (data.id === "class10") {
            that.gdfiveselectData.class3.current = getWord([
              "lianma",
              "ba",
              "zhong",
              "wu",
            ]);
            that.gdfiveselectData.class3.currentId = "LIANMAEIGHT";
            that.gdfiveselectData.currentTypeTab = 5;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIANMAEIGHT";
                })[0]
            );
          }
          if (data.id === "class11") {
            that.gdfiveselectData.class3.current = getWord([
              "qianer",
              "zuxuan",
            ]);
            that.gdfiveselectData.class3.currentId = "ZUER";
            that.gdfiveselectData.currentTypeTab = 2;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "ZUER";
                })[0]
            );
          }
          if (data.id === "class12") {
            that.gdfiveselectData.class3.current = getWord([
              "qiansan",
              "zuxuan",
            ]);
            that.gdfiveselectData.class3.currentId = "ZUSAN";
            that.gdfiveselectData.currentTypeTab = 3;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "ZUSAN";
                })[0]
            );
          }

          if (data.id === "class13") {
            that.gdfiveselectData.class4.current = getWord([
              "qianer",
              "zhixuan",
            ]);
            that.gdfiveselectData.class4.currentId = "QIANER";
            that.gdfiveselectData.class4.currentTypeTab = 2;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "QIANER";
                })[0]
            );
          }
          if (data.id === "class14") {
            that.gdfiveselectData.class4.current = getWord([
              "qiansan",
              "zhixuan",
            ]);
            that.gdfiveselectData.class4.currentId = "QIANSAN";
            that.gdfiveselectData.class4.currentTypeTab = 3;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "QIANSAN";
                })[0]
            );
          }
        }
      }

      if (data.type === "fc3d") {
        if (
          that._TEMPLATE === "template-1" ||
          that._TEMPLATE === "template-2"
        ) {
          if (data.classTypeId === "5") {
            that.fc3dClass5Switch("ERZIBAISHIWEI", 100);
          }
        }

        if (that._TEMPLATE === "template-3") {
          if (data.id === "class5") {
            this.fc3dData.class5.current = getWord([
              "er",
              "zi",
              "positioning",
              "bai2",
              "shi2",
              "place",
            ]);
            this.fc3dData.class5.currentId = "ERZIBAISHIWEI";
            this.fc3dData.currentTypeTab = 100;
            this.fc3dData.class5.currentTypeTab = 100;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "ERZIBAISHIWEI";
                })[0]
            );
          }
          if (data.id === "class6") {
            this.fc3dData.class5.current = getWord([
              "er",
              "zi",
              "positioning",
              "bai2",
              "ge",
              "place",
            ]);
            this.fc3dData.class5.currentId = "ERZIBAIGEWEI";
            this.fc3dData.currentTypeTab = 10;
            this.fc3dData.class5.currentTypeTab = 10;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "ERZIBAIGEWEI";
                })[0]
            );
          }
          if (data.id === "class7") {
            this.fc3dData.class5.current = getWord([
              "er",
              "zi",
              "positioning",
              "shi2",
              "ge",
              "place",
            ]);
            this.fc3dData.class5.currentId = "ERZISHIGEWEI";
            this.fc3dData.currentTypeTab = 1;
            this.fc3dData.class5.currentTypeTab = 1;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "ERZISHIGEWEI";
                })[0]
            );
          }
        }

        if (data.classTypeId === "7") {
          that.fc3dData.class7.current = that.fc3dData.class7.defaultHint;
        }

        if (data.classTypeId === "8") {
          that.fc3dData.class8.current = that.fc3dData.class8.defaultHint;
        }
      }
      if (data.type === "tiantiancai") {
        if (
          that._TEMPLATE === "template-1" ||
          that._TEMPLATE === "template-2"
        ) {
          if (data.classTypeId === "1") {
            that.ttcClass1Switch("0");
          }
          if (data.classTypeId === "2") {
            that.ttcClass2Switch("LIANMA_5_TE", 5);
          }
        }
        if (that._TEMPLATE === "template-3") {
          if (data.id === "class3") {
            that.ttcData.class2.current = getWord(["five", "zhong", "te"]);
            that.ttcData.class2.currentId = "LIANMA_5_TE";
            that.ttcData.currentTypeTab = 5;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIANMA_5_TE";
                })[0]
            );
          }
          if (data.id === "class4") {
            that.ttcData.class2.current = getWord(["six", "zhong", "te"]);
            that.ttcData.class2.currentId = "LIANMA_6_TE";
            that.ttcData.currentTypeTab = 6;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIANMA_6_TE";
                })[0]
            );
          }
          if (data.id === "class5") {
            that.ttcData.class2.current = getWord(["seven", "zhong", "te"]);
            that.ttcData.class2.currentId = "LIANMA_7_TE";
            that.ttcData.currentTypeTab = 7;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIANMA_7_TE";
                })[0]
            );
          }
          if (data.id === "class6") {
            that.ttcData.class2.current = getWord(["eight", "zhong", "te"]);
            that.ttcData.class2.currentId = "LIANMA_8_TE";
            that.ttcData.currentTypeTab = 8;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIANMA_8_TE";
                })[0]
            );
          }
        }
      }

      if (data.type === "gdklten") {
        if (
          that._TEMPLATE === "template-1" ||
          that._TEMPLATE === "template-2"
        ) {
          if (data.classTypeId === "5") {
            that.gdkltenClass12Switch("LIAN_REN_2", 2);
          }
        }
        if (that._TEMPLATE === "template-3") {
          if (data.id === "class12") {
            that.gdkltenData.class12.current = getWord(["renxuan", "er"]);
            that.gdkltenData.class12.currentId = "LIAN_REN_2";
            that.gdkltenData.currentTypeTab = 2;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIAN_REN_2";
                })[0]
            );
          }
          if (data.id === "class13") {
            that.gdkltenData.class12.current = getWord("xuanerlianzu");
            that.gdkltenData.class12.currentId = "LIAN_REN_ZU_2";
            that.gdkltenData.currentTypeTab = 2;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIAN_REN_ZU_2";
                })[0]
            );
          }
          if (data.id === "class14") {
            that.gdkltenData.class12.current = getWord(["renxuan", "san"]);
            that.gdkltenData.class12.currentId = "LIAN_REN_3";
            that.gdkltenData.currentTypeTab = 3;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIAN_REN_3";
                })[0]
            );
          }
          if (data.id === "class15") {
            that.gdkltenData.class12.current = getWord("xuansanqianzu");
            that.gdkltenData.class12.currentId = "LIAN_REN_ZU_2";
            that.gdkltenData.currentTypeTab = 3;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIAN_REN_ZU_3";
                })[0]
            );
          }
          if (data.id === "class16") {
            that.gdkltenData.class12.current = getWord(["renxuan", "si"]);
            that.gdkltenData.class12.currentId = "LIAN_REN_4";
            that.gdkltenData.currentTypeTab = 4;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIAN_REN_4";
                })[0]
            );
          }
          if (data.id === "class17") {
            that.gdkltenData.class12.current = getWord(["renxuan", "wu"]);
            that.gdkltenData.class12.currentId = "LIAN_REN_5";
            that.gdkltenData.currentTypeTab = 5;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIAN_REN_5";
                })[0]
            );
          }
        }
      }

      if (data.type === "cqklten") {
        if (
          that._TEMPLATE === "template-1" ||
          that._TEMPLATE === "template-2"
        ) {
          if (data.classTypeId === "5") {
            that.cqkltenClass4Switch("LIAN_REN_2", 2);
          }
        }
        if (that._TEMPLATE === "template-3") {
          if (data.id === "class4") {
            that.cqkltenData.class4.current = getWord(["renxuan", "er"]);
            that.cqkltenData.class4.currentId = "LIAN_REN_2";
            that.cqkltenData.currentTypeTab = 2;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIAN_REN_2";
                })[0]
            );
          }
          if (data.id === "class5") {
            that.cqkltenData.class4.current = getWord("xuanerlianzu");
            that.cqkltenData.class4.currentId = "LIAN_REN_ZU_2";
            that.cqkltenData.currentTypeTab = 2;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIAN_REN_ZU_2";
                })[0]
            );
          }
          if (data.id === "class6") {
            that.cqkltenData.class4.current = getWord(["renxuan", "san"]);
            that.cqkltenData.class4.currentId = "LIAN_REN_3";
            that.cqkltenData.currentTypeTab = 3;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIAN_REN_3";
                })[0]
            );
          }
          if (data.id === "class7") {
            that.cqkltenData.class4.current = getWord("xuansanqianzu");
            that.cqkltenData.class4.currentId = "LIAN_REN_ZU_3";
            that.cqkltenData.currentTypeTab = 3;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIAN_REN_ZU_3";
                })[0]
            );
          }
          if (data.id === "class8") {
            that.cqkltenData.class4.current = getWord(["renxuan", "si"]);
            that.cqkltenData.class4.currentId = "LIAN_REN_4";
            that.cqkltenData.currentTypeTab = 4;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIAN_REN_4";
                })[0]
            );
          }
          if (data.id === "class9") {
            that.cqkltenData.class4.current = getWord(["renxuan", "wu"]);
            that.cqkltenData.class4.currentId = "LIAN_REN_5";
            that.cqkltenData.currentTypeTab = 5;
            that.choose_num(
              that.type
                .reduce((acc, value) => acc.concat(value), [])
                .filter((item) => {
                  return item.id === "LIAN_REN_5";
                })[0]
            );
          }
        }
      }
    },
    enter(event, index) {
      var deleteCode = [8, 46];
      var numberCode = [
        48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102,
        103, 104, 105,
      ];

      var enter = new Boolean();
      if (deleteCode.indexOf(event.keyCode) > -1) {
        enter = false;
      } else if (numberCode.indexOf(event.keyCode) > -1) {
        enter = true;
      } else {
        enter = null;
      }

      if (
        this.game.info.currentAuctioneerUIClass.id === "4" &&
        this.wannengNum1 &&
        this.wannengNum1 === this.wannengNum2 &&
        this.wannengNum2 === this.wannengNum3 &&
        this.wannengNum3 === this.wannengNum4
      ) {
        app.Mint.Toast(getWord("wzwn_tips11"));
        this["wannengNum" + index] =
          event.target.value > 8
            ? event.target.value - 1
            : Number(event.target.value) + 1;
        return false;
      }

      if (index === 4 && enter) {
      } else if (enter) {
        this.$refs["num" + (index + 1)].focus();
        return false;
      }
      if (index === 1 && enter === false) {
      } else if (enter === false) {
        this.$refs["num" + (index - 1)].focus();
        return false;
      }
    },
    numberBackspace(index) {
      if (index === 1) {
      } else {
        this.$refs["num" + (index - 1)].focus();
      }
    },
    wannengConvertTitle(item) {
      switch (item) {
        case "ONE":
          switch (this.gameType) {
            case "wanneng":
              return getWord("single_number_of_item"); //'单一号码'
            case "damacai":
              return getWord("single_number_of_item2");
            case "duoduo":
              return getWord("single_number_of_item3");
            case "yuedawan":
              return getWord("single_number_of_item3");
            case "shaba88":
              return getWord("single_number_of_item3");
            case "saimahui":
              return getWord("single_number_of_item3");
            case "xinjapowz":
              return getWord("single_number_of_item4");
          }
        case "DA":
          switch (this.gameType) {
            case "wanneng":
              return getWord("type_text"); //'打字玩法'
            case "damacai":
              return getWord("type_text2");
            case "duoduo":
              return getWord("type_text3");
            case "yuedawan":
              return getWord("type_text");
            case "shaba88":
              return getWord("type_text");
            case "saimahui":
              return getWord("type_text");
            case "xinjapowz":
              return getWord("type_text4");
          }
        case "BAO":
          switch (this.gameType) {
            case "wanneng":
              return getWord("type_baozi"); //'包字玩法'
            case "damacai":
              return getWord("type_baozi2");
            case "duoduo":
              return getWord("type_baozi2");
            case "yuedawan":
              return getWord("type_baozi2");
            case "shaba88":
              return getWord("type_baozi2");
            case "saimahui":
              return getWord("type_baozi2");
            case "xinjapowz":
              return getWord("type_baozi3");
          }
        case "WAN":
          switch (this.gameType) {
            case "wanneng":
              return getWord("type_wanbao"); //'万字万保'
            case "damacai":
              return getWord("type_wanbao2");
            case "damacai":
              return getWord("type_wanbao2");
            case "damacai":
              return getWord("type_wanbao2");
            case "damacai":
              return getWord("type_wanbao2");
            case "damacai":
              return getWord("type_wanbao2");
            case "damacai":
              return getWord("type_wanbao3");
          }
        case "BIG":
          return getWord("dawan"); //'大万'
        case "SMALL":
          return getWord("xiaowan"); //'小万'
        default:
          return item;
      }
    },
    calcLength() {

      var keys = [];
      var key1 = this.wannengKey1.value ? keys.push(1) : false;
      var key2 = this.wannengKey2.value ? keys.push(2) : false;
      var key3 = this.wannengKey3.value ? keys.push(3) : false;
      var key4 = this.wannengKey4.value ? keys.push(4) : false;
      var key5 = this.wannengKey5.value ? keys.push(5) : false;
      if (
        this.game.info.currentAuctioneerUIClass.id === "1" ||
        this.game.info.currentAuctioneerUIClass.id === "4"
      ) {
        return 1 * keys.length;
      }
      if (this.game.info.currentAuctioneerUIClass.id === "2") {
        return this.generatedSequenceList.length * keys.length;
      }
      if (this.game.info.currentAuctioneerUIClass.id === "3") {
        if (this.calculatedPermutationList) {
          return (
            Array.from(this.calculatedPermutationList).length * keys.length
          );
        } else {
          return 0;
        }
      }
    },
    calcAmount() {
      var that = this;
      var keys = [];
      var key1 = this.wannengKey1.value ? keys.push(1) : false;
      var key2 = this.wannengKey2.value ? keys.push(2) : false;
      var key3 = this.wannengKey3.value ? keys.push(3) : false;
      var key4 = this.wannengKey4.value ? keys.push(4) : false;
      var key5 = this.wannengKey5.value ? keys.push(5) : false;

      function getLength() {
        if (
          that.game.info.currentAuctioneerUIClass.id === "1" ||
          that.game.info.currentAuctioneerUIClass.id === "4"
        ) {
          return 1;
        }
        if (that.game.info.currentAuctioneerUIClass.id === "2") {
          return that.generatedSequenceList.length;
        }
        if (that.game.info.currentAuctioneerUIClass.id === "3") {
          return Array.from(that.calculatedPermutationList).length;
        }
      }
      var total = 0;
      keys.forEach((item) => {
        total += this["wannengKey" + item].value * getLength();
      });

      return total;
    },
    filterOdds(value) {
      return Math.round(value).toString().length > 6
        ? 999999
        : Math.round(value);
    },
  },
  mounted() {
    let that = this;
    if (this.$refs.betTypesWrapper) {
      this.CustomScroll(this.$refs.betTypesWrapper.id);
    }

    //create type
    beilv(this.gameType, this.gameRoom).then((result) => {
      switch (this.gameType) {
        case "wanneng":
        case "damacai":
        case "duoduo":
        case "yuedawan":
        case "shaba88":
        case "saimahui":
        case "xinjapowz":
          this.currentWannengOdds = result.data.result;
          break;
        case "cqklten":
          if (this._TEMPLATE === "template-3") {
            this.type = this.createType(
              that,
              result,
              that.TEMPLATE3_xyncTypeRule,
              that.xyncColorRule
            );
          } else {
            this.type = this.createType(
              that,
              result,
              that.xyncTypeRule,
              that.xyncColorRule
            );
          }
          break;
        case "jndxy":
          this.type = this.createType(
            that,
            result,
            that.jndxyTypeRule,
            undefined
          );
          break;
        case "tiantiancai":
          if (this._TEMPLATE === "template-3") {
            this.type = this.createType(
              that,
              result,
              that.TEMPLATE3_ttcTypeRule,
              TEMPLATE3_tiantiancaiColorRule,
              that.ttcNumber
            );
          } else {
            this.type = this.createType(
              that,
              result,
              that.ttcTypeRule,
              tiantiancaiColorRule,
              that.ttcNumber
            );
          }
          break;
        case "fc3d":
          if (this._TEMPLATE === "template-3") {
            this.type = this.createType(
              that,
              result,
              that.TEMPLATE3_fc3dTypeRule,
              undefined,
              that.fc3dNumber
            );
          } else {
            this.type = this.createType(
              that,
              result,
              that.fc3dTypeRule,
              undefined,
              that.fc3dNumber
            );
          }
          break;
        case "fastthreeone":
        case "fastthreethree":
        case "fastthreefive":
        case "fastthreeten":
        case "shfastthree":
        case "jsfastthree":
        case "hbfastthree":
          this.type = this.createType(that, result, that.kuai3TypeRule);
          break;
        case "pc28one":
        case "bj28":
        case "jnd28":
        case "twbg28":
        case "mgelg28":
        case "jndx28":
        case "snfk28":
          if (this._TEMPLATE === "template-3") {
            this.type = this.createType(
              that,
              result,
              that.TEMPLATE3_pc28TypeRule,
              that.pc28ColorRule
            );
          } else {
            this.type = this.createType(
              that,
              result,
              that.pc28TypeRule,
              that.pc28ColorRule
            );
          }
          break;
        case "cqssc":
        case "hljssc":
        case "tjssc":
        case "sscone":
        case "sscthree":
        case "sscfive":
        case "azxy5":
          if (this._TEMPLATE === "template-3") {
            this.type = this.createType(
              that,
              result,
              that.dtClassicTypeRule,
              TEMPLATE3_defaultColorRule,
              that.dtClassicNumber
            );
          } else {
            this.type = this.createType(
              that,
              result,
              that.dtClassicTypeRule,
              undefined,
              that.dtClassicNumber
            );
          }
          break;
        case "tencent":
          this.type = this.createType(
            that,
            result,
            that.tencentTypeRule,
            undefined
          );
          break;
        case "gdfiveselect":
        case "sdfiveselect":
        case "jsfiveselect":
        case "jxfiveselect":
        case "shhfiveselect":
        case "anhfiveselect":
        case "shxfiveselect":
          if (this._TEMPLATE === "template-3") {
            this.type = this.createType(
              that,
              result,
              that.TEMPLATE3_gdfiveselectTypeRule,
              TEMPLATE3_gdfiveselectColorRule,
              that.gdfiveselectNumber
            );
          } else {
            this.type = this.createType(
              that,
              result,
              that.gdfiveselectTypeRule,
              gdfiveselectColorRule,
              that.gdfiveselectNumber
            );
          }
          break;
        case "gxklsf":
          if (this._TEMPLATE === "template-3") {
            this.type = this.createType(
              that,
              result,
              that.gxklsfTypeRule,
              TEMPLATE3_gxklsfColorRule,
              that.dtClassicNumber
            );
          } else {
            this.type = this.createType(
              that,
              result,
              that.gxklsfTypeRule,
              that.gxklsfColorRule,
              that.dtClassicNumber
            );
          }
          break;
        case "gdklten":
          if (this._TEMPLATE === "template-3") {
            this.type = this.createType(
              that,
              result,
              that.TEMPLATE3_gdkltenTypeRule
            );
          } else {
            this.type = this.createType(
              that,
              result,
              that.gdkltenTypeRule,
              that.gdkltenColorRule
            );
          }
          break;
        case "bjsc":
        case "scone":
        case "scthree":
        case "scfive":
        case "xyft":
        case "azxy10":
          if (this._TEMPLATE === "template-3") {
            this.type = this.createType(
              that,
              result,
              that.pk10TypeRule,
              pk10ColorRule,
              that.pk10Number
            );
          } else {
            this.type = this.createType(
              that,
              result,
              that.pk10TypeRule,
              undefined,
              that.pk10Number
            );
          }
          break;
        case "lhc":
        case "lhcaomen":
        case "lhcone":
        case "lhcthree":
        case "lhcfive":
        case "lhcten":
          getAnimals().then((_result) => {
            if (_result.data.code === "SUCCESS") {
              var a = [];
              a[0] = _result.data.result.SHU;
              a[0].id = "SHU";
              a[1] = _result.data.result.NIU;
              a[1].id = "NIU";
              a[2] = _result.data.result.HU;
              a[2].id = "HU";
              a[3] = _result.data.result.TU;
              a[3].id = "TU";
              a[4] = _result.data.result.LONG;
              a[4].id = "LONG";
              a[5] = _result.data.result.SHE;
              a[5].id = "SHE";
              a[6] = _result.data.result.MA;
              a[6].id = "MA";
              a[7] = _result.data.result.YANG;
              a[7].id = "YANG";
              a[8] = _result.data.result.HOU;
              a[8].id = "HOU";
              a[9] = _result.data.result.JI;
              a[9].id = "JI";
              a[10] = _result.data.result.GOU;
              a[10].id = "GOU";
              a[11] = _result.data.result.ZHU;
              a[11].id = "ZHU";
              this.lhcAnimalRule = a;

              if (this._TEMPLATE === "template-3") {
                this.type = this.createType(
                  that,
                  result,
                  that.TEMPLATE3_lhcTypeRule,
                  that.lhcColorRule,
                  undefined,
                  that.lhcAnimalRule
                );
              } else {
                this.type = this.createType(
                  that,
                  result,
                  that.lhcTypeRule,
                  that.lhcColorRule,
                  undefined,
                  that.lhcAnimalRule
                );
              }
            }
          });
          break;
        default:
          break;
      }

      function render(data) {
        var classRule = null;

        if (
          that.gameType.indexOf("ssc") > -1 ||
          that.gameType === "jndxy" ||
          that.gameType === "azxy5"
        ) {
          classRule = that.dtClassicClassRule;
        } else if (that.gameType.indexOf("lhc") === 0) {
          classRule =
            that._TEMPLATE === "template-3"
              ? that.TEMPLATE3_lhcClassRule
              : that.lhcClassRule;
        } else if (that.gameType === "tencent") {
          classRule = that.tencentClassRule;
        } else if (that.gameType.indexOf("fiveselect") > -1) {
          classRule =
            that._TEMPLATE === "template-3"
              ? that.TEMPLATE3_gdfiveselectClassRule
              : that.gdfiveselectClassRule;
        } else if (that.gameType === "fc3d") {
          classRule =
            that._TEMPLATE === "template-3"
              ? that.TEMPLATE3_fc3dClassRule
              : that.fc3dClassRule;
        } else if (that.gameType === "gxklsf") {
          classRule = that.gxklsfClassRule;
        } else if (that.gameType === "gdklten") {
          classRule =
            that._TEMPLATE === "template-3"
              ? that.TEMPLATE3_gdkltenClassRule
              : that.gdkltenClassRule;
        } else if (that.gameType === "cqklten") {
          classRule =
            that._TEMPLATE === "template-3"
              ? that.TEMPLATE3_xyncClassRule
              : that.xyncClassRule;
        } else if (that.gameType === "tiantiancai") {
          classRule =
            that._TEMPLATE === "template-3"
              ? that.TEMPLATE3_tiantiancaiClassRule
              : that.tiantiancaiClassRule;
        } else {
          classRule = that.pk10ClassRule;
        }

        that.type
          .reduce((acc, value) => acc.concat(value), [])
          .forEach((item) => {
            item.status = 1;
          });
        function filterClass(_class) {
          that.type.forEach((item, index) => {
            item.forEach((item, index) => {
              if (item.class.includes(_class) !== true) {
                item.status = 0;
              }
            });
          });
        }
        classRule.forEach((item) => {
          if (data.id === item.id) {
            filterClass(item.id);
          }
        });
      }

      this.$root.$on("titleMenuSwitcher", (data) => {
        switch (data.type) {
          case "cqssc":
          case "hljssc":
          case "tjssc":
          case "sscone":
          case "sscthree":
          case "sscfive":
          case "bjsc":
          case "scone":
          case "scthree":
          case "scfive":
          case "xyft":
          case "lhc":
          case "lhcaomen":
          case "lhcone":
          case "lhcthree":
          case "lhcfive":
          case "lhcten":
          case "tencent":
          case "gdfiveselect":
          case "sdfiveselect":
          case "jsfiveselect":
          case "jxfiveselect":
          case "shhfiveselect":
          case "anhfiveselect":
          case "shxfiveselect":
          case "fc3d":
          case "gxklsf":
          case "gdklten":
          case "jndxy":
          case "azxy5":
          case "azxy10":
          case "cqklten":
          case "wanneng":
          case "damacai":
          case "duoduo":
          case "yuedawan":
          case "shaba88":
          case "saimahui":
          case "xinjapowz":
          case "tiantiancai":
            render(data);
            this.reset(false);
            this.setDefault(data);
            break;
          default:
            break;
        }
      });

      if (this.game.info.currentAuctioneerUIClass) {
        render(this.game.info.currentAuctioneerUIClass);
        this.titleMenuSwitcher(this.game.info.currentAuctioneerUIClass);
      } else if (this.titleMenuPayload) {
        render(this.titleMenuPayload);
      }

      this.$root.$on("headerMenuProcessor", (data) => {
        this.openModal({
          name: data.eventData.name,
          type: data.eventData.gameType,
          api: data.eventData.request,
        });
      });
    });

    //create coins
    that.coinsType.forEach((item) => {
      this.coins.push({
        value: item,
        select: false,
        active: false,
      });
    });

    var _baseurl = this.cloudImageBaseUrl_coins + this._TEMPLATE + "/coins/";
    that.coins.forEach((item) => {
      item.src = _baseurl + item.value + ".png";
    });

    if (localStorage.getItem("coinsInit")) {
      that.coinsInit = localStorage.getItem("coinsInit").split(",");
      this.originalUserCoinsInit = localStorage.getItem("coinsInit").split(",");
    } else {
      this.originalUserCoinsInit = this.coinsInit;
      window.localStorage.setItem("coinsInit", this.coinsInit.join());
    }
    if (this._TEMPLATE === "template-1") {
      that.TEMPLATE1coinsInit.forEach((item) => {
        var index = that.coins.findIndex((data) => data.value === item);
        that.coins[index].select = true;
      });
    }
     if ( this._TEMPLATE === "template-2") {
      that.TEMPLATE2coinsInit.forEach((item) => {
        var index = that.coins.findIndex((data) => data.value === item);
        that.coins[index].select = true;
      });
    }
  },
  updated() {},
  beforeDestroy() {
    // this.$root.$off('titleMenuSwitcher');
    this.$root.$off("headerMenuProcessor");
  },
  watch: {
    amount: function (_new, _old) {
      this.choose_num({ select: false });
    },
  },
  computed: {
    selectAry() {
      let selectAry = [];
      selectAry = this.type
        .reduce((acc, value) => acc.concat(value), [])
        .filter(function (item) {
          if (item.select === true) {
            return item;
          }
        });
      return selectAry;
    },
    userBalance() {
      return (
        this.$store.state.userBalance &&
        this.$store.state.userBalance["user_money"]
      );
    },
    calculatedPermutationList() {
      if (
        this.wannengNum1 &&
        this.wannengNum2 &&
        this.wannengNum3 &&
        this.wannengNum4
      ) {
        var result = this.permutationList([
          this.wannengNum1,
          this.wannengNum2,
          this.wannengNum3,
          this.wannengNum4,
        ]);
        result.forEach((item, index) => {
          result[index] = item.join("");
        });
        var filteredResult = new Set(result);
        return filteredResult;
      }
    },
    generatedSequenceList() {
      var num1 = this.wannengNum1 ? 1 : 0;
      var num2 = this.wannengNum2 ? 1 : 0;
      var num3 = this.wannengNum3 ? 1 : 0;
      var num4 = this.wannengNum4 ? 1 : 0;
      var class2Number = num1 + num2 + num3 + num4;
      var result = [];

      if (class2Number === 3) {
        for (var i = 0; i < 10; i++) {
          result.push([
            num1 ? this.wannengNum1 : i,
            num2 ? this.wannengNum2 : i,
            num3 ? this.wannengNum3 : i,
            num4 ? this.wannengNum4 : i,
          ]);
        }
      }
      result.forEach((item, index) => {
        result[index] = item.join("");
      });
      return result;
    },
    language() {
      return window.language;
    },
  },
};
</script>
<template>
  <div
    id="auctioneer-ui"
    :class="[_TEMPLATE,{coverd:headerMenuDockerOpen}]"
    @click="restore"
  >
    <i
      class="iconfont icon-23"
      @click="close_show()"
      v-if="!checkRoomType() && _TEMPLATE!=='template-3'"
    >
      <span v-if="_TEMPLATE!=='template-3'">{{ getWord('auctioneer_ui') }}</span>
    </i>
    <div
      id="bet-types-wrapper"
      ref="betTypesWrapper"
      v-if="gameType==='wanneng'||gameType==='damacai'||gameType==='duoduo'||gameType==='yuedawan'||gameType==='shaba88'||gameType==='saimahui'||gameType==='xinjapowz'"
      class="wanneng"
    >
      <template v-if="game.info.currentAuctioneerUIClass.id!=='2'">
        <div class="level-1">
          <h4>{{ getWord('wzwn_tips5') }}</h4>
          <div class="numbers">
            <input
              type="text"
              pattern="\d?"
              class="num"
              placeholder="_"
              maxlength="1"
              v-model="wannengNum1"
              ref="num1"
              @keyup="enter($event,1)"
            />
            <input
              type="text"
              pattern="\d?"
              class="num"
              placeholder="_"
              maxlength="1"
              v-model="wannengNum2"
              ref="num2"
              @keyup="enter($event,2)"
            />
            <input
              type="text"
              pattern="\d?"
              class="num"
              placeholder="_"
              maxlength="1"
              v-model="wannengNum3"
              ref="num3"
              @keyup="enter($event,3)"
            />
            <input
              type="text"
              pattern="\d?"
              class="num"
              placeholder="_"
              maxlength="1"
              v-model="wannengNum4"
              ref="num4"
              @keyup="enter($event,4)"
            />
          </div>
        </div>
      </template>
      <template v-if="game.info.currentAuctioneerUIClass.id==='2'">
        <div class="level-1">
          <h4>{{ getWord('wzwn_tips4') }}</h4>
          <div class="numbers">
            <input
              type="text"
              pattern="\d?"
              class="num"
              placeholder="R"
              maxlength="1"
              v-model="wannengNum1"
            />
            <input
              type="text"
              pattern="\d?"
              class="num"
              placeholder="R"
              maxlength="1"
              v-model="wannengNum2"
            />
            <input
              type="text"
              pattern="\d?"
              class="num"
              placeholder="R"
              maxlength="1"
              v-model="wannengNum3"
            />
            <input
              type="text"
              pattern="\d?"
              class="num"
              placeholder="R"
              maxlength="1"
              v-model="wannengNum4"
            />
          </div>
        </div>
      </template>
      <template v-if="game.info.currentAuctioneerUIClass.id==='2'">
        <div class="list-wrapper">
          <ul class="list">
            <li v-for="item in generatedSequenceList">{{ item }}</li>
          </ul>
        </div>
      </template>
      <template v-if="game.info.currentAuctioneerUIClass.id==='3' || game.info.currentAuctioneerUIClass.id==='4'">
        <div class="list-wrapper">
          <ul class="list">
            <li v-for="item in calculatedPermutationList">{{ item }}</li>
          </ul>
        </div>
      </template>
      <div class="level-2">
        <h4>{{ getWord('wzwn_tips3') }}
          <span>（{{ getWord('wzwn_tips2',{
                        '$1':'1'
                    }) }}）</span>
        </h4>
        <div
          class="numbers"
          :class="{ 'class4':game.info.currentAuctioneerUIClass.id==='4' }"
        >
          <label>
            {{ getWord('dawan') }}
            <input
              type="text"
              pattern="\d*"
              class="num"
              placeholder="_"
              maxlength="2"
              v-model="wannengKey1.value"
              alias="BIG"
            />
          </label>
          <label>
            {{ getWord('xiaowan') }}
            <input
              type="text"
              pattern="\d*"
              class="num"
              placeholder="_"
              maxlength="2"
              v-model="wannengKey2.value"
              alias="SMALL"
            />
          </label>
          <template v-if="game.info.currentAuctioneerUIClass.id!=='4'">
            <label>
              SA<span class="hidden">正</span>
              <input
                type="text"
                pattern="\d*"
                class="num"
                placeholder="_"
                maxlength="2"
                v-model="wannengKey3.value"
                alias="SA"
              />
            </label>
            <label>
              3A<span class="hidden">正</span>
              <input
                type="text"
                pattern="\d*"
                class="num"
                placeholder="_"
                maxlength="2"
                v-model="wannengKey4.value"
                alias="3A"
              />
            </label>
            <label>
              3C<span class="hidden">正</span>
              <input
                type="text"
                pattern="\d*"
                class="num"
                placeholder="_"
                maxlength="2"
                v-model="wannengKey5.value"
                alias="3C"
              />
            </label>
          </template>
        </div>
      </div>
      <div
        class="level-3"
        v-if="currentWannengOdds && game.info.currentAuctioneerUIClass.id!=='4'"
      >
        <div class="inner">
          <h4>{{ getWord('wzwn_tips1') }}</h4>
          <div class="tr">
            <span class="th">{{ getWord('ranking') }}</span><span class="th">{{ getWord('dawan') }}</span><span class="th">{{ getWord('xiaowan') }}</span><span class="th">SA</span><span class="th">3A</span><span class="th">3C</span>
          </div>
          <div class="tr">
            <span class="td">{{ getWord('shoujiang') }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_1'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey2.alias+'_1'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey3.alias+'_1'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey4.alias+'_1'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey5.alias+'_1'].odds) }}</span>
          </div>
          <div class="tr">
            <span class="td">{{ getWord('erjiang') }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_2'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey2.alias+'_2'].odds) }}</span>
            <span class="td"></span>
            <span class="td"></span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey5.alias+'_2'].odds) }}</span>
          </div>
          <div class="tr">
            <span class="td">{{ getWord('sanjiang') }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_3'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey2.alias+'_3'].odds) }}</span>
            <span class="td"></span>
            <span class="td"></span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey5.alias+'_3'].odds) }}</span>
          </div>
          <div class="tr">
            <span class="td">{{ getWord('tebiejiang') }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_4'].odds) }}</span>
            <span class="td"></span>
            <span class="td"></span>
            <span class="td"></span>
            <span class="td"></span>
          </div>
          <div class="tr">
            <span class="td">{{ getWord('anweijiang') }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_5'].odds) }}</span>
            <span class="td"></span>
            <span class="td"></span>
            <span class="td"></span>
            <span class="td"></span>
          </div>
        </div>
      </div>
      <div
        class="level-3 wan"
        v-if="currentWannengOdds && game.info.currentAuctioneerUIClass.id==='4'"
      >
        <div class="inner">
          <h4>{{ getWord('wzwn_tips1') }}</h4>
          <div class="tr">
            <span class="th">{{ getWord('dawan') }}</span><span class="th">mbox24</span><span class="th">mbox12</span><span class="th">mbox6</span><span class="th">mbox4</span>
          </div>
          <div class="tr">
            <span class="td">{{ getWord('shoujiang') }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M24_1'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M12_1'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M6_1'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M4_1'].odds) }}</span>
          </div>
          <div class="tr">
            <span class="td">{{ getWord('erjiang') }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M24_2'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M12_2'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M6_2'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M4_2'].odds) }}</span>
          </div>
          <div class="tr">
            <span class="td">{{ getWord('sanjiang') }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M24_3'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M12_3'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M6_3'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M4_3'].odds) }}</span>
          </div>
          <div class="tr">
            <span class="td">{{ getWord('tebiejiang') }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M24_4'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M12_4'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M6_4'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M4_4'].odds) }}</span>
          </div>
          <div class="tr">
            <span class="td">{{ getWord('anweijiang') }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M24_5'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M12_5'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M6_5'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey1.alias+'_M4_5'].odds) }}</span>
          </div>

          <div class="tr">
            <span class="th">{{ getWord('xiaowan') }}</span><span class="th">mbox24</span><span class="th">mbox12</span><span class="th">mbox6</span><span class="th">mbox4</span>
          </div>
          <div class="tr">
            <span class="td">{{ getWord('shoujiang') }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey2.alias+'_M24_1'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey2.alias+'_M12_1'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey2.alias+'_M6_1'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey2.alias+'_M4_1'].odds) }}</span>
          </div>
          <div class="tr">
            <span class="td">{{ getWord('erjiang') }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey2.alias+'_M24_2'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey2.alias+'_M12_2'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey2.alias+'_M6_2'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey2.alias+'_M4_2'].odds) }}</span>
          </div>
          <div class="tr">
            <span class="td">{{ getWord('sanjiang') }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey2.alias+'_M24_3'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey2.alias+'_M12_3'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey2.alias+'_M6_3'].odds) }}</span>
            <span class="td">{{ filterOdds(currentWannengOdds[game.info.currentAuctioneerUIClass.alias][game.info.currentAuctioneerUIClass.alias+'_'+wannengKey2.alias+'_M4_3'].odds) }}</span>
          </div>
        </div>
      </div>
      <div class="submit">
        <h6>
          <span>{{ getWord('order') }}: <b>{{ calcLength() }}</b>{{ getWord('item') }} </span>
          <span>{{ getWord('total4') }}: <b>{{ Number(this.calcAmount()).toFixed(2) }}</b>元</span>
        </h6>
        <button @click="order2()">{{ getWord('confirm4') }}</button>
      </div>
    </div>
    <div
      id="bet-types-wrapper"
      ref="betTypesWrapper"
      v-if="gameType==='cqklten'"
      class="xync"
    >
      <div
        class="custom-type-odd"
        v-if="_TEMPLATE==='template-3'&&(game.info.currentAuctioneerUIClass.classTypeId==='5')"
      >
        <h5>{{cqkltenData.class4.current}}</h5>
        <h6>{{ getWord('odds_of_item') }}：<span>{{cqkltenData.combinationOdds}}</span></h6>
      </div>
      <div
        class="custom-header"
        v-if="_TEMPLATE==='template-3' && game.info.currentAuctioneerUIClass.classTypeId==='5'"
      >
        <b>{{ getWord('number_of_item') }}</b>
        <span>{{ getWord('checked2') }}</span>
        <b>{{ getWord('number_of_item') }}</b>
        <span>{{ getWord('checked2') }}</span>
      </div>
      <template v-if="(_TEMPLATE==='template-1' || _TEMPLATE==='template-2')">
        <ul
          class="clearfix bet-types dtclassic"
          :title="item"
          v-for="(item,rootIndex) in xyncTypeTitle"
          v-if="item!==getWord('lianma')"
        >
          <li
            v-for="(item, index) in type[rootIndex]"
            v-if="item.status"
            @click="choose_num(item)"
            :class="{ highlight:item.select }"
          >
            <b
              class="title"
              :style="{ color:item.color }"
              v-if="isNaN(item.title)"
              :class="{small:item.title.length>2}"
            >{{item.title}}</b>
            <b v-else>
              <img :src="require('@@/assets/'+_TEMPLATE+'/images/tradingfloor/icons/cqklten/'+item.title+'.png')" />
            </b>
            <span>{{item.odds}}</span>
          </li>
        </ul>
      </template>
      <template v-if="_TEMPLATE==='template-3'">
        <ul
          class="clearfix bet-types dtclassic"
          :title="item"
          v-for="(item,rootIndex) in TEMPLATE3_xyncTypeTitle"
          v-if="item!==getWord(['renxuan', 'er'])&&item!==getWord('xuanerlianzu')&&item!==getWord(['renxuan', 'san'])&&item!==getWord('xuansanqianzu')&&item!==getWord(['renxuan', 'si'])&&item!==getWord(['renxuan', 'wu'])"
        >
          <li
            v-for="(item, index) in type[rootIndex]"
            v-if="item.status"
            @click="choose_num(item)"
            :class="{ highlight:item.select, number:!isNaN(item.title) }"
          >
            <div class="title-wrap">
              <b
                class="title"
                :style="{ '--data-color':item.color }"
                v-if="isNaN(item.title)"
              >
                {{item.title}}
              </b>
              <b v-else>
                <img :src="require('@@/assets/'+_TEMPLATE+'/images/tradingfloor/icons/cqklten/'+item.title+'.png')" />
              </b>
            </div>
            <span>{{item.odds}}</span>
          </li>
        </ul>
      </template>
      <template v-if="game.info.currentAuctioneerUIClass.classTypeId==='5'">
        <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
          <template v-if="_TEMPLATE!=='template-2'">
            <h5 @click='cqkltenData.class4Visible=!cqkltenData.class4Visible'>{{ getWord('category') }}<span>{{cqkltenData.class4.current}}</span></h5>
            <h5>{{ getWord('odds_of_item') }}<span>{{cqkltenData.combinationOdds}}</span></h5>
          </template>
          <template v-else>
            <div class="zhong type2 variant">
              <div class="top">
                <h6>{{ getWord('category') }}</h6>
                <label
                  v-for="(item,index) in cqkltenData.class4.actions"
                  @click="item.method.call(this)"
                  :class="{highlight:cqkltenData.class4.current===item.id}"
                >
                  <span class="radio"></span>
                  <b>{{item.name}}</b>
                </label>
                <label>
                  <span class="radio hidden"></span>
                  <b class="restore">{{ getWord('odds_of_item') }}</b>
                  <i>{{cqkltenData.combinationOdds}}</i>
                </label>
              </div>
            </div>
          </template>
          <ul class="clearfix bet-types dtclassic lhcnumberlist">
            <li
              v-for="(item, index) in gdkltenNumberList"
              @click="choose_num(item)"
              :class="{ highlight:item.select }"
              :style="{ '--data-bg-color':item.color }"
            >
              <b
                class="title"
                :style="{ color:item.color }"
              >
                <img :src="require('@@/assets/'+_TEMPLATE+'/images/tradingfloor/icons/cqklten/'+item.title+'.png')" />
              </b>
            </li>
          </ul>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
          <ul class="clearfix bet-types dtclassic numberlist">
            <li
              v-for="(item, index) in gdkltenNumberList"
              @click="choose_num(item)"
              :class="{ highlight:item.select, number:!isNaN(item.title) }"
              :style="{ '--data-bg-color':item.color }"
            >
              <div class="title-wrap">
                <b>
                  <img :src="require('@@/assets/'+_TEMPLATE+'/images/tradingfloor/icons/cqklten/'+item.title+'.png')" />
                </b>
              </div>
              <strong><i class="iconfont icon-correct"></i></strong>
            </li>
          </ul>
        </template>
      </template>
    </div>
    <div
      id="bet-types-wrapper"
      ref="betTypesWrapper"
      v-if="gameType.indexOf('ssc')>-1 || gameType==='jndxy' || gameType==='azxy5'"
    >
      <template v-if="_TEMPLATE!=='template-3'">
        <ul
          class="clearfix bet-types dtclassic"
          :title="item"
          v-for="(item,rootIndex) in dtClassicTypeTitle"
        >
          <li
            v-for="(item, index) in type[rootIndex]"
            v-if="item.status"
            @click="choose_num(item)"
            :class="{ highlight:item.select }"
          >
            <b
              class="title"
              :style="{ color:item.color }"
            >{{item.title}}</b>
            <span>{{item.odds}}</span>
          </li>
        </ul>
      </template>
      <template v-else>
        <ul
          class="clearfix bet-types dtclassic"
          :title="item"
          v-for="(item,rootIndex) in dtClassicTypeTitle"
        >
          <li
            v-for="(item, index) in type[rootIndex]"
            v-if="item.status"
            @click="choose_num(item)"
            :class="{ highlight:item.select, number:!isNaN(item.title) }"
          >
            <div class="title-wrap">
              <b
                class="title"
                :style="{ '--data-color':item.color }"
              >{{item.title}}</b>
            </div>
            <span>{{item.odds}}</span>
          </li>
        </ul>
      </template>
    </div>
    <div
      id="bet-types-wrapper"
      ref="betTypesWrapper"
      v-if="gameType==='tiantiancai'"
      :current-class='game.info.currentAuctioneerUIClass.class'
      class="ttc"
    >
      <div
        class="custom-type-odd"
        v-if="_TEMPLATE==='template-3'&&game.info.currentAuctioneerUIClass.classTypeId==='2'"
      >
        <h5>{{selectAry[0].originalTitle}}</h5>
        <h6>{{ getWord('odds_of_item') }}：<span>{{selectAry[0].odds}}</span></h6>
      </div>
      <div
        class="custom-header"
        v-if="_TEMPLATE==='template-3' && game.info.currentAuctioneerUIClass.classTypeId==='2'"
      >
        <b>{{ getWord('number_of_item') }}</b>
        <span>{{ getWord('checked2') }}</span>
        <b>{{ getWord('number_of_item') }}</b>
        <span>{{ getWord('checked2') }}</span>
      </div>
      <template v-if="game.info.currentAuctioneerUIClass.classTypeId==='1'&&_TEMPLATE!=='template-3'">
        <div class="zhong type2 variant">
          <div class="top">
            <h6>{{ getWord('category') }}</h6>
            <label
              v-for="(item,index) in ttcData.class1.actions"
              @click="item.method.call(this)"
              :class="{ 'highlight':ttcData.class1.currentId===item.id }"
            >
              <span class="radio"></span>
              <b>{{item.name}}</b>
            </label>
          </div>
        </div>
      </template>
      <template
        v-for="(item,rootIndex) in ttcTypeTitle"
        v-if="checkClass(item.class)"
      >
        <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
          <ul
            class="clearfix bet-types dtclassic"
            :title="item.title"
            :rootIndex="rootIndex"
            v-if="ttcData.class1.currentId==rootIndex"
          >
            <li
              v-for="(item, index) in type[rootIndex]"
              v-if="item.status"
              @click="choose_num(item)"
              :class="{ highlight:item.select }"
            >
              <b
                class="title"
                :style="{ color:item.color }"
                :class="{small:item.title&&item.title.length>2}"
              >{{item.title}}</b>
              <span>{{item.odds}}</span>
            </li>
          </ul>
        </template>
      </template>
      <template
        v-for="(item,rootIndex) in TEMPLATE3_ttcTypeTitle"
        v-if="checkClass(item.class) && game.info.currentAuctioneerUIClass.classTypeId!=='2'"
      >
        <template v-if="_TEMPLATE==='template-3'">
          <ul
            class="clearfix bet-types dtclassic"
            :title="item.title"
          >
            <li
              v-for="(item, index) in type[rootIndex]"
              v-if="item.status"
              @click="choose_num(item)"
              :class="{ highlight:item.select, number:!isNaN(item.title) }"
            >
              <div class="title-wrap">
                <b
                  class="title"
                  :style="{ '--data-color':item.color }"
                >{{item.title}}</b>
              </div>
              <span>{{item.odds}}</span>
            </li>
          </ul>
        </template>
      </template>
      <template v-if="game.info.currentAuctioneerUIClass.classTypeId==='2'">
        <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
          <template v-if="_TEMPLATE==='template-1'">
            <h5 @click='gdkltenData.class12Visible=!gdkltenData.class12Visible'>{{ getWord('category') }}<span>{{gdkltenData.class12.current}}</span></h5>
            <h5>{{ getWord('odds_of_item') }}<span>{{gdkltenData.combinationOdds}}</span></h5>
          </template>
          <template v-else>
            <div class="zhong type2 variant">
              <div class="top">
                <h6>{{ getWord('category') }}</h6>
                <label
                  v-for="(item,index) in ttcData.class2.actions"
                  @click="item.method.call(this)"
                  :class="{ 'highlight':ttcData.class2.currentId===item.id}"
                >
                  <span class="radio"></span>
                  <b>{{item.name}}</b>
                </label>
                <label>
                  <span class="radio hidden"></span>
                  <b class="restore">{{ getWord('odds_of_item') }}</b>
                  <i>{{ttcData.combinationOdds}}</i>
                </label>
              </div>
            </div>
          </template>
          <ul class="clearfix bet-types dtclassic lhcnumberlist">
            <li
              v-for="(item, index) in ttcNumberList"
              @click="choose_num(item)"
              :class="{ 'highlight':item.select }"
              :style="{ '--data-bg-color':item.color }"
            >
              <b
                class="title"
                :style="{ 'color':item.color }"
              >{{item.title}}</b>
            </li>
          </ul>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
          <ul class="clearfix bet-types dtclassic numberlist">
            <li
              v-for="(item, index) in ttcNumberList"
              @click="choose_num(item)"
              :class="{ 'highlight':item.select, 'number':!isNaN(item.title) }"
              :style="{ '--data-bg-color':item.color }"
            >
              <div class="title-wrap">
                <b
                  class="title"
                  :style="{ '--data-color':item.color }"
                >{{item.title}}</b>
              </div>
              <strong><i class="iconfont icon-correct"></i></strong>
            </li>
          </ul>
        </template>
      </template>
    </div>
    <div
      id="bet-types-wrapper"
      ref="betTypesWrapper"
      v-if="gameType==='gdklten'"
      :current-class='game.info.currentAuctioneerUIClass.class'
      class="klten"
    >
      <div
        class="custom-type-odd"
        v-if="_TEMPLATE==='template-3'&&(game.info.currentAuctioneerUIClass.classTypeId==='5')"
      >
        <h5>{{gdkltenData.class12.current}}</h5>
        <h6>{{ getWord('odds_of_item') }}：<span>{{gdkltenData.combinationOdds}}</span></h6>
      </div>
      <div
        class="custom-header"
        v-if="_TEMPLATE==='template-3' && game.info.currentAuctioneerUIClass.classTypeId==='5'"
      >
        <b>{{ getWord('number_of_item') }}</b>
        <span>{{ getWord('checked2') }}</span>
        <b>{{ getWord('number_of_item') }}</b>
        <span>{{ getWord('checked2') }}</span>
      </div>
      <template
        v-for="(item,rootIndex) in gdkltenTypeTitle"
        v-if="checkClass(item.class) && game.info.currentAuctioneerUIClass.classTypeId!=='5'"
      >
        <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
          <ul
            class="clearfix bet-types dtclassic"
            :title="item.title"
            :rootIndex="rootIndex"
          >
            <li
              v-for="(item, index) in type[rootIndex]"
              v-if="item.status"
              @click="choose_num(item)"
              :class="{ highlight:item.select }"
            >
              <b
                class="title"
                :style="{ color:item.color }"
                :class="{small:item.title&&item.title.length>2}"
              >{{item.title}}</b>
              <span>{{item.odds}}</span>
            </li>
          </ul>
        </template>
      </template>
      <template
        v-for="(item,rootIndex) in TEMPLATE3_gdkltenTypeTitle"
        v-if="checkClass(item.class) && game.info.currentAuctioneerUIClass.classTypeId!=='5'"
      >
        <template v-if="_TEMPLATE==='template-3'">
          <ul
            class="clearfix bet-types dtclassic"
            :title="item.title"
          >
            <li
              v-for="(item, index) in type[rootIndex]"
              v-if="item.status"
              @click="choose_num(item)"
              :class="{ highlight:item.select, number:!isNaN(item.title) }"
            >
              <div class="title-wrap">
                <b
                  class="title"
                  :style="{ '--data-color':item.color }"
                >{{item.title}}</b>
              </div>
              <span>{{item.odds}}</span>
            </li>
          </ul>
        </template>
      </template>
      <template v-if="game.info.currentAuctioneerUIClass.classTypeId==='5'">
        <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
          <template v-if="_TEMPLATE!=='template-2'">
            <h5 @click='gdkltenData.class12Visible=!gdkltenData.class12Visible'>{{ getWord('category') }}<span>{{gdkltenData.class12.current}}</span></h5>
            <h5>{{ getWord('odds_of_item') }}<span>{{gdkltenData.combinationOdds}}</span></h5>
          </template>
          <template v-else>
            <div class="zhong type2 variant">
              <div class="top">
                <h6>{{ getWord('category') }}</h6>
                <label
                  v-for="(item,index) in gdkltenData.class12.actions"
                  @click="item.method.call(this)"
                  :class="{highlight:gdkltenData.class12.currentId===item.id}"
                >
                  <span class="radio"></span>
                  <b>{{item.name}}</b>
                </label>
                <label>
                  <span class="radio hidden"></span>
                  <b class="restore">{{ getWord('odds_of_item') }}</b>
                  <i>{{gdkltenData.combinationOdds}}</i>
                </label>
              </div>
            </div>
          </template>
          <ul class="clearfix bet-types dtclassic lhcnumberlist">
            <li
              v-for="(item, index) in gdkltenNumberList"
              @click="choose_num(item)"
              :class="{ highlight:item.select }"
              :style="{ '--data-bg-color':item.color }"
            >
              <b
                class="title"
                :style="{ color:item.color }"
              >{{item.title}}</b>
            </li>
          </ul>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
          <ul class="clearfix bet-types dtclassic numberlist">
            <li
              v-for="(item, index) in gdkltenNumberList"
              @click="choose_num(item)"
              :class="{ highlight:item.select, number:!isNaN(item.title) }"
              :style="{ '--data-bg-color':item.color }"
            >
              <div class="title-wrap">
                <b
                  class="title"
                  :style="{ '--data-color':item.color }"
                >{{item.title}}</b>
              </div>
              <strong><i class="iconfont icon-correct"></i></strong>
            </li>
          </ul>
        </template>
      </template>
    </div>
    <div
      id="bet-types-wrapper"
      ref="betTypesWrapper"
      v-if="gameType.indexOf('fiveselect')>-1"
      class="gdfiveselect"
    >
      <div
        class="custom-type-odd"
        v-if="_TEMPLATE==='template-3'&&(game.info.currentAuctioneerUIClass.classTypeId==='3')"
      >
        <template v-if="game.info.currentAuctioneerUIClass.classTypeId==='3'">
          <h5>{{gdfiveselectData.class3.current.replace('连码','')}}</h5>
          <h6>{{ getWord('odds_of_item') }}：<span>{{gdfiveselectData.combinationOdds}}</span></h6>
        </template>
      </div>
      <div
        class="custom-header"
        v-if="_TEMPLATE==='template-3' && game.info.currentAuctioneerUIClass.classTypeId==='3'"
      >
        <template>
          <template v-if="game.info.currentAuctioneerUIClass.classTypeId==='3'">
            <b>{{ getWord('number_of_item') }}</b>
            <span>{{ getWord('checked2') }}</span>
            <b>{{ getWord('number_of_item') }}</b>
            <span>{{ getWord('checked2') }}</span>
          </template>
        </template>
      </div>
      <template v-if="game.info.currentAuctioneerUIClass.classTypeId!=='3' && game.info.currentAuctioneerUIClass.classTypeId!=='4'">
        <template v-if="_TEMPLATE!=='template-3'">
          <ul
            class="clearfix bet-types dtclassic"
            :title="item"
            v-for="(item,rootIndex) in gdfiveselectTypeTitle"
          >
            <li
              v-for="(item, index) in type[rootIndex]"
              v-if="item.status"
              @click="choose_num(item)"
              :class="{ highlight:item.select }"
            >
              <b
                class="title"
                :style="{ color:item.color }"
                :class="{small:item.title.length>2}"
              >{{item.title}}</b>
              <span>{{item.odds}}</span>
            </li>
          </ul>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
          <ul
            class="clearfix bet-types dtclassic"
            :title="item"
            v-for="(item,rootIndex) in TEMPLATE3_gdfiveselectTypeTitle"
          >
            <li
              v-for="(item, index) in type[rootIndex]"
              v-if="item.status"
              @click="choose_num(item)"
              :class="{ highlight:item.select, number:!isNaN(item.title) }"
            >
              <div class="title-wrap">
                <b
                  class="title"
                  :style="{ '--data-color':item.color }"
                >{{item.title}}</b>
              </div>
              <span>{{item.odds}}</span>
            </li>
          </ul>
        </template>
      </template>
      <template v-if="game.info.currentAuctioneerUIClass.classTypeId==='3'">
        <template v-if="_TEMPLATE==='template-1'">
          <h5 @click='gdfiveselectData.class3Visible=!gdfiveselectData.class3Visible'>{{ getWord('category') }}<span>{{gdfiveselectData.class3.current}}</span></h5>
          <h5>{{ getWord('odds_of_item') }}<span>{{gdfiveselectData.combinationOdds}}</span></h5>
        </template>
        <template v-if="_TEMPLATE==='template-2'">
          <div class="zhong type2">
            <div class="top">
              <h6>{{ getWord('category') }}</h6>
              <label
                v-for="(item,index) in gdfiveselectData.class3.actions"
                @click="item.method.call(this)"
                :class="{highlight:gdfiveselectData.class3.currentId===item.id}"
              ><span class="radio"></span><b>{{item.name}}</b></label>
            </div>
            <div class="bottom">
              <h6>{{ getWord('odds_of_item') }}</h6>
              <label>{{gdfiveselectData.combinationOdds}}</label>
            </div>
          </div>
        </template>
        <template v-if="_TEMPLATE!=='template-3'">
          <ul class="clearfix bet-types dtclassic lhcnumberlist">
            <li
              v-for="(item, index) in gdfiveselectNumberList"
              @click="choose_num(item)"
              :class="{ highlight:item.select,number:!isNaN(item.title) }"
              :style="{ '--data-bg-color':item.color }"
            >
              <b
                class="title"
                :style="{ color:item.color }"
              >{{item.title}}</b>
            </li>
          </ul>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
          <ul class="clearfix bet-types dtclassic gdfiveselectNumberList">
            <li
              v-for="(item, index) in gdfiveselectNumberList"
              @click="choose_num(item)"
              :class="{ highlight:item.select,number:!isNaN(item.title) }"
              :style="{ '--data-bg-color':item.color }"
            >
              <div class="title-wrap">
                <b
                  class="title"
                  :style="{ '--data-color':item.color }"
                >{{item.title}}</b>
              </div>
              <strong><i class="iconfont icon-correct"></i></strong>
            </li>
          </ul>
        </template>
      </template>
      <template v-if="game.info.currentAuctioneerUIClass.classTypeId==='4'">
        <template v-if="_TEMPLATE==='template-1'">
          <h5 @click='gdfiveselectData.class4Visible=!gdfiveselectData.class4Visible'>{{ getWord('category') }}<span>{{gdfiveselectData.class4.current}}</span></h5>
          <h5>{{ getWord('odds_of_item') }}<span>{{gdfiveselectData.combinationOdds}}</span></h5>
        </template>
        <template v-if="_TEMPLATE==='template-2'">
          <div class="zhong type2 variant">
            <div class="top">
              <h6>{{ getWord('category') }}</h6>
              <label
                v-for="(item,index) in gdfiveselectData.class4.actions"
                @click="item.method.call(this)"
                :class="{highlight:gdfiveselectData.class4.currentId===item.id}"
              >
                <span class="radio"></span>
                <b>{{item.name}}</b>
              </label>
              <label>
                <span class="radio hidden"></span>
                <b class="restore">{{ getWord('odds_of_item') }}</b>
                <i>{{gdfiveselectData.combinationOdds}}</i>
              </label>
            </div>
          </div>
        </template>
        <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
          <ul
            class="clearfix bet-types dtclassic lhcnumberlist gdfiveselectZhixuan"
            v-for="(_item,_index) in gdfiveselectZhixuanTitle[gdfiveselectData.class4.currentTypeTab]"
            :title="_item"
          >
            <li
              v-for="(item, index) in gdfiveselectZhixuanNumberList.slice( (gdfiveselectData.class4.currentTypeTab===3?_index+2:_index)*11, (gdfiveselectData.class4.currentTypeTab===3?_index+2:_index) *11+11)"
              :index="item.index"
              @click="choose_num(item,$event)"
              :title="'zhixuan-'+(index+1)"
              :class="{ highlight:item.select }"
              :style="{ '--data-bg-color':item.color }"
            >
              <b
                class="title"
                :style="{ color:item.color }"
              >{{item.title}}</b>
            </li>
          </ul>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
          <ul
            class="clearfix bet-types dtclassic gdfiveselectZhixuan"
            v-for="(_item,_index) in gdfiveselectZhixuanTitle[gdfiveselectData.class4.currentTypeTab]"
            :title="_item"
          >
            <li
              v-for="(item, index) in gdfiveselectZhixuanNumberList.slice( (gdfiveselectData.class4.currentTypeTab===3?_index+2:_index)*11, (gdfiveselectData.class4.currentTypeTab===3?_index+2:_index) *11+11)"
              :index="item.index"
              @click="choose_num(item,$event)"
              :title="'zhixuan-'+(index+1)"
              :class="{ highlight:item.select, disabled:item.disabled, number:!isNaN(item.title) }"
              :style="{ '--data-bg-color':item.color }"
            >
              <div class="title-wrap">
                <b
                  class="title"
                  :style="{ '--data-color':item.color }"
                >{{item.title}}</b>
              </div>
              <strong><i class="iconfont icon-correct"></i></strong>
            </li>
          </ul>
        </template>
      </template>
    </div>
    <div
      id="bet-types-wrapper"
      ref="betTypesWrapper"
      v-if="gameType==='gxklsf'"
      class="klsf"
      :current-class='game.info.currentAuctioneerUIClass.class'
    >
      <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
        <ul
          v-for="(item,rootIndex) in gxklsfTypeTitle"
          class="clearfix bet-types dtclassic gxklsf"
          :title="item.title"
          v-if="checkClass(item.class)"
        >
          <li
            v-for="(item, index) in type[rootIndex]"
            v-if="item.status"
            @click="choose_num(item)"
            :class="{ highlight:item.select }"
          >
            <b
              class="title"
              :style="{ color:item.color }"
              :class="{small:item.title.length>2}"
            >{{item.title}}</b>
            <span>{{item.odds}}</span>
          </li>
        </ul>
      </template>
      <template v-if="_TEMPLATE==='template-3'">
        <ul
          v-for="(item,rootIndex) in gxklsfTypeTitle"
          class="clearfix bet-types dtclassic gxklsf"
          :title="item.title"
          v-if="checkClass(item.class)"
        >
          <li
            v-for="(item, index) in type[rootIndex]"
            v-if="item.status"
            @click="choose_num(item)"
            :class="{ highlight:item.select, number:!isNaN(item.title) }"
          >
            <div class="title-wrap">
              <b
                class="title"
                :style="{ '--data-color':item.color }"
              >{{item.title}}</b>
            </div>
            <span>{{item.odds}}</span>
          </li>
        </ul>
      </template>
    </div>
    <div
      id="bet-types-wrapper"
      ref="betTypesWrapper"
      v-if="gameType==='tencent'"
    >
      <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
        <ul
          v-for="(item,rootIndex) in tencentTypeTitle"
          class="clearfix bet-types dtclassic"
          :title="item"
          :class="{ column2:item.indexOf('VS')>-1 }"
        >
          <li
            v-for="(item, index) in type[rootIndex]"
            v-if="item.status"
            @click="choose_num(item)"
            :class="{ highlight:item.select }"
          >
            <b
              class="title"
              :style="{ color:item.color }"
            >{{item.title}}</b>
            <span>{{item.odds}}</span>
          </li>
        </ul>
      </template>
      <template v-if="_TEMPLATE==='template-3'">
        <ul
          v-for="(item,rootIndex) in tencentTypeTitle"
          class="clearfix bet-types dtclassic"
          :title="item"
          :class="{ column2:item.indexOf('VS')>-1 }"
        >
          <li
            v-for="(item, index) in type[rootIndex]"
            v-if="item.status"
            @click="choose_num(item)"
            :class="{ highlight:item.select, number:!isNaN(item.title) }"
          >
            <div class="title-wrap">
              <b
                class="title"
                :style="{ '--data-color':item.color }"
              >{{item.title}}</b>
            </div>
            <span>{{item.odds}}</span>
          </li>
        </ul>
      </template>
    </div>
    <div
      id="bet-types-wrapper"
      ref="betTypesWrapper"
      v-if="gameType==='fc3d'"
      class="fc3d"
    >
      <div
        class="custom-type-odd"
        v-if="_TEMPLATE==='template-3'&&(game.info.currentAuctioneerUIClass.classTypeId==='5'||game.info.currentAuctioneerUIClass.classTypeId==='7'||game.info.currentAuctioneerUIClass.classTypeId==='8'||game.info.currentAuctioneerUIClass.classTypeId==='9')"
      >
        <template v-if="game.info.currentAuctioneerUIClass.classTypeId==='7'">
          <h5>{{fc3dData.class7.current}}</h5>
          <h6>{{ getWord('odds_of_item') }}：<span>{{fc3dData.combinationOdds}}</span></h6>
        </template>
        <template v-if="game.info.currentAuctioneerUIClass.classTypeId==='8'">
          <h5>{{fc3dData.class8.current}}</h5>
          <h6>{{ getWord('odds_of_item') }}：<span>{{fc3dData.combinationOdds}}</span></h6>
        </template>
        <template v-if="game.info.currentAuctioneerUIClass.classTypeId==='5'">
          <h5>{{selectAry[0].title}}</h5>
          <h6>{{ getWord('odds_of_item') }}：<span>{{selectAry[0].odds}}</span></h6>
        </template>
      </div>
      <div
        class="custom-header"
        v-if="_TEMPLATE==='template-3'&&(game.info.currentAuctioneerUIClass.classTypeId==='3'||game.info.currentAuctioneerUIClass.classTypeId==='7'||game.info.currentAuctioneerUIClass.classTypeId==='8'||game.info.currentAuctioneerUIClass.classTypeId==='9')"
      >
        <template v-if="game.info.currentAuctioneerUIClass.classTypeId==='7'||game.info.currentAuctioneerUIClass.classTypeId==='8'">
          <b>{{ getWord('number_of_item') }}</b>
          <span>{{ getWord('checked2') }}</span>
          <b>{{ getWord('number_of_item') }}</b>
          <span>{{ getWord('checked2') }}</span>
        </template>
        <template v-if="game.info.currentAuctioneerUIClass.classTypeId==='3'||game.info.currentAuctioneerUIClass.classTypeId==='9'">
          <b>{{ getWord('number_of_item') }}</b>
          <span>{{ getWord('odds_of_item') }}</span>
          <b>{{ getWord('number_of_item') }}</b>
          <span>{{ getWord('odds_of_item') }}</span>
        </template>
      </div>
      <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
        <ul
          v-for="(item,rootIndex) in fc3dTypeTitle"
          class="clearfix bet-types dtclassic fc3d"
          :title="item"
          v-if="game.info.currentAuctioneerUIClass.classTypeId!=='5' && game.info.currentAuctioneerUIClass.classTypeId!=='7' && game.info.currentAuctioneerUIClass.classTypeId!=='8'"
        >
          <li
            v-for="(item, index) in type[rootIndex]"
            v-if="item.status"
            @click="choose_num(item)"
            :class="{ highlight:item.select }"
          >
            <b
              class="title"
              :style="{ color:item.color }"
            >{{convertTitle(item.title)}}</b>
            <span>{{item.odds}}</span>
          </li>
        </ul>
      </template>
      <template v-if="_TEMPLATE==='template-3'">
        <ul
          v-for="(item,rootIndex) in TEMPLATE3_fc3dTypeTitle"
          class="clearfix bet-types dtclassic fc3d"
          :title="item"
          v-if="game.info.currentAuctioneerUIClass.classTypeId!=='5' && game.info.currentAuctioneerUIClass.classTypeId!=='7' && game.info.currentAuctioneerUIClass.classTypeId!=='8'"
        >
          <li
            v-for="(item, index) in type[rootIndex]"
            v-if="item.status"
            @click="choose_num(item)"
            :class="{ highlight:item.select, number:!isNaN(item.title) }"
          >
            <div class="title-wrap">
              <b class="title">{{convertTitle(item.title)}}</b>
            </div>
            <span>{{item.odds}}</span>
          </li>
        </ul>
      </template>
      <template v-if="game.info.currentAuctioneerUIClass.classTypeId==='5'">
        <template v-if="_TEMPLATE==='template-1'&& (game.info.currentAuctioneerUIClass.classTypeId==='5') ">
          <h5 @click='fc3dData.class5Visible=!fc3dData.class5Visible'>{{ getWord('category') }}<span>{{fc3dData.class5.current}}</span></h5>
          <h5>{{ getWord('odds_of_item') }}<span>{{fc3dData.combinationOdds}}</span></h5>
        </template>
        <template v-if="_TEMPLATE==='template-2'">
          <div class="zhong type2">
            <div class="top">
              <h6>{{ getWord('category') }}</h6>
              <label
                v-for="(item,index) in fc3dData.class5.actions"
                @click="item.method.call(this)"
                :class="{highlight:fc3dData.class5.current===item.id}"
              ><span class="radio"></span><b>{{item.name}}</b></label>
            </div>
            <div class="bottom">
              <h6>{{ getWord('odds_of_item') }}</h6>
              <label>{{fc3dData.combinationOdds}}</label>
            </div>
          </div>
        </template>
        <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
          <ul
            class="clearfix bet-types dtclassic lhcnumberlist"
            v-for="(_item,_index) in fc3dErzidingweiTitle[fc3dData.class5.currentTypeTab]"
            :title="_item"
          >
            <li
              v-for="(item, index) in fc3dErzidingweiNumberList.slice(_index*10,_index*10+10)"
              @click="choose_num(item,$event)"
              :title="'zhixuan-'+(index+1)"
              :class="{ highlight:item.select }"
              :style="{ '--data-bg-color':item.color }"
            >
              <b
                class="title"
                :style="{ color:item.color }"
              >{{item.title}}</b>
            </li>
          </ul>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
          <ul
            class="clearfix bet-types dtclassic"
            v-for="(_item,_index) in fc3dErzidingweiTitle[fc3dData.class5.currentTypeTab]"
            :title="_item"
          >
            <li
              v-for="(item, index) in fc3dErzidingweiNumberList.slice(_index*10,_index*10+10)"
              @click="choose_num(item,$event)"
              :title="'zhixuan-'+(index+1)"
              :class="{ highlight:item.select, number:!isNaN(item.title) }"
            >
              <b class="title">{{item.title}}</b>
            </li>
          </ul>
        </template>
      </template>
      <template v-if="game.info.currentAuctioneerUIClass.classTypeId==='7'">
        <template v-if="_TEMPLATE==='template-1'">
          <h5 @click='fc3dData.class7Visible=!fc3dData.class7Visible'>{{ getWord('category') }}<span>{{fc3dData.class7.current}}</span></h5>
          <h5>{{ getWord('odds_of_item') }}<span>{{fc3dData.combinationOdds}}</span></h5>
        </template>
        <template v-if="_TEMPLATE==='template-2'">
          <div class="zhong type3">
            <!-- <div class="top">
                            <h6>{{ getWord('category') }}</h6> -->
            <!-- <template v-if="fc3dData.class7.current.indexOf('请选择')===0"> -->
            <!-- <label class="tip">{{fc3dData.class7.defaultHint}}</label> -->
            <!-- </template>
                            <template v-else>
                                <label v-for="(item,index) in fc3dData.class7.actions" @click="item.method.call(this)" :class="{highlight:fc3dData.class7.current===item.name}"><span class="radio"></span><b>{{item.name}}</b></label>
                            </template> -->
            <!-- </div> -->
            <div class="bottom">
              <h6>{{ getWord('odds_of_item') }}:</h6>
              <label>&nbsp;{{fc3dData.combinationOdds}}</label>
            </div>
          </div>
        </template>
        <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
          <ul
            class="clearfix bet-types dtclassic lhcnumberlist"
            :title="game.info.currentAuctioneerUIClass.class"
          >
            <li
              v-for="(item, index) in fc3dNumberList"
              @click="choose_num(item,$event)"
              :title="'zhixuan-'+(index+1)"
              :class="{ highlight:item.select }"
              :style="{ '--data-bg-color':item.color }"
            >
              <b
                class="title"
                :style="{ color:item.color }"
              >{{item.title}}</b>
            </li>
          </ul>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
          <ul class="clearfix bet-types dtclassic numberlist">
            <li
              v-for="(item, index) in fc3dNumberList"
              @click="choose_num(item,$event)"
              :title="'zhixuan-'+(index+1)"
              :class="{ highlight:item.select, number:!isNaN(item.title) }"
              :style="{ '--data-bg-color':item.color }"
            >
              <div class="title-wrap">
                <b
                  class="title"
                  :style="{ '--data-color':item.color }"
                >{{item.title}}</b>
              </div>
              <strong><i class="iconfont icon-correct"></i></strong>
            </li>
          </ul>
        </template>
      </template>
      <template v-if="game.info.currentAuctioneerUIClass.classTypeId==='8'">
        <template v-if="_TEMPLATE==='template-1'">
          <h5 @click='fc3dData.class8Visible=!fc3dData.class8Visible'>{{ getWord('category') }}<span>{{fc3dData.class8.current}}</span></h5>
          <h5>{{ getWord('odds_of_item') }}<span>{{fc3dData.combinationOdds}}</span></h5>
        </template>
        <template v-if="_TEMPLATE==='template-2'">
          <div class="zhong type3">
            <!-- <div class="top">
                            <h6>{{ getWord('category') }}</h6> -->
            <!-- <template v-if="fc3dData.class8.current.indexOf('请选择')===0"> -->
            <!-- <label class="tip">{{fc3dData.class8.defaultHint}}</label> -->
            <!-- </template>
                            <template v-else>
                                <label v-for="(item,index) in fc3dData.class8.actions" @click="item.method.call(this)" :class="{highlight:fc3dData.class8.current===item.name}"><span class="radio"></span><b>{{item.name}}</b></label>
                            </template> -->
            <!-- </div> -->
            <div class="bottom">
              <h6>{{ getWord('odds_of_item') }}:</h6>
              <label>&nbsp;{{fc3dData.combinationOdds}}</label>
            </div>
          </div>
        </template>
        <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
          <ul class="clearfix bet-types dtclassic lhcnumberlist">
            <li
              v-for="(item, index) in fc3dNumberList"
              @click="choose_num(item,$event)"
              :title="'zhixuan-'+(index+1)"
              :class="{ highlight:item.select }"
              :style="{ '--data-bg-color':item.color }"
            >
              <b
                class="title"
                :style="{ color:item.color }"
              >{{item.title}}</b>
            </li>
          </ul>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
          <ul class="clearfix bet-types dtclassic numberlist">
            <li
              v-for="(item, index) in fc3dNumberList"
              @click="choose_num(item,$event)"
              :title="'zhixuan-'+(index+1)"
              :class="{ highlight:item.select, number:!isNaN(item.title) }"
              :style="{ '--data-bg-color':item.color }"
            >
              <div class="title-wrap">
                <b
                  class="title"
                  :style="{ '--data-color':item.color }"
                >{{item.title}}</b>
              </div>
              <strong><i class="iconfont icon-correct"></i></strong>
            </li>
          </ul>
        </template>
      </template>
    </div>
    <div
      id="bet-types-wrapper"
      ref="betTypesWrapper"
      v-if="gameType.indexOf('28')>-1"
    >
      <template v-if="_TEMPLATE!=='template-3'">
        <ul class="clearfix bet-types pc28">
          <li
            v-for="(item, index) in type"
            v-if="item.status"
            @click="choose_num(item)"
            :class="{ highlight:item.select }"
            :style="{ '--data-bg-color':item.color?item.color:'rgba(57, 133, 225, .5)' }"
          >
            <b
              class="title"
              :style="{ color:item.color }"
            >{{item.title}}</b>
            <span>{{item.odds}}</span>
          </li>
        </ul>
      </template>
      <template
        v-else
        v-for="(item,rootIndex) in pc28TypeTitle"
      >
        <ul
          class="clearfix bet-types dtclassic"
          :title="item"
          :class="{ column2:item.indexOf('VS第')>-1 }"
        >
          <li
            v-for="(item, index) in type[rootIndex]"
            v-if="item.status"
            @click="choose_num(item)"
            :class="{ highlight:item.select, number:!isNaN(item.title) }"
          >
            <div class="title-wrap">
              <b
                class="title"
                :style="{ '--data-color':item.color }"
              >{{item.title}}</b>
            </div>
            <span>{{item.odds}}</span>
          </li>
        </ul>
      </template>
    </div>
    <div
      id="bet-types-wrapper"
      ref="betTypesWrapper"
      v-if="gameType.indexOf('fastthree')>-1"
    >
      <template v-if="_TEMPLATE!=='template-3'">
        <template v-for="(item,rootIndex) in kuai3TypeTitle">
          <ul
            class="clearfix bet-types kuai3"
            :title="item"
            :class="{ 'smaller':item===getWord(['da','small']) || item===getWord('dianshu'), 'full':item===(getWord('weitou')+','+getWord('quantou')) }"
          >
            <li
              v-for="(item, index) in type[rootIndex]"
              v-if="item.status"
              @click="choose_num(item)"
              :class="{ highlight:item.select }"
            >
              <template v-if="isNaN(item.title-0)">
                <b
                  class="title"
                  :style="{ color:item.color }"
                >{{item.title}}</b>
              </template>
              <template v-else>
                <span
                  v-for="_item in item.title.split('')"
                  class="dice"
                  :class="'dice-'+_item"
                > </span>
              </template>
              <span class="blockable">{{item.odds}}</span>
            </li>
          </ul>
        </template>
      </template>
      <template v-else>
        <template v-for="(item,rootIndex) in kuai3TypeTitle">
          <ul
            class="clearfix bet-types kuai3"
            :title="item"
            :class="{ 'smaller':item===getWord(['da','small']) || item===getWord('dianshu'), 'full':item===(getWord('weitou')+','+getWord('quantou')) }"
          >
            <li
              v-for="(item, index) in type[rootIndex]"
              v-if="item.status"
              @click="choose_num(item)"
              :class="{ highlight:item.select }"
            >
              <div class="title-wrap">
                <template v-if="isNaN(item.title-0)">
                  <b
                    class="title"
                    :style="{ color:item.color }"
                  >{{item.title}}</b>
                </template>
                <template v-else>
                  <span
                    v-for="_item in item.title.split('')"
                    class="dice"
                    :class="'dice-'+_item"
                  > </span>
                </template>
              </div>
              <span class="blockable">{{item.odds}}</span>
            </li>
          </ul>
        </template>
      </template>
    </div>
    <div
      id="bet-types-wrapper"
      ref="betTypesWrapper"
      v-if="gameType==='bjsc' || gameType==='scone' || gameType==='scthree' || gameType==='scfive' || gameType==='xyft' || gameType==='azxy10'"
      class="pk10"
    >
      <template v-if="_TEMPLATE!=='template-3'">
        <ul
          class="clearfix bet-types dtclassic"
          :title="item"
          v-for="(item,rootIndex) in pk10TypeTitle"
        >
          <li
            v-for="(item, index) in type[rootIndex]"
            v-if="item.status"
            @click="choose_num(item)"
            :class="{ highlight:item.select,disabled:item.disabled }"
          >
            <b
              class="title"
              :style="{ color:item.color }"
            >{{item.title}}</b>
            <span>{{item.odds}}</span>
          </li>
        </ul>
      </template>
      <template v-else>
        <ul
          class="clearfix bet-types dtclassic"
          :title="item"
          v-for="(item,rootIndex) in pk10TypeTitle"
        >
          <li
            v-for="(item, index) in type[rootIndex]"
            v-if="item.status"
            @click="choose_num(item)"
            :class="{ highlight:item.select, disabled:item.disabled, number:(typeof item.title==='number') }"
          >
            <div class="title-wrap">
              <b
                class="title"
                :class="{ 'tinting':game.info.currentAuctioneerUIClass.id==='class1'?true:false }"
                :style="{ '--data-color':game.info.currentAuctioneerUIClass.id==='class1'?item.color:'' }"
              >{{item.title}}</b>
            </div>
            <span>{{item.odds}}</span>
          </li>
        </ul>
      </template>
    </div>
    <div
      id="bet-types-wrapper"
      ref="betTypesWrapper"
      v-if="gameType.indexOf('lhc')===0"
    >
      <div
        class="zhong"
        v-if="_TEMPLATE==='template-3'&&(game.info.currentAuctioneerUIClass.id==='class12'||game.info.currentAuctioneerUIClass.classTypeId==='11'||game.info.currentAuctioneerUIClass.classTypeId==='8')"
      >
        <h6>{{ getWord('category') }}</h6>
        <label
          v-for="item in lhcData.actions"
          @click="actionsSwitch(item.id)"
          :class="{highlight:lhcData.zhong===item.id}"
        ><span class="radio"></span>{{item.name}}</label>
        <h6>{{ getWord('odds_of_item') }}</h6>
        <span>
          <span v-if="!lhcData.specialItemSelected">{{lhcData.combinationOdds}}</span>
          <span v-if="lhcData.specialItemSelected">{{lhcData.specialItemOdds}}</span>
        </span>
      </div>
      <div
        class="custom-type-odd"
        v-if="_TEMPLATE==='template-3'&&(game.info.currentAuctioneerUIClass.classTypeId==='10'||game.info.currentAuctioneerUIClass.classTypeId==='12')"
      >
        <h5>{{selectAry[0].title}}</h5>
        <h6>{{ getWord('odds_of_item') }}：<span>{{selectAry[0].odds}}</span></h6>
      </div>
      <div
        class="custom-header"
        v-if="_TEMPLATE==='template-3'"
      >
        <template v-if="gameType.indexOf('lhc')===0">
          <template v-if="game.info.currentAuctioneerUIClass.classTypeId==='10'||game.info.currentAuctioneerUIClass.classTypeId==='12'">
            <b>{{ getWord('number_of_item') }}</b>
            <span>{{ getWord('checked2') }}</span>
            <b>{{ getWord('number_of_item') }}</b>
            <span>{{ getWord('checked2') }}</span>
          </template>
          <template v-if="game.info.currentAuctioneerUIClass.id==='class1'||game.info.currentAuctioneerUIClass.id==='class2'||game.info.currentAuctioneerUIClass.classTypeId==='3'">
            <b>{{ getWord('number_of_item') }}</b>
            <span>{{ getWord('odds_of_item') }}</span>
            <b>{{ getWord('number_of_item') }}</b>
            <span>{{ getWord('odds_of_item') }}</span>
          </template>
          <template v-if="game.info.currentAuctioneerUIClass.id==='class17'">
            <b class="large">{{ getWord('sebo') }}</b>
            <span>{{ getWord('odds_of_item') }}</span>
          </template>
          <template v-if="game.info.currentAuctioneerUIClass.id==='class10'||game.info.currentAuctioneerUIClass.id==='class11'">
            <b class="small">{{ getWord('zodiac') }}</b>
            <span class="normal">{{ getWord('number_of_item') }}</span>
            <span>{{ getWord('odds_of_item') }}</span>
          </template>
          <template v-if="game.info.currentAuctioneerUIClass.id==='class12'||game.info.currentAuctioneerUIClass.classTypeId==='8'">
            <b class="small">{{ getWord('zodiac') }}</b>
            <span class="normal">{{ getWord('number_of_item') }}</span>
            <span>{{ getWord('checked2') }}</span>
          </template>
          <template v-if="game.info.currentAuctioneerUIClass.classTypeId==='11'">
            <b class="small">{{ getWord('weishu') }}</b>
            <span class="normal">{{ getWord('number_of_item') }}</span>
            <span>{{ getWord('checked2') }}</span>
          </template>
        </template>
      </div>
      <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
        <template v-for="(_item,rootIndex) in lhcTypeTitle">
          <ul
            :title="_TEMPLATE==='template-2'&&_item"
            class="clearfix bet-types dtclassic lhc"
            :class="{ transformtotab:game.info.currentAuctioneerUIClass.id==='class15' }"
            v-if="game.info.currentAuctioneerUIClass.id==='class'+(rootIndex+1) && game.info.currentAuctioneerUIClass.id!=='class12' && game.info.currentAuctioneerUIClass.id!=='class13' && game.info.currentAuctioneerUIClass.id!=='class16' && game.info.currentAuctioneerUIClass.id!=='class17'"
          >
            <template v-if="_TEMPLATE==='template-2'&&game.info.currentAuctioneerUIClass.id==='class15'">
              <li class="lianma">
                <div class="top">
                  <span class="radio hidden"></span>
                  <b>{{ getWord('category') }}</b>
                </div>
                <div class="bottom">
                  <b class="title">{{ getWord('odds_of_item') }}</b>
                  <span class="hidden">0</span>
                </div>
              </li>
              <li
                v-for="(item, index) in type[rootIndex]"
                v-if="item.status && item.title!=='三中三' && item.title!=='二中二'"
                @click="choose_num(item)"
                :class="['lianma',{ 'highlight':item.select, 'zodiac':item.zodiacBall, 'banbo':item.banBoBall }]"
                :style="{ '--data-bg-color':item.color?item.color:'rgba(57, 133, 225, .5)' }"
              >
                <div class="top">
                  <span class="radio"></span>
                  <b>{{item.title}}</b>
                </div>
                <div class="bottom">
                  <p v-if="item.zodiacBall">
                    <i
                      v-for="item in item.zodiacBall"
                      :style="{ '--data-color':item.color }"
                    >{{item.title}}</i>
                  </p>
                  <p
                    v-if="item.banBoBall"
                    :style="{ '--data-color':item.banBoBall.color }"
                  >
                    <i v-for="item in item.banBoBall.numbers">{{item}}</i>
                  </p>
                  <b
                    class="title"
                    :class="{number:!isNaN(item.title)}"
                    :style="{ color:item.color }"
                  >{{item.title}}</b>
                  <span>{{item.odds}}</span>
                </div>
              </li>
            </template>
            <template v-else>
              <li
                v-for="(item, index) in type[rootIndex]"
                v-if="item.status && item.title!=='三中三' && item.title!=='二中二'"
                @click="choose_num(item)"
                :class="{ 'highlight':item.select, 'zodiac':item.zodiacBall, 'banbo':item.banBoBall }"
                :style="{ '--data-bg-color':item.color?item.color:'rgba(57, 133, 225, .5)' }"
              >
                <p v-if="item.zodiacBall">
                  <i
                    v-for="item in item.zodiacBall"
                    :style="{ '--data-color':item.color }"
                  >{{item.title}}</i>
                </p>
                <p
                  v-if="item.banBoBall"
                  :style="{ '--data-color':item.banBoBall.color }"
                >
                  <i v-for="item in item.banBoBall.numbers">{{item}}</i>
                </p>
                <b
                  class="title"
                  :class="{number:!isNaN(item.title)}"
                  :style="{ color:item.color }"
                >{{item.title}}</b>
                <span>{{item.odds}}</span>
              </li>
            </template>
          </ul>
        </template>
        <template v-if="game.info.currentAuctioneerUIClass.id==='class12' || game.info.currentAuctioneerUIClass.id==='class13' || game.info.currentAuctioneerUIClass.id==='class16'">
          <template v-if="_TEMPLATE!=='template-2'">
            <h5 @click='lhcData.actionsVisible=!lhcData.actionsVisible'>{{ getWord('category') }}<span>{{lhcData.zhong}}</span></h5>
            <h5>{{ getWord('odds_of_item') }}<span><span v-if="!lhcData.specialItemSelected">{{lhcData.combinationOdds}}</span><span v-if="lhcData.specialItemSelected">{{lhcData.combinationOdds+'/'+lhcData.specialItemOdds}}</span></span></h5>
          </template>
          <template v-else>
            <div class="zhong type1">
              <h6>{{ getWord('category') }}</h6>
              <label
                v-for="item in lhcData.actions"
                @click="actionsSwitch(item.id)"
                :class="{highlight:lhcData.zhong===item.id}"
              ><span class="radio"></span>{{item.name}}</label>
              <h6>{{ getWord('odds_of_item') }}</h6>
              <span>
                <span v-if="!lhcData.specialItemSelected">{{lhcData.combinationOdds}}</span>
                <span v-if="lhcData.specialItemSelected">{{lhcData.specialItemOdds}}</span>
              </span>
            </div>
          </template>
          <nav v-if="game.info.currentAuctioneerUIClass.id==='class13' && (_TEMPLATE==='template-1' || _TEMPLATE==='template-2')">
            <a
              v-for="item in lhcData.typeTab.class13"
              @click="bindTypeTab(item,'class13')"
              :class="{ highlight:item.active }"
            >{{item.title}}</a>
          </nav>
          <nav v-if="game.info.currentAuctioneerUIClass.id==='class12'">
            <a
              v-for="item in lhcZodiacSeries()"
              @click="bindZodiacSeries(item,'class12')"
              :class="{ highlight:item.select }"
            >{{item.title}}</a>
          </nav>
          <ul
            class="zodiac clearfix"
            v-if="game.info.currentAuctioneerUIClass.id!=='class16'"
          >
            <li
              v-for="(item, index) in lhcAnimalRule"
              @click="choose_num(item)"
              :class="{ highlight:item.select }"
            >
              <p><i
                  v-for="item in item.numbers"
                  :style="{ '--data-color':item.color }"
                >{{item.title}}</i></p>
              <b
                class="title"
                :style="{ color:item.color }"
              >{{item.title}}</b>
            </li>
          </ul>
          <nav v-if="game.info.currentAuctioneerUIClass.id==='class16'">
            <a
              v-for="item in lhcData.typeTab.class15"
              @click="bindTypeTab(item,'class15')"
              :class="{ highlight:item.active }"
            >{{item.title}}</a>
          </nav>
          <ul
            class="zodiac clearfix"
            v-if="game.info.currentAuctioneerUIClass.id==='class16'"
          >
            <li
              v-for="(item, index) in lhcWeiLianRule"
              @click="choose_num(item)"
              :class="{ highlight:item.select }"
            >
              <p><i
                  v-for="item in item.related"
                  :style="{ '--data-color':item.color }"
                >{{item.title}}</i></p>
              <b
                class="title"
                :style="{ color:item.color }"
              >{{item.title}}</b>
            </li>
          </ul>
        </template>
        <template v-if="game.info.currentAuctioneerUIClass.id==='class15' || game.info.currentAuctioneerUIClass.id==='class17'">
          <template v-if="_TEMPLATE!=='template-2'&&game.info.currentAuctioneerUIClass.id==='class17'">
            <h5 @click='lhcData.class17Visible=!lhcData.class17Visible'>{{ getWord('category') }}<span>{{lhcData.class17.current}}</span></h5>
            <h5>{{ getWord('odds_of_item') }}<span>{{lhcData.combinationOdds}}</span></h5>
          </template>
          <template v-else-if="game.info.currentAuctioneerUIClass.id==='class17'">
            <div class="zhong type2">
              <div class="top">
                <h6>{{ getWord('category') }}</h6>
                <label
                  v-for="(item,index) in lhcData.class17.actions"
                  @click="item.method.call(this)"
                  :class="{highlight:lhcData.class17.current===item.name}"
                ><span class="radio"></span><b>{{item.name}}</b></label>
              </div>
              <div class="bottom">
                <h6>{{ getWord('odds_of_item') }}</h6>
                <label>{{lhcData.combinationOdds}}</label>
              </div>
            </div>
          </template>
          <ul class="clearfix bet-types dtclassic lhc lhcnumberlist">
            <li
              v-for="(item, index) in lhcNumberList"
              @click="choose_num(item)"
              :class="{ highlight:item.select }"
              :style="{ '--data-bg-color':item.color }"
            >
              <b
                class="title"
                :style="{ color:item.color }"
              >{{item.title}}</b>
            </li>
          </ul>
        </template>
      </template>
      <template v-if="_TEMPLATE==='template-3'">
        <template v-for="(_item,rootIndex) in TEMPLATE3_lhcTypeTitle">
          <ul
            class="clearfix bet-types dtclassic lhc"
            v-if="game.info.currentAuctioneerUIClass.id==='class'+(rootIndex+1) && game.info.currentAuctioneerUIClass.id!=='class12' && game.info.currentAuctioneerUIClass.classTypeId!=='8' && game.info.currentAuctioneerUIClass.classTypeId!=='11' && game.info.currentAuctioneerUIClass.classTypeId!=='12' && game.info.currentAuctioneerUIClass.classTypeId!=='10' "
          >
            <template>
              <li
                v-for="(item, index) in type[rootIndex]"
                @click="choose_num(item)"
                :class="{ 'highlight':item.select, 'zodiac':item.zodiacBall, 'banbo':item.banBoBall, number:!isNaN(item.title) }"
                :style="{ '--data-bg-color':item.color?item.color:'rgba(57, 133, 225, .5)' }"
              >
                <div class="title-wrap">
                  <b
                    class="title"
                    :style="{ '--data-color':item.color }"
                    :class="item.colorName"
                  >{{item.title}}</b>
                </div>
                <p v-if="item.zodiacBall">
                  <i
                    v-for="item in item.zodiacBall"
                    :class="item.colorName"
                  >{{item.title}}</i>
                </p>
                <span>{{item.odds}}</span>
              </li>
            </template>
          </ul>
        </template>
        <template v-if="game.info.currentAuctioneerUIClass.id==='class12' || game.info.currentAuctioneerUIClass.classTypeId==='8' || game.info.currentAuctioneerUIClass.classTypeId==='11'">
          <ul
            class="zodiac clearfix"
            v-if="game.info.currentAuctioneerUIClass.classTypeId!=='11'"
          >
            <li
              v-for="(item, index) in lhcAnimalRule"
              @click="choose_num(item)"
              :class="{ highlight:item.select }"
            >
              <b
                class="title"
                :style="{ color:item.color }"
              >{{item.title}}</b>
              <p><i
                  v-for="item in item.numbers"
                  :style="{ '--data-color':item.color }"
                  :class="item.colorName"
                >{{item.title}}</i></p>
              <strong><i class="iconfont icon-correct"></i></strong>
            </li>
          </ul>
          <ul
            class="zodiac clearfix"
            v-if="game.info.currentAuctioneerUIClass.classTypeId==='11'"
          >
            <li
              v-for="(item, index) in lhcWeiLianRule"
              @click="choose_num(item)"
              :class="{ highlight:item.select }"
            >
              <b
                class="title"
                :style="{ color:item.color }"
              >{{item.title}}</b>
              <p><i
                  v-for="item in item.related"
                  :style="{ '--data-color':item.color }"
                  :class="item.colorName"
                >{{item.title}}</i></p>
              <strong><i class="iconfont icon-correct"></i></strong>
            </li>
          </ul>
        </template>
        <template v-if="game.info.currentAuctioneerUIClass.classTypeId==='10' || game.info.currentAuctioneerUIClass.classTypeId==='12'">
          <ul class="clearfix bet-types dtclassic lhc">
            <li
              v-for="(item, index) in lhcNumberList"
              @click="choose_num(item)"
              :class="{ highlight:item.select,number:!isNaN(item.title) }"
              :style="{ '--data-bg-color':item.color }"
            >
              <div class="title-wrap">
                <b
                  class="title"
                  :style="{ '--data-color':item.color }"
                  :class="item.colorName"
                >{{item.title}}</b>
              </div>
              <strong><i class="iconfont icon-correct"></i></strong>
            </li>
          </ul>
        </template>
      </template>
    </div>
    <template v-if="(_TEMPLATE==='template-1'||_TEMPLATE==='template-2')&& (gameType!=='wanneng'&&gameType!=='damacai'&&gameType!=='duoduo'&&gameType!=='yuedawan'&&gameType!=='shaba88'&&gameType!=='saimahui'&&gameType!=='xinjapowz') ">
      <div
        id="bet-coins"
        class="clearfix type2"
      >
        <div class="coins">
          <div class="inner">
            <img
              v-for="(item,index) in coins"
              @click="choose_money(item,$event)"
              :src="item.src"
              v-if="item.select"
              :class="{ active:item.active }"
            >
          </div>
        </div>
        <div :class="_TEMPLATE+' order'">
          <a @click="reset()">{{ getWord('clear') }}</a>
          <label>
            <span>{{ currencySymbol() }}</span>
            <input
              type="number"
              v-model="amount"
              :placeholder="getWord('amount_of_betting2')"
              @blur="_blured($event)"
            />
          </label>
          <span>{{ getWord('balance') }}： <b>{{userBalance}}</b></span>
          <button @click="order()">{{ getWord('confirm3') }}</button>
        </div>
      </div>
    </template>
    <template v-if="_TEMPLATE==='template-3' && (gameType!=='wanneng'&&gameType!=='damacai'&&gameType!=='duoduo'&&gameType!=='yuedawan'&&gameType!=='shaba88'&&gameType!=='saimahui'&&gameType!=='xinjapowz')">
      <div
        id="bet-coins"
        class="clearfix"
      >
        <div class="coins">
          <label>{{ getWord('amount_of_betting3') }}</label>
          <input
            type="text"
            v-model="amount"
            :placeholder="getWord('amount_of_betting4')"
            @blur="_blured($event)"
          />
          <!-- <img v-for="(item,index) in coins" @click="choose_money(item,$event)" :src="item.src" v-if="item.select" :class="{ active:item.active }"> -->
          <!-- <a @click="toggleAside('coinSelector')">筹码设定</a> -->
        </div>
        <div class="order">
          <div class="left">
            <span>{{ getWord('total') }}{{countOfCombinationBet!==0?countOfCombinationBet:selectAry.length}}{{ getWord('order2') }}</span>
            <span>{{ getWord('total') }}{{totalAmount}}{{ currencySymbol() }}</span>
          </div>
          <div class="right">
            <a @click="reset()">{{ getWord('clear') }}</a>
            <button @click="order()">{{ getWord('betting') }}</button>
          </div>
        </div>
      </div>
    </template>
    <mt-actionsheet
      :actions="lhcData.actions"
      v-model="lhcData.actionsVisible"
    ></mt-actionsheet>
    <mt-actionsheet
      :actions="lhcData.class17.actions"
      v-model="lhcData.class17Visible"
    ></mt-actionsheet>
    <mt-actionsheet
      :actions="gdfiveselectData.class3.actions"
      v-model="gdfiveselectData.class3Visible"
    ></mt-actionsheet>
    <mt-actionsheet
      :actions="gdfiveselectData.class4.actions"
      v-model="gdfiveselectData.class4Visible"
    ></mt-actionsheet>
    <mt-actionsheet
      :actions="fc3dData.class5.actions"
      v-model="fc3dData.class5Visible"
    ></mt-actionsheet>
    <mt-actionsheet
      :actions="gdkltenData.class12.actions"
      v-model="gdkltenData.class12Visible"
    ></mt-actionsheet>
    <transition name="slide-fade">
      <aside v-if="aside.coinSelector">
        <div class="inner">
          <h4>{{getWord('bargaining_chip',{ '$1':'3','$2':''}).replace('~','') }}</h4>
          <ul>
            <li
              v-for="(item,index) in coins"
              :class="{ highlight:item.select }"
            >
              <img
                :src="cloudImageBaseUrl_coins+_TEMPLATE+'/coins/'+item.value+'.png'"
                @click="choosing_chips(item)"
              />

            </li>
          </ul>
          <footer>
            <button
              class="ripple"
              @click="chip_select('coinSelector',true)"
            >{{ getWord('cancel') }}</button>
            <button
              class="ripple"
              @click="chip_select('coinSelector')"
            >{{ getWord('ok') }}</button>
          </footer>
        </div>
      </aside>
    </transition>
    <transition name="slide-fade">
      <aside v-if="aside.orderComfirm">
        <template v-if="(_TEMPLATE==='template-1'||_TEMPLATE==='template-2') && (gameType!=='wanneng'&&gameType!=='damacai'&&gameType!=='duoduo'&&gameType!=='yuedawan'&&gameType!=='shaba88'&&gameType!=='saimahui'&&gameType!=='xinjapowz')">
          <div
            class="inner sure_bets_box"
            id="order-confirm"
            ref="orderComfirm"
          >
            <h4>
              <img :src="require('@@/assets/images/tradingfloor/icons/auctioneerUI-order-title.png')" />
              {{ getWord('confirm_order') }}{{currentDrawing}}{{ getWord('period') }}
            </h4>
            <div
              class="scrolled-block"
              id="scrolled-block"
              ref="scrolledBlock"
            >
              <table>
                <tr>
                  <th>{{ getWord('selected_item') }}</th>
                  <th>{{ getWord('odds_of_item') }}</th>
                  <th>{{ getWord('amount_of_betting') }}</th>
                  <th>{{ getWord('operation') }}</th>
                </tr>
                <tr v-for="(item, index) in selectAry">
                  <td v-if="item.originalTitle && item.originalTitle!==''">{{item.originalTitle}}</td>
                  <td v-else-if="item.title && item.title!==''">{{item.title}}</td>
                  <td v-else>{{item.name}}</td>
                  <td>{{item.odds}}</td>
                  <td>{{ currencySymbol() }}{{Number(amount).toFixed(2)}}</td>
                  <td @click="deleteEntry(item)"><i class="iconfont icon-delete"></i></td>
                </tr>
              </table>
            </div>
            <h6>{{ getWord('order') }}: <b>{{countOfCombinationBet!==0?countOfCombinationBet:selectAry.length}}</b>{{ getWord('item') }} {{ getWord('total4') }}: <b>{{Number(this.totalAmount).toFixed(2)}}</b>{{ currencySymbol() }}</h6>
            <footer>
              <button
                @click="orderAuctionByUI()"
                :disabled="disabled"
              >{{ getWord('confirm3') }}</button>
              <button @click="toggleAside('orderComfirm')">{{ getWord('cancel') }}</button>
            </footer>
          </div>
        </template>
        <template v-if="(gameType==='wanneng'||gameType==='damacai'||gameType==='duoduo'||gameType==='yuedawan'||gameType==='shaba88'||gameType==='saimahui'||gameType==='xinjapowz')">
          <div
            class="inner sure_bets_box"
            id="order-confirm"
            ref="orderComfirm"
          >
            <h4>
              <img :src="require('@@/assets/images/tradingfloor/icons/auctioneerUI-order-title.png')" />
              {{ getWord('confirm_order') }}{{currentDrawing}}{{ getWord('period') }}
            </h4>
            <div
              class="scrolled-block"
              id="scrolled-block"
              ref="scrolledBlock"
            >
              <table>
                <tr>
                  <th>{{ getWord('game_rules2') }}</th>
                  <th>{{ getWord('number_of_item') }}</th>
                  <th>{{ getWord('amount_of_betting') }}</th>
                  <th>{{ getWord('operation') }}</th>
                </tr>
                <tr
                  v-if="game.info.currentAuctioneerUIClass.id==='2' && wannengKey1.value"
                  v-for="item in generatedSequenceList"
                >
                  <td>{{ wannengConvertTitle(game.info.currentAuctioneerUIClass.alias) }}_{{ wannengConvertTitle(wannengKey1.alias) }}</td>
                  <td>{{item}}</td>
                  <td>￥{{Number(wannengKey1.value).toFixed(2)}}</td>
                </tr>
                <tr
                  v-if="game.info.currentAuctioneerUIClass.id==='3' && wannengKey1.value"
                  v-for="item in calculatedPermutationList"
                >
                  <td>{{ wannengConvertTitle(game.info.currentAuctioneerUIClass.alias) }}_{{ wannengConvertTitle(wannengKey1.alias) }}</td>
                  <td>{{item}}</td>
                  <td>￥{{Number(wannengKey1.value).toFixed(2)}}</td>
                </tr>
                <tr v-if="(game.info.currentAuctioneerUIClass.id!=='2'&&game.info.currentAuctioneerUIClass.id!=='3') && wannengKey1.value">
                  <td>
                    {{ wannengConvertTitle(game.info.currentAuctioneerUIClass.alias) }}_{{ wannengConvertTitle(wannengKey1.alias) }}
                    {{ game.info.currentAuctioneerUIClass.id==='4'?'_M'+Array.from(calculatedPermutationList).length:'' }}
                  </td>
                  <td>{{wannengNum1}},{{wannengNum2}},{{wannengNum3}},{{wannengNum4}}</td>
                  <td>￥{{Number(wannengKey1.value).toFixed(2)}}</td>
                  <td @click="deleteWannengEntry(1)"><i class="iconfont icon-delete"></i></td>
                </tr>

                <tr
                  v-if="game.info.currentAuctioneerUIClass.id==='2' && wannengKey2.value"
                  v-for="item in generatedSequenceList"
                >
                  <td>{{ wannengConvertTitle(game.info.currentAuctioneerUIClass.alias) }}_{{ wannengConvertTitle(wannengKey2.alias) }}</td>
                  <td>{{item}}</td>
                  <td>￥{{Number(wannengKey2.value).toFixed(2)}}</td>
                </tr>
                <tr
                  v-if="game.info.currentAuctioneerUIClass.id==='3' && wannengKey2.value"
                  v-for="item in calculatedPermutationList"
                >
                  <td>{{ wannengConvertTitle(game.info.currentAuctioneerUIClass.alias) }}_{{ wannengConvertTitle(wannengKey2.alias) }}</td>
                  <td>{{item}}</td>
                  <td>￥{{Number(wannengKey2.value).toFixed(2)}}</td>
                </tr>
                <tr v-if="(game.info.currentAuctioneerUIClass.id!=='2'&&game.info.currentAuctioneerUIClass.id!=='3') && wannengKey2.value">
                  <td>{{ wannengConvertTitle(game.info.currentAuctioneerUIClass.alias) }}_{{ wannengConvertTitle(wannengKey2.alias) }}{{ game.info.currentAuctioneerUIClass.id==='4'?'_M'+Array.from(calculatedPermutationList).length:'' }}</td>
                  <td>{{wannengNum1}},{{wannengNum2}},{{wannengNum3}},{{wannengNum4}}</td>
                  <td>￥{{Number(wannengKey2.value).toFixed(2)}}</td>
                  <td @click="deleteWannengEntry(2)"><i class="iconfont icon-delete"></i></td>
                </tr>

                <tr
                  v-if="game.info.currentAuctioneerUIClass.id==='2' && wannengKey3.value"
                  v-for="item in generatedSequenceList"
                >
                  <td>{{ wannengConvertTitle(game.info.currentAuctioneerUIClass.alias) }}_{{ wannengConvertTitle(wannengKey3.alias) }}</td>
                  <td>{{item}}</td>
                  <td>￥{{Number(wannengKey3.value).toFixed(2)}}</td>
                </tr>
                <tr
                  v-if="game.info.currentAuctioneerUIClass.id==='3' && wannengKey3.value"
                  v-for="item in calculatedPermutationList"
                >
                  <td>{{ wannengConvertTitle(game.info.currentAuctioneerUIClass.alias) }}_{{ wannengConvertTitle(wannengKey3.alias) }}</td>
                  <td>{{item}}</td>
                  <td>￥{{Number(wannengKey3.value).toFixed(2)}}</td>
                </tr>
                <tr v-if="(game.info.currentAuctioneerUIClass.id==='1'||game.info.currentAuctioneerUIClass.id==='4') && wannengKey3.value">
                  <td>{{ wannengConvertTitle(game.info.currentAuctioneerUIClass.alias) }}_{{ wannengConvertTitle(wannengKey3.alias) }}{{ game.info.currentAuctioneerUIClass.id==='4'?'_M'+Array.from(calculatedPermutationList).length:'' }}</td>
                  <td>{{wannengNum1}},{{wannengNum2}},{{wannengNum3}},{{wannengNum4}}</td>
                  <td>￥{{Number(wannengKey3.value).toFixed(2)}}</td>
                  <td @click="deleteWannengEntry(3)"><i class="iconfont icon-delete"></i></td>
                </tr>

                <tr
                  v-if="game.info.currentAuctioneerUIClass.id==='2' && wannengKey4.value"
                  v-for="item in generatedSequenceList"
                >
                  <td>{{ wannengConvertTitle(game.info.currentAuctioneerUIClass.alias) }}_{{ wannengConvertTitle(wannengKey4.alias) }}</td>
                  <td>{{item}}</td>
                  <td>￥{{Number(wannengKey4.value).toFixed(2)}}</td>
                </tr>
                <tr
                  v-if="game.info.currentAuctioneerUIClass.id==='3' && wannengKey4.value"
                  v-for="item in calculatedPermutationList"
                >
                  <td>{{ wannengConvertTitle(game.info.currentAuctioneerUIClass.alias) }}_{{ wannengConvertTitle(wannengKey4.alias) }}</td>
                  <td>{{item}}</td>
                  <td>￥{{Number(wannengKey4.value).toFixed(2)}}</td>
                </tr>
                <tr v-if="(game.info.currentAuctioneerUIClass.id==='1'||game.info.currentAuctioneerUIClass.id==='4') && wannengKey4.value">
                  <td>{{ wannengConvertTitle(game.info.currentAuctioneerUIClass.alias) }}_{{ wannengConvertTitle(wannengKey4.alias) }}{{ game.info.currentAuctioneerUIClass.id==='4'?'_M'+Array.from(calculatedPermutationList).length:'' }}</td>
                  <td>{{wannengNum1}},{{wannengNum2}},{{wannengNum3}},{{wannengNum4}}</td>
                  <td>￥{{Number(wannengKey4.value).toFixed(2)}}</td>
                  <td @click="deleteWannengEntry(4)"><i class="iconfont icon-delete"></i></td>
                </tr>

                <tr
                  v-if="game.info.currentAuctioneerUIClass.id==='2' && wannengKey5.value"
                  v-for="item in generatedSequenceList"
                >
                  <td>{{ wannengConvertTitle(game.info.currentAuctioneerUIClass.alias) }}_{{ wannengConvertTitle(wannengKey5.alias) }}</td>
                  <td>{{item}}</td>
                  <td>￥{{Number(wannengKey5.value).toFixed(2)}}</td>
                </tr>
                <tr
                  v-if="game.info.currentAuctioneerUIClass.id==='3' && wannengKey5.value"
                  v-for="item in calculatedPermutationList"
                >
                  <td>{{ wannengConvertTitle(game.info.currentAuctioneerUIClass.alias) }}_{{ wannengConvertTitle(wannengKey5.alias) }}</td>
                  <td>{{item}}</td>
                  <td>￥{{Number(wannengKey5.value).toFixed(2)}}</td>
                </tr>
                <tr v-if="(game.info.currentAuctioneerUIClass.id==='1'||game.info.currentAuctioneerUIClass.id==='4') && wannengKey5.value">
                  <td>{{ wannengConvertTitle(game.info.currentAuctioneerUIClass.alias) }}_{{ wannengConvertTitle(wannengKey5.alias) }}{{ game.info.currentAuctioneerUIClass.id==='4'?'_M'+Array.from(calculatedPermutationList).length:'' }}</td>
                  <td>{{wannengNum1}},{{wannengNum2}},{{wannengNum3}},{{wannengNum4}}</td>
                  <td>￥{{Number(wannengKey5.value).toFixed(2)}}</td>
                  <td @click="deleteWannengEntry(5)"><i class="iconfont icon-delete"></i></td>
                </tr>
              </table>
            </div>
            <h6 v-if="(_TEMPLATE==='template-1'||_TEMPLATE==='template-2')&&gameType!=='wanneng'&&gameType!=='damacai'&&gameType!=='duoduo'&&gameType!=='yuedawan'&&gameType!=='shaba88'&&gameType!=='saimahui'&&gameType!=='xinjapowz'">{{ getWord('order') }}: <b>{{countOfCombinationBet!==0?countOfCombinationBet:selectAry.length}}</b>{{ getWord('item') }} {{ getWord('total4') }}: <b>{{Number(this.totalAmount).toFixed(2)}}</b>{{ currencySymbol() }}</h6>
            <h6 v-else>{{ getWord('order') }}: <b>{{ calcLength() }}</b>{{ getWord('item') }} {{ getWord('total4') }}: <b>{{ Number(this.calcAmount()).toFixed(2) }}</b>元</h6>
            <footer>
              <button @click="toggleAside('orderComfirm')">{{ getWord('cancel') }}</button>
              <button
                @click="orderAuctionByUI()"
                :disabled="disabled"
              >{{ getWord('confirm3') }}</button>
            </footer>
          </div>
        </template>
        <template v-if="_TEMPLATE==='template-3' && (gameType!=='wanneng'&&gameType!=='damacai'&&gameType!=='duoduo'&&gameType!=='yuedawan'&&gameType!=='shaba88'&&gameType!=='saimahui'&&gameType!=='xinjapowz')">
          <div
            class="inner sure_bets_box"
            id="order-confirm"
            ref="orderComfirm"
          >
            <h4>
              {{ getWord(['order','information']) }}
              <span>{{ getWord('number') }}{{currentDrawing}}{{ getWord('period') }}</span>
            </h4>
            <table>
              <thead>
                <tr>
                  <th>{{ getWord('number_of_item') }}</th>
                  <th>{{ getWord('odds_of_item') }}</th>
                  <th>{{ getWord('amount_of_betting') }}</th>
                  <th>{{ getWord('operation') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in selectAry">
                  <td v-if="item.originalTitle && item.originalTitle!==''">{{item.originalTitle}}</td>
                  <td v-else-if="item.title && item.title!==''">{{item.title}}</td>
                  <td v-else>{{item.name}}</td>
                  <td>{{item.odds}}</td>
                  <td>{{Number(amount).toFixed(2)}}</td>
                  <td @click="deleteEntry(item)"><i class="iconfont icon-delete"></i></td>
                </tr>
              </tbody>
            </table>
            <h6>{{ getWord('total') }}<b>{{countOfCombinationBet!==0?countOfCombinationBet:selectAry.length}}</b>{{ getWord('order2') }} {{ getWord('total') }}<b>{{Number(this.totalAmount).toFixed(2)}}</b>{{ currencySymbol() }}</h6>
            <footer>
              <button @click="toggleAside('orderComfirm')">{{ getWord('cancel') }}</button>
              <button
                @click="orderAuctionByUI()"
                :disabled="disabled"
              >{{ getWord('ok') }}</button>
            </footer>
          </div>
        </template>
      </aside>
    </transition>
    <transition name="slide-fade">
      <aside
        class="guide"
        v-if="aside.asideGameRule"
        :class="{lhc:gameType.indexOf('lhc')===0}"
      >
        <div class="inner">
          <h4>
            <b v-if='gameType==="lhc"'>香港六合彩</b>
            <b v-else-if='gameType==="hljssc"'>黑龙江时时彩</b>
            <b v-else-if='gameType==="tjssc"'>天津时时彩</b>
            <b v-else-if='gameType==="sscone"'>一分时时彩</b>
            <b v-else-if='gameType==="sscthree"'>三分时时彩</b>
            <b v-else-if='gameType==="sscfive"'>五分时时彩</b>
            <b v-else-if='gameType==="scone"'>一分赛车</b>
            <b v-else-if='gameType==="scthree"'>三分赛车</b>
            <b v-else-if='gameType==="scfive"'>五分赛车</b>
            <b v-else-if='gameType==="fastthreeone"'>极速快三</b>
            <b v-else-if='gameType==="fastthreethree"'>三分快三</b>
            <b v-else-if='gameType==="fastthreefive"'>幸运快三</b>
            <b v-else-if='gameType==="fastthreeten"'>十分快三</b>
            <b v-else-if='gameType==="pc28one"'>一分28</b>
            <b v-else-if='gameType==="tencent"'>腾讯分分彩</b>
            <b v-else-if='gameType==="shfastthree"'>上海快三</b>
            <b v-else-if='gameType==="jsfastthree"'>江苏快三</b>
            <b v-else-if='gameType==="hbfastthree"'>湖北快三</b>
            <b v-else-if='gameType==="gdfiveselect"'>广东11选5</b>
            <b v-else-if='gameType==="sdfiveselect"'>山东11选5</b>
            <b v-else-if='gameType==="jsfiveselect"'>江苏11选5</b>
            <b v-else-if='gameType==="jxfiveselect"'>江西11选5</b>
            <b v-else-if='gameType==="shhfiveselect"'>上海11选5</b>
            <b v-else-if='gameType==="anhfiveselect"'>安徽11选5</b>
            <b v-else-if='gameType==="shxfiveselect"'>陕西11选5</b>
            <b v-else-if='gameType==="fc3d"'>福彩3D</b>
            <b v-else-if='gameType==="gxklsf"'>广西快乐十分</b>
            <b v-else-if='gameType==="gdklten"'>广东快乐十分</b>
            <b v-else-if='gameType==="jndxy"'>加拿大幸运五</b>
            <b v-else-if='gameType==="azxy5"'>澳洲幸运五</b>
            <b v-else-if='gameType==="azxy10"'>澳洲幸运十</b>
            {{ getWord('game_rules') }}
          </h4>
          <content v-html="api.explainList.result">
          </content>
          <footer>
            <button
              class="ripple"
              @click="toggleAside('asideGameRule')"
            >{{ getWord('ok') }}</button>
          </footer>
        </div>
      </aside>
    </transition>
  </div>
</template>
<style type="text/css">
.mint-actionsheet-list {
  max-height: 70vh;
  overflow: auto;
}
</style>
<style lang="scss" scoped>
@import "@@/assets/style/_variables";
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

#auctioneer-ui {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 1;
  &.template-1 .coins img {
    max-height: 40px;
  }
  &.template-2 .coins img {
    max-height: 40px;
    max-width: 40px;
  }
  &.template-3 {
    overflow: auto;
    flex: 1 1 0;

    #bet-types-wrapper {
      height: 100%;
      flex: 1 1 20rem;

      .zhong {
        margin: 0;

        h6 {
          background-color: #ff9726;
          font-size: 0.28rem;
          font-weight: normal;
          color: #ffffff;
        }

        label {
          display: flex;
          justify-content: center;

          span {
            margin: 0;
            margin-right: 0.1rem;
          }
        }
      }

      .custom-type-odd {
        display: flex;
        // align-items: center;
        text-align: center;
        border-bottom: 1px solid #eeeeee;

        h5 {
          width: 50%;
          background-color: #ff9726;
          color: #ffffff;
          font-size: 0.28rem;
          height: auto;
          line-height: 1;
          padding: 0.15rem 0;
          border: 0;
          border-bottom: 1px solid #b0b0b0;
        }

        h6 {
          width: 50%;
          background-color: #eeeeee;
          color: #5f646e;
          font-size: 0.28rem;
          padding: 0.15rem 0;
          font-weight: normal;
          line-height: 1;
          border-bottom: 1px solid #b0b0b0;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            color: #ec2829;
          }
        }
      }

      .custom-header {
        background-color: #ff9726;
        font-size: 0.32rem;
        color: #ffffff;
        display: flex;
        text-align: center;
        padding: 0.1rem 0;

        b {
          width: 20%;
          font-weight: normal;
        }

        span {
          width: 30%;
        }

        .normal {
          width: 50%;
        }

        .large {
          width: 70%;
        }
      }

      &.ttc {
        ul {
          &.numberlist {
            &:before {
              content: none;
            }
          }
        }
      }

      &.wanneng {
        padding: 0;

        h4 {
          color: #ffffff;
          background-color: #ffae00;
          line-height: 1.4;
          padding: 0.1rem;

          span {
            line-height: 2;
          }
        }

        button {
          background-color: #ec2829;
        }

        .list {
          background-color: #f2f2f2;
          border: 0;
          border-radius: 0;
          margin-bottom: 0;

          li {
            border-top: 1px solid #e6e6e6;
          }
        }

        .level-1 {
          padding-top: 0;
          border-bottom: 0;
          margin-bottom: 0.15rem;
        }

        .list-wrapper {
          width: 100%;
        }

        .level-3 {
          border-left: 0;
          border-right: 0;

          h4 {
            color: #ffffff;
            border-radius: none;
          }

          .tr {
            .th {
              color: #ffae00;
            }
          }
        }

        .submit {
          background-color: #000000;
          color: #ffffff;
          display: flex;
          justify-content: space-between;
          padding: 0.25rem;
          align-items: center;
          width: 100%;
          box-sizing: border-box;

          h6 {
            font-size: 0.24rem;
          }

          button {
            margin: 0;
            width: auto;
            padding: 0.15rem 0.25rem;
            font-size: 0.24rem;
            line-height: 0.24rem;
            background-color: #ffb400;
            color: #333333;
            border-radius: 0.1rem;
          }
        }

        .numbers {
          input {
            color: #ffae00;
            border: 1px solid #ffae00;
            box-shadow: 1px 1px 0px 1px rgba(255, 174, 0, 0.2);

            &::-webkit-input-placeholder {
              /* Chrome/Opera/Safari */
              color: #ffae00;
            }
          }
        }
      }

      &.xync {
        .bet-types {
          &.numberlist {
            &:before {
              content: none;
            }
          }

          li {
            .title-wrap {
              b {
                img {
                  width: 0.7rem;
                }
              }
            }
          }
        }
      }

      &.fc3d {
        ul {
          &.numberlist {
            &:before {
              content: none;
            }

            li {
              .title {
                box-shadow: inset 0px 0px 4px 2px #ec2829;
              }
            }
          }
        }
      }

      &.klten {
        &[current-class="第一球"],
        &[current-class="第二球"],
        &[current-class="第三球"],
        &[current-class="第四球"],
        &[current-class="第五球"],
        &[current-class="第六球"],
        &[current-class="第七球"],
        &[current-class="第八球"] {
          ul {
            &.bet-types {
              &.dtclassic {
                li {
                  &:nth-child(6n + 0) {
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }

        ul {
          &.bet-types {
            &.numberlist {
              &:before {
                content: none;
              }
            }
          }
        }
      }

      &.klsf {
        &[current-class="第一球"],
        &[current-class="第二球"],
        &[current-class="第三球"],
        &[current-class="第四球"],
        &[current-class="第五球"] {
          ul {
            &.bet-types {
              &.dtclassic {
                li {
                  &:nth-child(6n + 0) {
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }
      }

      &.gdfiveselect {
        .bet-types {
          &.gdfiveselectNumberList {
            &:before {
              content: none;
            }
          }
        }
      }

      &.pk10 {
        .bet-types {
          li {
            &.number {
              .title {
                border-radius: 3px;
                width: 0.48rem;
                height: 0.48rem;
                line-height: 0.48rem;
                font-size: 0.3rem;
                margin-top: 0.11rem;

                &.tinting {
                  color: #ffffff;
                  -webkit-text-stroke: 0.3px #333333;
                  background-color: var(--data-color);
                }
              }
            }
          }
        }
      }

      .bet-types {
        &.fc3d {
          &[title="二字组合"],
          &[title="跨度"] {
            &:before {
              content: none;
            }
          }

          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            width: 100%;
            float: none;
            padding-left: 0;
            padding-right: 0;
          }

          &:nth-child(4) {
            padding-left: 0;
          }

          &:nth-child(7) {
            clear: both;
          }

          li {
            &.number {
              .title {
                box-shadow: inset 0px 0px 4px 2px #ec2829;
              }
            }
          }
        }

        &.lhc {
          &:before {
            content: none;
          }

          li {
            &.zodiac {
              margin: 0;
              padding: 0;
              width: 100%;
              border-bottom: 1px solid #ff9726;
              background-color: #ffffff;

              &.highlight {
                p {
                  i {
                    color: #000000;
                  }
                }
              }

              .title-wrap {
                width: 20%;
              }

              span {
                width: 30%;
              }

              p {
                width: 50%;
                border-right: 1px solid #ff9726;
                margin-bottom: 0;
                height: 0.72rem;
                display: flex;
                align-items: center;
                justify-content: center;

                &.type3 {
                  .bottom {
                    display: flex;
                    align-items: center;

                    h6 {
                      width: 49%;
                      height: 0.4rem;
                    }

                    label {
                      width: 50%;
                      height: 0.4rem;
                      color: #ff0f0f;
                      text-align: center;
                      font-weight: bold;
                    }
                  }
                }

                &.type2 {
                  flex-direction: column;

                  i {
                    background-size: contain;
                    background-repeat: no-repeat;
                  }

                  &.red {
                    background-image: url("/static/assets/template-3/images/tradingfloor/icons/lhc-number-red.png");
                  }

                  &.blue {
                    background-image: url("/static/assets/template-3/images/tradingfloor/icons/lhc-number-blue.png");
                  }

                  &.green {
                    background-image: url("/static/assets/template-3/images/tradingfloor/icons/lhc-number-green.png");
                  }
                }
              }
            }

            &:nth-child(10),
            &:nth-child(12),
            &:nth-child(18),
            &:nth-child(20),
            &:nth-child(24),
            &:nth-child(30),
            &:nth-child(40),
            &:nth-child(42),
            &:nth-child(48),
            &:nth-child(49),
            &:nth-child(54),
            &:nth-child(60) {
              margin-right: 0;
            }

            &.highlight {
              background-color: #edb93f;

              .title {
                color: #000000 !important;
              }
            }

            &.banbo {
              width: 100%;
              padding: 0;
              border-bottom: 1px solid #ff9726;
              background-color: #ffffff;

              &.highlight {
                background-color: #edb93f;

                span {
                  color: #e83535 !important;
                }
              }

              .title-wrap {
                width: 70%;

                .title {
                  width: auto;
                  font-size: 0.3rem;
                }
              }

              span {
                width: 30%;
              }
            }

            &.number {
              .title {
                background-size: contain;
                background-repeat: no-repeat;
                box-shadow: none;

                &.red {
                  background-image: url("/static/assets/template-3/images/tradingfloor/icons/lhc-number-red.png");
                }

                &.blue {
                  background-image: url("/static/assets/template-3/images/tradingfloor/icons/lhc-number-blue.png");
                }

                &.green {
                  background-image: url("/static/assets/template-3/images/tradingfloor/icons/lhc-number-green.png");
                }
              }
            }
          }
        }

        &.dtclassic,
        &.kuai3 {
          padding: 0;

          &:before {
            position: static;
            display: block;
            text-align: center;
            font-size: 0.32rem;
            background-color: #ff9726;
            color: #ffffff;
            padding: 0.1rem 0;
          }

          li {
            width: 50%;
            height: auto;
            margin: 0;

            .title {
              margin-top: 0;
            }
          }
        }

        &.kuai3 {
          &.full {
            li {
              width: 100%;
            }
          }

          &.smaller {
            li {
              width: 50%;
            }
          }

          li {
            border-radius: 0;

            .title-wrap {
              display: flex;
              justify-content: center;
              align-items: center;

              .dice {
                margin: 0 0.1rem;
                width: auto;
              }

              .title {
                font-size: 0.3rem;
                margin: 0;
              }
            }
          }
        }

        li {
          border-radius: 0;
          box-shadow: 0px 0px 0px 1px rgba(255, 151, 38, 1);
          display: flex;
          align-items: center;

          &.highlight {
            background-color: rgba(237, 185, 63, 1);

            strong {
              i {
                color: #333333;

                &:before {
                  opacity: 1;
                }
              }
            }
          }

          &.number {
            .title {
              box-shadow: inset 0px 0px 4px 2px var(--data-color);
              width: 0.72rem;
              border-radius: 100%;
              font-size: 0.36rem;
              color: #000000;
              margin: 0 auto;
            }
          }

          .title-wrap {
            width: 40%;
            border-right: 1px solid #ff9726;
            height: 0.72rem;
          }

          .title {
            font-size: 0.3rem;
            line-height: 0.72rem;
            white-space: nowrap;
            overflow: hidden;
          }

          span {
            text-align: center;
            width: 60%;
            font-weight: bold;
            font-size: 0.3rem;
          }

          strong {
            width: 60%;

            i {
              border-radius: 0;
              width: 0.36rem;
              height: 0.36rem;
              background-color: #e0e0e0;
              font-size: 0.28rem;
              line-height: 0.4rem;

              &:before {
                opacity: 0;
              }
            }
          }
        }

        &.column2 {
          width: 100%;
        }
      }

      > ul {
        &.zodiac {
          padding: 0;

          li {
            margin: 0;
            padding: 0;
            width: 100%;
            float: none;
            display: flex;
            border-bottom: 1px solid #ff9726;
            height: 0.72rem;
            align-items: center;
            background-color: #ffffff;

            &.highlight {
              background-color: #edb93f;

              strong {
                i {
                  color: #333333;

                  &:before {
                    opacity: 1;
                  }
                }
              }
            }

            &:nth-child(2n + 1) {
              margin: 0;
            }

            b {
              height: 0.72rem;
              margin-top: 0;
              width: 20%;
              border-right: 1px solid #ff9726;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            p {
              height: 0.72rem;
              width: 50%;
              border-right: 1px solid #ff9726;
              margin-bottom: 0;
              display: flex;
              align-items: center;
              justify-content: center;

              i {
                color: #000000;
                background-size: contain;
                background-repeat: no-repeat;

                &.red {
                  background-image: url("/static/assets/template-3/images/tradingfloor/icons/lhc-number-red.png");
                }

                &.blue {
                  background-image: url("/static/assets/template-3/images/tradingfloor/icons/lhc-number-blue.png");
                }

                &.green {
                  background-image: url("/static/assets/template-3/images/tradingfloor/icons/lhc-number-green.png");
                }
              }
            }

            strong {
              width: 30%;

              i {
                border-radius: 0;
                width: 0.36rem;
                height: 0.36rem;
                background-color: #e0e0e0;
                font-size: 0.28rem;
                line-height: 0.4rem;

                &:before {
                  opacity: 0;
                }
              }
            }
          }
        }
      }
    }

    #bet-coins {
      padding: 0;
      flex: 1 1 auto;

      .coins {
        background-color: #b0b0b0;
        color: #5f646e;
        align-items: center;
        margin-bottom: 0;

        label {
          padding: 0 0.4rem;
          white-space: nowrap;
        }

        input {
          width: 80%;
          border-radius: 0;
        }
      }

      .order {
        background-color: #171719;
        color: #ffffff;
        padding: 0.15rem 0.25rem;
        justify-content: space-between;
        flex-wrap: wrap-reverse;

        a {
          padding: 0.1rem 0.25rem;
          background-color: #464646;
          color: #ffffff;
          border-color: transparent;
          font-size: 0.28rem;
        }

        button {
          border: 0;
          background-color: #ffb400;
          color: #333333;
          padding: 0.15rem 0.25rem;
        }

        span {
          flex-grow: 0;
          margin-left: 0.25rem;
        }
      }
    }

    aside {
      .inner {
        width: 90%;

        &#order-confirm {
          h4 {
            background-color: #db5c54;
            color: #ffffff;
            font-size: 0.4rem;
            padding: 0.15rem;

            span {
              color: #ffffff;
              font-size: 0.28rem;
            }
          }

          h6 {
            background-color: #fbf7e2;
            text-align: center;
            font-size: 0.3rem;
            padding: 0.15rem;
          }

          footer {
            padding: 0.25rem;
            width: auto;
            justify-content: space-between;
            display: block;

            button {
              background-color: #db5c54;
              font-size: 0.3rem;
              color: #ffffff;
              border: 0;
              flex-grow: 0;
              width: 48%;

              &:first-child {
                background-color: #eeeeee;
                color: #5f646e;
              }

              &:last-child {
                float: right;
              }
            }
          }

          table {
            th {
              background-color: #fbf7e2;
              font-size: 0.36rem;
              color: #333333;
              padding: 0.1rem 0;
            }

            td {
              padding: 0.15rem 0;
            }

            tbody {
              tr {
                &:nth-child(even) {
                  td {
                    background-color: #eeeeee;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.template-2 {
    &.coverd {
      &:before {
        content: "";
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 3;
      }
    }

    > i {
      background: rgb(140, 186, 241);
      background: linear-gradient(
        90deg,
        rgba(140, 186, 241, 1) 0%,
        rgba(70, 119, 227, 1) 100%
      );
      margin: 0;
      line-height: 0.8rem;
      font-size: 0.5rem;
      color: #ffffff;
      padding-top: 0.1rem;
      text-align: center;

      &:before {
        margin-right: 0.25rem;
        float: right;
      }

      span {
        font-size: 0.42rem;
        position: relative;
        top: -0.025rem;
        left: 0.45rem;
      }
    }

    #bet-types-wrapper {
      .zhong {
        &.type1 {
          label {
            display: flex;
            align-items: center;
            white-space: nowrap;
          }
        }
      }

      &.wanneng {
        padding: 0;
        background-color: #f5f5f9;
        display: block;

        .level-1 {
          padding-top: 0.25rem;
          margin-top: 0.25rem;
          background-color: #ffffff;
          border-bottom: 0;
          margin-bottom: 0;
        }

        .list-wrapper {
          background-color: #ffffff;
          padding: 0 0.25rem;
          padding-bottom: 0.25rem;
          margin-bottom: 0.25rem;

          .list {
            margin-bottom: 0;
          }
        }

        .level-2 {
          padding: 0.25rem;
          background-color: #ffffff;
        }

        .level-3 {
          box-sizing: border-box;
          padding: 0.25rem;
          background-color: #ffffff;
          border-radius: 0;
          border: 0;

          .inner {
            border: 1px solid #e6e6e6;
            border-top: 0;
          }
        }

        .submit {
          background-color: #ffffff;
          color: #aaaaaa;
          display: flex;
          justify-content: space-between;
          min-height: 1rem;

          h6 {
            font-size: 0.26rem;
            padding-left: 0.25rem;
            display: flex;
            flex-direction: column;

            span {
              display: flex;
              align-items: center;
            }

            b {
              font-size: 0.36rem;
              color: #bf2e1b;
              margin: 0 0.1rem;
            }
          }

          button {
            margin: 0;
            width: auto;
            min-width: 2rem;
            max-width: 40%;
            font-size: 0.36rem;
            color: #ffffff;
            line-height: 1.3;
            background: rgb(254, 176, 121);
            background: linear-gradient(
              180deg,
              rgba(254, 176, 121, 1) 0%,
              rgba(252, 90, 29, 1) 100%
            );
            border-radius: 0;
            word-break: break-all;
            padding: 0.1rem;
          }
        }
      }

      &.fc3d {
        .zhong {
          .bottom {
            display: flex;
            align-items: center;

            h6 {
              width: 40%;
            }

            label {
              color: #ff0f0f;
              width: 60%;
            }
          }
        }
      }

      nav {
        border-color: #cccccc;

        a {
          color: #252525;
          border-left-color: #cccccc;
          font-size: 0.28rem;
          font-weight: bold;
          padding: 0.05rem;

          &.highlight {
            background-color: #ffff00;
            color: #252525;
          }
        }
      }

      > .zodiac {
        padding: 3vw;

        li {
          border: 1px solid rgba(67, 147, 255, 0.5);
          box-shadow: 0px 1.5px 2px rgba(67, 147, 255, 0.3);
          border-radius: 4px;
          background-color: transparent;
          padding: 0.1rem;
          box-sizing: border-box;

          &:nth-child(2n + 1) {
            margin: 0 3.3vw 3vw 0;
          }

          &.highlight {
            background-color: #a0c9ff;

            .title {
              color: #ffffff;
            }
          }

          p {
            i {
              // border: 1px solid #CCCCCC;
              // box-shadow: none;
            }
          }

          .title {
            margin-top: 0;
          }
        }
      }

      &.xync {
        .bet-types {
          &.lhcnumberlist {
            li {
              b {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
              }
            }
          }

          li {
            b {
              img {
                width: 0.6rem;
              }
            }
          }
        }
      }

      .bet-types {
        &.pc28 {
          li {
            border-radius: 4px;
            box-shadow: 0px 2px 2px rgba(67, 147, 255, 0.3);
            height: 1.4rem;
            overflow: hidden;

            &.highlight {
              background: rgb(255, 229, 141);
              background: linear-gradient(
                180deg,
                rgba(255, 229, 141, 1) 0%,
                rgba(255, 255, 254, 1) 100%
              );

              .title {
                color: #252525 !important;
              }

              span {
                color: #999999 !important;
              }
            }

            &:nth-child(11),
            &:nth-child(12),
            &:nth-child(13),
            &:nth-child(14),
            &:nth-child(15),
            &:nth-child(16) {
              height: 1.4rem;

              .title {
                // font-size: .36rem;
              }
            }

            &:nth-child(1n + 17) {
              border-radius: 100%;
              width: 0.92rem;
              height: 0.92rem;
              margin-right: 0.09rem;
              margin-bottom: 0.2rem;
              padding-top: 0.1rem;
              box-sizing: border-box;
            }

            &:nth-child(5),
            &:nth-child(10),
            &:nth-child(16),
            &:nth-child(23),
            &:nth-child(30),
            &:nth-child(37),
            &:nth-child(44) {
              margin-right: 0;
            }

            span {
              color: #999999;
            }

            .title {
              font-weight: 400;
              line-height: 1;
              font-size: 0.34rem;
              word-break: break-all;
            }
          }
        }

        &.dtclassic {
          li {
            border-radius: 4px;
            box-shadow: 0px 2px 3px rgba(67, 147, 255, 0.3);
            border: 1px solid rgba(67, 147, 255, 0.5);
            box-sizing: border-box;
            min-height: 1rem;
            height: auto;
            overflow: hidden;

            &:nth-child(6n + 0) {
              margin-right: 0.2rem;
            }

            span {
              color: #ff0f0f;
              font-weight: bold;
            }

            .title {
              font-size: 0.3rem;
              color: #6e6e6e;
              white-space: nowrap;
            }
          }
        }

        &.lhc {
          li {
            &.lianma {
              height: auto;
              border: 1px solid #cccccc;
              border-radius: 0;

              &:nth-child(6n + 0) {
                margin-right: 0;
              }

              .top {
                color: $TEMPLATE2-theme-color;
                border-bottom: 1px solid #cccccc;
                padding: 0.1rem 0 0.3rem;
                background-color: #e4f2fa;
                flex: 0 0 39.25%;

                .radio {
                  width: 0.3rem;
                  height: 0.3rem;
                  border-radius: 100%;
                  line-height: 0.3rem;
                  text-align: center;
                  border: 1px solid rgba(204, 204, 204, 1);
                  background-color: #dedede;
                  position: relative;
                  margin: 0 auto;
                }

                b {
                  display: block;
                  margin-top: 0.1rem;
                  word-break: break-all;
                }
              }

              .bottom {
                padding: 0.3rem 0;
              }
            }

            .title {
              &.number {
                margin-top: 0.06rem;
                display: inline-block;
                text-align: center;
                width: 0.5rem;
                height: 0.5rem;
                line-height: 0.5rem;
                border-radius: 100%;
                border: 1px solid #cccccc;
              }
            }

            span {
              display: block;
            }

            &:nth-child(16),
            &:nth-child(26),
            &:nth-child(46),
            &:nth-child(55),
            &:nth-child(61) {
              margin-right: 0.2rem;
            }

            &:nth-child(10),
            &:nth-child(20),
            &:nth-child(30),
            &:nth-child(40) {
              margin-right: 0.2rem;
            }

            &:nth-child(49) {
              margin-right: 1.4rem;
            }

            &.highlight {
              background-color: #a0c9ff;

              &.lianma {
                background-color: transparent;

                .top {
                  .radio {
                    &:before {
                      content: "";
                      width: 0.14rem;
                      height: 0.14rem;
                      background-color: #666666;
                      border-radius: 100%;
                      position: absolute;
                      top: 0;
                      right: 0;
                      left: 0;
                      bottom: 0;
                      margin: auto;
                    }
                  }
                }
              }

              .title {
                &.number {
                  background-color: var(--data-bg-color);
                }
              }
            }

            &.zodiac {
              background-color: #ffffff;
              padding: 0.1rem;

              p {
                margin-bottom: 0;
              }

              .title {
                font-size: 0.24rem;
                color: #ff0f0f;
                margin-top: 0;
              }

              &.highlight {
                background-color: #a0c9ff;
              }

              &:nth-child(6n + 0),
              &:nth-child(10),
              &:nth-child(12) {
                margin-right: 0;
              }

              span {
                display: inline;
                color: #252525;
              }
            }

            &.banbo {
              height: auto;
              padding: 0.1rem;
              background-color: transparent;

              i {
                color: var(--data-color);
              }

              p {
                margin-bottom: 0;
              }

              .title {
                margin-top: 0;
                font-size: 0.24rem;
              }

              span {
                display: inline;
              }

              &.highlight {
                background-color: #a0c9ff;

                i {
                  color: #ffffff;
                }

                .title,
                span {
                  color: #ffffff !important;
                }
              }
            }
          }
        }

        &.gxklsf {
          li {
            .title {
              &.small {
                margin-top: 0.06rem;
              }
            }
          }
        }
      }
    }

    #bet-coins {
      background-color: #27485c;
      position: relative;
      z-index: 2;

      &.type2 {
        background-color: rgba(39, 72, 92, 0.9);

        .order {
          display: flex;

          a {
            background-color: #ff7b00;
            border-radius: 5px;
            margin-left: 0;
            vertical-align: middle;
            width: 1.5rem;
            overflow: hidden;
            word-break: break-all;
            height: 0.7rem;
            padding: 0.1rem;
            text-align: center;
            line-height: 1;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0.15rem;
          }

          button {
            position: absolute;
            height: 1.55rem;
            top: 0.2rem;
            background: linear-gradient(
              180deg,
              rgba(122, 202, 254, 1) 0%,
              rgba(34, 133, 252, 1) 100%
            );
            font-size: 0.36rem;
            right: 0.2rem;
            border: 0;
            border-radius: 5px;
            width: 1.8rem;
            word-break: break-all;
            overflow: hidden;
            line-height: 1;
            padding: 0.15rem;
          }

          > span {
            float: none;
            color: #cccccc;
            width: 1rem;
            display: flex;
            flex-direction: column;
            line-height: 1;
            margin-left: 0.1rem;
            text-align: left;
            justify-content: flex-start;
            align-items: flex-start;

            b {
              color: #ffffff;
              font-size: 0.28rem;
              margin-top: 0.1rem;
            }
          }

          label {
            position: relative;
            display: inline-block;
            vertical-align: middle;

            span {
              position: absolute;
              left: 0.1rem;
              font-size: 0.28rem;
              color: #252525;
              top: 0.2rem;
              width: 0.5rem;
              text-align: right;
            }

            input {
              font-size: 0.28rem;
              padding: 0.16rem;
              box-sizing: border-box;
              border: 1px solid #e6e6ea;
              width: 1.9rem;
              float: left;
              border-radius: 4px;
              padding-left: 0.6rem;

              &::placeholder {
                color: #cccccc;
              }
            }
          }
        }
      }

      .order {
        display: flex;
        margin-top: -0.2rem;
        margin-bottom: 0.1rem;
        align-items: unset;

        button {
          font-size: 0.3rem;
          background-color: #ff7b00;
          padding: 0.1rem;
          border-radius: 0;
          word-break: break-all;
          min-width: 1.2rem;
          max-width: 2rem;
        }

        a {
          background-color: #ff0f0f;
          border-radius: 0;
          color: #ffffff;
          border: 0;
          margin: 0 0.15rem;
          word-break: break-all;
          min-width: 1rem;
          max-width: 2rem;
          padding: 0.1rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        span {
          color: #ffffff;
          text-align: right;
          line-height: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          b {
            color: #ff0f0f;
          }
        }
      }

      .coins {
        .inner {
          width: 5rem;
          overflow: auto;
          height: 1.1rem;
          position: relative;
          top: -0.1rem;
          white-space: nowrap;

          img {
            margin-top: 0.1rem;
            flex-basis: 1rem;
          }
        }

        label {
          position: relative;

          span {
            position: absolute;
            left: 0.125rem;
            font-size: 0.26rem;
            color: #252525;
            top: 0.15rem;
            text-align: right;
            width: 0.4rem;
          }

          input {
            padding-left: 0.5rem;
            width: 1.9rem;

            &::placeholder {
              color: #999999;
            }
          }
        }
      }
    }

    aside {
      .inner {
        max-height: none;
      }

      h4 {
        font-size: 0.36rem;
        color: #ffffff;
        background: rgb(143, 194, 255);
        background: linear-gradient(
          90deg,
          rgba(143, 194, 255, 1) 0%,
          rgba(42, 97, 235, 1) 100%
        );

        img {
          height: 0.28rem;
          vertical-align: middle;
        }
      }

      h6 {
        font-size: 0.24rem;
      }

      table {
        td {
          font-size: 0.24rem;
        }

        th {
          background-color: transparent;
          font-weight: normal;
        }
      }

      footer {
        button {
          font-size: 0.3rem;

          &:last-child {
            color: #ff0f0f;
          }
        }
      }
    }
  }

  &.template-1 {
    .sure_bets_box {
      footer {
        display: block;

        button {
          text-align: center;
          width: 50%;

          &:last-child {
            float: right;
          }
        }
      }
    }
  }

  > i {
    font-size: 0.4rem;
    display: block;
    text-align: right;
    margin: 0.2rem 0.2rem 0 0;
  }

  #bet-types-wrapper {
    flex: 1 1 auto;
    overflow: auto;

    &.wanneng {
      flex-direction: column;
      align-items: center;
      padding: 0 0.25rem;
      display: flex;
      flex-direction: column;

      h4 {
        font-weight: normal;
        color: #6e6e6e;
        font-size: 0.3rem;
        text-align: center;

        span {
          font-size: 0.24rem;
          color: #ff0f0f;
          display: block;
        }
      }

      button {
        background-color: $TEMPLATE2-theme-color;
        border: 0;
        color: #ffffff;
        line-height: 0.88rem;
        margin: 0.25rem auto;
        display: block;
        width: 100%;
        font-size: 0.3rem;
        border-radius: 0.15rem;
      }

      .list {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #b8d6ff;
        background-color: #f9fcff;
        border-radius: 0.15rem;
        margin-bottom: 0.25rem;
        width: 100%;

        li {
          text-align: center;
          width: 25%;
          font-size: 0.34rem;
          border-top: 1px solid #b8d6ff;
          color: #738091;
          line-height: 0.75rem;

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            border: 0;
          }
        }
      }

      .level-1 {
        padding: 0.1rem 0;
        border-bottom: 1px solid #a0c9ff;
        margin-bottom: 0.25rem;
        width: 100%;
      }

      .level-2 {
        margin-bottom: 0.25rem;
      }

      .level-3 {
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 0.3rem;
        border: 1px solid #e6e6e6;
        border-top: 0;
        border-radius: 0.15rem;
        flex-grow: 1;

        &.wan {
          .tr {
            .td,
            .th {
              width: 20%;
            }

            .th {
              color: #5b2900;
              background-color: #fff6e2;
            }
          }
        }

        h4 {
          background-color: #ffae00;
          color: #5b2900;
          border-radius: 0.15rem 0.15rem 0 0;
          line-height: 0.75rem;
        }

        .tr {
          display: flex;
          border-top: 1px solid #e6e6e6;

          &:first-child {
            border-top: 0;
          }

          .td,
          .th {
            line-height: 0.75rem;
            display: block;
            width: 16.6%;
            text-align: center;

            &:first-child {
              width: 20%;
            }
          }

          .th {
            white-space: nowrap;
          }

          .td {
            color: #6e6e6e;

            &:first-child {
              white-space: nowrap;
            }
          }
        }
      }

      .submit {
        width: 100%;
      }

      .numbers {
        display: flex;
        margin-top: 0.25rem;
        justify-content: center;
        align-items: flex-end;

        &.class4 {
          input {
            width: 3rem;
            display: block;
          }
        }

        label {
          text-align: center;
        }

        input {
          margin: 0.15rem 0.1rem;
          color: $TEMPLATE2-theme-color;
          border: 1px solid $TEMPLATE2-theme-color;
          border-radius: 0.1rem;
          width: 1rem;
          height: 1rem;
          font-size: 0.5rem;
          text-align: center;
          box-shadow: 1px 1px 0px 1px rgba(67, 147, 255, 0.2);

          &:active,
          &:focus {
            border-color: #ffae00;
            outline: none;
          }
        }
      }
    }

    &[current-class="第一球"],
    &[current-class="第二球"],
    &[current-class="第三球"],
    &[current-class="第四球"],
    &[current-class="第五球"],
    &[current-class="第六球"],
    &[current-class="第七球"],
    &[current-class="第八球"] {
      ul.bet-types.dtclassic {
        padding-right: 0;

        &:first-child {
          ~ ul {
            &:before {
              content: none;
            }
          }
        }

        &:after {
          content: none;
        }

        li {
          &:nth-child(6n + 0) {
            margin-right: 0.2rem;
          }
        }
      }
    }

    &.xync {
      .bet-types {
        li {
          b {
            img {
              width: 0.6rem;
            }
          }
        }

        &.lhcnumberlist {
          li {
            .title {
              img {
                width: 1rem;
              }
            }

            &.highlight {
              .title {
                img {
                  filter: drop-shadow(2px 4px 6px black);
                }
              }
            }
          }
        }
      }
    }

    .zhong {
      display: flex;
      justify-content: space-around;
      border: 1px solid #cccccc;
      margin: 0 0.25rem;
      margin-bottom: 0.25rem;

      &.type2 {
        flex-direction: column;

        &.variant {
          h6 {
            flex-grow: 1;
          }

          label {
            b {
              &.restore {
                display: block;
                writing-mode: unset;
                margin-bottom: 0.25rem;
              }
            }

            i {
              display: block;
              font-style: normal;
              color: #ff0f0f;
            }
          }
        }

        .top,
        .bottom {
          display: flex;
        }

        .top {
          h6 {
            display: flex;
            justify-content: center;
            flex-direction: column;
          }

          label {
            background-color: #e4f2fa;
            color: $TEMPLATE2-theme-color;
            min-height: 2rem;

            &.tip {
              min-height: 0;
            }
          }
        }

        .bottom {
          align-items: center;
          border-top: 1px solid #cccccc;

          label {
            flex-grow: 1;
            color: #ff0f0f;
            font-weight: bold;
          }
        }

        label {
          flex-grow: 1;

          span {
            margin: 0 auto;
          }

          b {
            margin-top: 0.1rem;
            writing-mode: tb-rl;
            letter-spacing: 3px;
          }
        }
      }

      > * {
        flex-basis: 100%;
        text-align: center;
      }

      h6 {
        font-size: 0.28rem;
        background-color: #e4f2fa;
        color: $TEMPLATE2-theme-color;
        padding: 0.1rem;
        white-space: pre;
      }

      label {
        padding: 0.1rem;
        display: inline-block;
        border-left: 1px solid #cccccc;

        &.highlight {
          .radio {
            &:before {
              content: "";
              width: 0.14rem;
              height: 0.14rem;
              background-color: #666666;
              border-radius: 100%;
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              margin: auto;
            }
          }
        }

        .radio {
          display: block;
          width: 0.3rem;
          height: 0.3rem;
          border-radius: 100%;
          line-height: 0.3rem;
          text-align: center;
          border: 1px solid rgba(204, 204, 204, 1);
          background-color: #dedede;
          position: relative;
          margin: 0 auto;
          margin-right: 0.25rem;
          vertical-align: middle;
        }
      }

      > span {
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }

    h4 {
      padding: 0 0.35rem;
      font-size: 0.28rem;
      color: #333333;
    }

    h5 {
      font-size: 0.3rem;
      font-weight: 500;
      height: 0.92rem;
      line-height: 0.92rem;
      padding: 0 0.35rem;
      border-bottom: 1px solid #f3f3f4;

      span {
        float: right;
      }
    }

    nav {
      color: #999999;
      display: flex;
      justify-content: space-around;
      border: 1px solid #f3f3f4;
      border-radius: 3px;
      margin: 0.25rem 0.25rem 0;

      a {
        padding: 0.1rem 0;
        border-left: 1px solid #f3f3f4;
        display: block;
        width: 50%;
        text-align: center;

        &:first-child {
          border-left: 0;
        }

        &.highlight {
          color: #3985e1;
          background-color: rgba(57, 133, 225, 0.2);
        }
      }
    }

    .bet-types {
      padding: 3vw;

      &:empty {
        display: none;
      }

      &.kuai3 {
        overflow: initial;
        position: relative;
        padding-top: 0.5rem;
        padding-right: 0;

        &.smaller {
          li {
            width: 1.5rem;
          }
        }

        &:before {
          content: attr(title);
          position: absolute;
          top: 0;
          font-size: 0.28rem;
        }

        li {
          width: 2.1rem;
          height: 1.1rem;
          border-radius: 0.1rem;

          .title {
            font-size: 0.34rem;
            margin-bottom: 0.1rem;
          }

          .dice {
            font-size: 0.56rem;
            margin-top: 0.1rem;
            margin-left: 0.05rem;
            margin-right: 0.05rem;
          }

          span {
            color: #e83535;
          }

          .blockable {
            display: block;
          }
        }
      }

      &.dtclassic {
        overflow: initial;
        position: relative;
        padding-top: 0.5rem;

        &:before {
          content: attr(title);
          position: absolute;
          top: 0;
          font-size: 0.28rem;
          left: 0.25rem;
        }

        li {
          width: 1rem;
          height: 1rem;
          margin: 0 0.2rem 0.2rem 0;

          span {
            color: #e83535;
          }

          &:nth-child(6n + 0) {
            margin-right: 0;
          }

          .title {
            margin-top: 0.06rem;

            &.small {
              margin-top: 0.16rem;
              font-size: 0.3rem;
            }
          }
        }
      }

      &.column2 {
        width: 50%;
        box-sizing: border-box;

        &:nth-child(odd) {
          padding-left: 0;
          float: right;
        }

        &:nth-child(even) {
          padding-right: 0;
          float: left;
        }

        li {
          margin: 0 0.15rem 0.2rem 0;
        }
      }

      &.lhc {
        li {
          .title {
            font-size: 0.34rem;
            margin-top: 0.12rem;
          }

          &:nth-child(12),
          &:nth-child(18),
          &:nth-child(24),
          &:nth-child(42),
          &:nth-child(48),
          &:nth-child(54),
          &:nth-child(60) {
            margin-right: 0.2rem;
          }

          &:nth-child(16),
          &:nth-child(26),
          &:nth-child(46),
          &:nth-child(55),
          &:nth-child(61) {
            margin-right: 0;
          }

          &:nth-child(10),
          &:nth-child(20),
          &:nth-child(30),
          &:nth-child(40) {
            margin-right: 2rem;
          }

          &:nth-child(49) {
            margin-right: 3rem;
          }

          &.highlight {
            background-color: var(--data-bg-color);

            .title,
            span {
              color: #ffffff !important;
            }
          }
        }
      }

      &.lhcnumberlist {
        li {
          line-height: 1rem;

          .title {
            margin-top: 0;
          }
        }
      }

      &.pc28 {
        li {
          &:nth-child(5),
          &:nth-child(10),
          &:nth-child(16),
          &:nth-child(23),
          &:nth-child(30),
          &:nth-child(37),
          &:nth-child(44) {
            margin-right: 0;
          }

          &:nth-child(11),
          &:nth-child(12),
          &:nth-child(13),
          &:nth-child(14),
          &:nth-child(15),
          &:nth-child(16) {
            width: 0.955rem;
            height: 0.955rem;

            .title {
              font-size: 0.32rem;
            }
          }

          &:nth-child(1n + 17) {
            width: 0.78rem;
            height: 0.78rem;

            .title {
              font-size: 0.32rem;
              margin: 0;
            }
          }

          &.highlight {
            background-color: var(--data-bg-color);

            .title,
            span {
              color: #ffffff !important;
            }
          }
        }
      }

      &.transformtotab {
        display: flex;
        border: 1px solid #f3f3f4;
        padding: 0;
        margin: 0 0.25rem;
        margin-top: 0.25rem;

        &:before {
          content: none;
        }

        li {
          box-shadow: none;
          border-radius: 0;
          margin: 0;
          border-left: 1px solid #f3f3f4;
          display: flex;
          height: 1.2rem;
          flex-direction: column;
          justify-content: space-between;
          width: 50%;

          &:first-child {
            border-left: 0;
          }

          .title {
            margin-top: 0;
            font-size: 0.24rem;
          }

          &.highlight {
            background-color: rgba(57, 133, 225, 0.2);

            .title {
              color: #3985e1 !important;
            }

            span {
              color: #e83535 !important;
            }
          }
        }
      }

      &.fc3d {
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          width: 32%;
          float: left;
          padding-left: 0;
          padding-right: 0;
        }

        &:nth-child(4) {
          padding-left: 0.25rem;
        }

        &:nth-child(7) {
          clear: both;
        }
      }

      &.gdfiveselectZhixuan {
        li {
          &.disabled {
            background-color: #d8d8d8;

            b {
              color: #999999 !important;
            }
          }
        }
      }

      &.gxklsf,
      &.gdklten {
        &:first-child {
          z-index: 1;
        }

        li {
          .title {
            &.small {
              margin-top: 0.16rem;
              font-size: 0.3rem;
            }
          }
        }
      }

      li {
        list-style: none;
        float: left;
        width: 1.2rem;
        height: 1.2rem;
        text-align: center;
        margin: 0 0.25rem 0.25rem 0;
        background-color: #ffffff;
        box-shadow: 0px 0px 3px 0px rgba(57, 133, 225, 0.5);
        border-radius: 100%;

        p {
          margin-bottom: 0.05rem;
        }

        i {
          display: inline-block;
          font-style: normal;
          width: 0.52rem;
          height: 0.52rem;
          line-height: 0.52rem;
          background-color: #ffffff;
          box-shadow: 0px 0px 3px 0px rgba(57, 133, 225, 0.5);
          border-radius: 100%;
          margin: 0 0.05rem;
        }

        &.zodiac {
          width: 45vw;
          border-radius: 0;
          margin: 0;
          padding: 0.25rem 0;
          box-sizing: border-box;
          height: auto;
          background-color: #f3f3f4;
          box-shadow: none;

          .title {
            font-size: 0.26rem;
            display: inline-block;
            margin-right: 0.25rem;
          }

          i {
            color: var(--data-color);
          }

          &:nth-child(10),
          &:nth-child(12) {
            margin-right: 0;
          }

          &:nth-child(2n + 1) {
            margin: 0 3.3vw 3vw 0;
          }

          &.highlight {
            background-color: rgba(57, 133, 225, 0.2);

            i {
              background-color: var(--data-color);
              color: #ffffff;
            }

            .title,
            span {
              color: #3985e1 !important;
            }
          }
        }

        &.banbo {
          width: 100%;
          border-radius: 0;
          background-color: #f3f3f4;
          box-shadow: none;
          margin: 0 0 0.25rem;
          padding: 0.25rem 0;

          p {
            i {
              color: var(--data-color);
            }
          }

          .title {
            font-size: 0.26rem;
            display: inline-block;
            margin-right: 0.25rem;
          }

          &.highlight {
            background-color: rgba(57, 133, 225, 0.2);

            .title,
            span {
              color: #3985e1 !important;
            }

            p {
              i {
                background-color: var(--data-color);
                color: #ffffff;
              }
            }
          }
        }

        &.highlight {
          background-color: rgba(57, 133, 225, 0.2);
        }

        .title {
          margin: 0.12rem 0 0 0;
          font-size: 0.42rem;
          font-weight: 500;
          display: block;
        }
      }
    }

    > ul.zodiac {
      list-style: none;
      padding: 0.25rem;

      li {
        float: left;
        width: 45vw;
        border-radius: 0;
        margin: 0;
        padding: 0.25rem 0;
        box-sizing: border-box;
        height: auto;
        background-color: #f3f3f4;
        box-shadow: none;
        text-align: center;

        p {
          margin-bottom: 0.05rem;
        }

        i {
          display: inline-block;
          font-style: normal;
          width: 0.52rem;
          height: 0.52rem;
          line-height: 0.52rem;
          background-color: #ffffff;
          box-shadow: 0px 0px 3px 0px rgba(57, 133, 225, 0.5);
          border-radius: 100%;
          margin: 0 0.05rem;
          color: var(--data-color);
        }

        b {
          font-size: 0.26rem;
          display: inline-block;
          margin-right: 0.25rem;
        }

        &:nth-child(2n + 1) {
          margin: 0 0.25rem 0.25rem 0;
        }

        &.highlight {
          background-color: rgba(199, 223, 254, 0.5);

          i {
            background-color: var(--data-color);
            color: #ffffff;
          }
        }

        .title {
          margin: 0.12rem 0 0 0;
          font-size: 0.26rem;
          font-weight: 500;
          display: block;
        }
      }
    }
  }

  #bet-coins {
    padding: 0.125rem 0.25rem;
    border-top: 1px solid #f3f3f4;
box-shadow: 0px -1px 6px #9fa0a3;
    .coins {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 0.125rem;

      input {
        font-size: 0.28rem;
        padding: 0.16rem;
        box-sizing: border-box;
        border: 1px solid #e6e6ea;
        width: 1.68rem;
        float: left;
        border-radius: 4px;

        &::placeholder {
          color: #cccccc;
        }
      }

      img {
        width: 1rem;
        height: 1rem;
        vertical-align: bottom;
        margin-top: -0.1rem;
        position: relative;

        &.active {
          top: -0.1rem;
        }
      }

      a {
        background-color: #ed7428;
        font-size: 0.28rem;
        color: #ffffff;
        border-radius: 4px;
        padding: 0.15rem;
        float: right;
      }
    }

    .order {
      display: flex;
      align-items: center;

      span {
        flex-grow: 1;
        line-height: 0.7rem;
        font-size: 0.3rem;

        b {
          font-weight: 500;
          color: #e83535;
        }
      }

      a {
        display: inline-block;
        color: #999999;
        border: 1px solid #e6e6ea;
        border-radius: 4px;
        padding: 0.15rem 0.41rem;
        margin: 0 0.25rem;
        font-size: 0.3rem;
      }

      button {
        border: 1px solid transparent;
        border-radius: 4px;
        color: #ffffff;
        font-size: 0.26rem;
        background-color: #3985e1;
        padding: 0.15rem 0.18rem;
      }
    }
  }

  aside {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;

    .scrolled-block {
      overflow: auto;
      max-height: 40vh;
    }

    &.lhc {
      .inner {
        display: flex;
        flex-direction: column;
        width: 85vw;
      }

      content {
        display: block;
        min-height: 10vh;
        border-top: 1px solid #f3f3f4;
        padding: 0.25rem 0.75rem;
        font-size: 0.3rem;
        flex-grow: 1;
        overflow: auto;
      }

      footer {
        display: block;

        button {
          width: 100%;
        }
      }
    }

    &.guide {
      .inner {
        content {
          padding: 0.25rem;
          overflow: auto;
        }

        footer {
          display: block;
          text-align: center;
        }
      }
    }

    .inner {
      position: relative;
      width: 5.75rem;
      border-radius: 5px;
      background-color: #ffffff;
      box-shadow: 0px 1px 10px 0 rgba(0, 0, 0, 0.2);
      max-height: 67.5vh;
      margin-top: 27.5vh;
      overflow: auto;
      display: flex;
      flex-direction: column;
    }

    h4 {
      text-align: center;
      font-size: 0.3rem;
      font-weight: 500;
      padding: 0.25rem;

      span {
        font-size: 0.24rem;
        display: block;
        color: #999999;
      }
    }

    h6 {
      font-size: 0.28rem;
      font-weight: 500;
      text-align: right;
      padding: 0.25rem;

      b {
        font-weight: 500;
        color: #e83535;
      }
    }

    table {
      width: 100%;
      padding: 0 0.25rem;
      text-align: center;
      font-size: 0.28rem;
      border-collapse: collapse;
      box-sizing: border-box;

      tr {
        border-bottom: 1px solid #f3f3f4;
      }

      th {
        border: 1px solid #ebeaf1;
        border-left: 0;
        border-right: 0;
        font-size: 0.24rem;
        background-color: #f3f3f4;
        color: #999999;
        padding: 0.2rem 0;
      }

      td {
        padding: 0.2rem 0;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0 1rem;
      list-style: none;
      justify-content: space-between;

      li {
        filter: brightness(0.5);
        margin-bottom: 0.125rem;

        &.highlight {
          filter: none;
        }

        img {
          width: 0.88rem;
          height: 0.88rem;
        }
      }
    }

    footer {
      display: flex;
      width: 100%;
      border-top: 1px solid #f3f3f4;

      button {
        font-size: 0.32rem;
        flex-grow: 1;
        border: 0;
        background-color: transparent;
        padding: 0.25rem 0;
        color: #3985e1;
        outline: none;

        &:first-child {
          border-right: 1px solid #f3f3f4;
        }
      }
    }

    &:before {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      position: fixed;
      background-color: rgba(0, 0, 0, 0.2);
      content: "";
      cursor: default;
    }
  }
#bet-coins .template-1.order{
  a{ padding:0.15rem 0.21rem;}
  label{
    display:flex;
    span{ padding:10px 5px; font-size: 20px;}
    input{ margin:0 10px; border:1px solid #ccc;}
  }
}
}
</style>